import React, { useEffect, useMemo, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import CustomTable from "../Table/CustomTable";
import SendEmailTextComponent from "../SendEmailTextComponent";
import {
  copyToClipboard,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  getFullName,
} from "../../helper-methods";
import SvgIcons from "../SvgIcons";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../custom/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateTemplateList } from "../../redux/actions";
import { getAllOwnerAffiliates } from "../../http/http-calls";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Name", id: "name" },
  { label: "Deal Made", id: "dealsMade" },
  { label: "ES Commission", id: "estimatedCommission" },
  { label: "Partner Distribution", id: "partnerDistribution" },
  { label: "Owner Commission", id: "ownerCommission" },
  { label: "Total Commission", id: "totalCommissionsion" },
];

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const OwnerAffiliatesTable = ({ ownerId, isGetTemplatesList = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _getAllOwnerAffiliates = async (payload) => {
    try {
      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      const res = await getAllOwnerAffiliates({ id: ownerId, payload });

      setData(res.referredAffiliates);
      setDataCount(res.referredAffiliatesCount);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllOwnerAffiliates(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllOwnerAffiliates(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAllOwnerAffiliates(newDataPayload);
  };

  const _onChangeSelectedData = (value = "all", checked = false) => {
    if (value === "every") {
      setIsSelectAll(checked);
    } else {
      setIsSelectAll(false);
      let newSelectedDataIds = [...selectedDataIds];
      if (value === "all") {
        if (checked) {
          const currentPageIds = data
            ?.filter((each) => !newSelectedDataIds?.includes(each._id))
            .map((each) => each._id);
          newSelectedDataIds = [...newSelectedDataIds, ...currentPageIds];
        } else {
          const currentPageIds = data?.map((each) => each._id);
          newSelectedDataIds = newSelectedDataIds.filter(
            (sid) => !currentPageIds.includes(sid)
          );
        }
      } else {
        if (checked) {
          newSelectedDataIds.push(value);
        } else {
          const findIndex = newSelectedDataIds.findIndex(
            (each) => each === value
          );
          if (findIndex >= 0) newSelectedDataIds.splice(findIndex, 1);
        }
      }
      setSelectedDataIds(newSelectedDataIds);
    }
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              {/* <img
                src={require("../../assets/img/default-profile.svg").default}
                alt="user"
              /> */}
              <div className="userContact">
                <h2
                  className={`${
                    row?.isActive === false ? "text-danger" : "text-primary"
                  }`}
                  onClick={() => navigate(`/${userType}/affiliate/${row?._id}`)}
                >
                  {getFullName(row?.name) || "Anonymous"}
                </h2>
                <ul className="userAction">
                  {row?.phone && (
                    <li>
                      <>
                        <div
                          id={`user_phone_${row?._id}`}
                          onClick={() =>
                            copyToClipboard(
                              `${row?.countryCode || ""}${row?.phone}`
                            )
                          }
                        >
                          <SvgIcons type={"phone"} />
                        </div>

                        <CustomTooltip
                          target={`user_phone_${row?._id}`}
                          text={`${row?.countryCode || ""} ${row?.phone}`}
                        />
                      </>
                    </li>
                  )}
                  {row?.email && (
                    <li>
                      <>
                        <div
                          id={`user_email_${row?._id}`}
                          onClick={() => copyToClipboard(row?.email)}
                        >
                          <SvgIcons type={"email"} />
                        </div>

                        <CustomTooltip
                          target={`user_email_${row?._id}`}
                          text={row?.email}
                        />
                      </>
                    </li>
                  )}
                  {row?.referCode && (
                    <li>
                      <>
                        <div
                          id={`user_website_${row?._id}`}
                          onClick={() => copyToClipboard(row?.referCode)}
                        >
                          <SvgIcons type={"refer"} />
                        </div>
                        <CustomTooltip
                          target={`user_website_${row?._id}`}
                          text={row?.referCode}
                        />
                      </>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      }
      case "dealsMade": {
        return <>{row?.dealsMade || 0}</>;
      }
      case "estimatedCommission": {
        return <>{formatCurrencyValue(row?.estimatedCommission || 0)}</>;
      }
      case "partnerDistribution": {
        return <>{formatCurrencyValue(row?.partnerDistribution || 0)}</>;
      }
      case "ownerCommission": {
        return <>{formatCurrencyValue(row?.ownerCommission || 0)}</>;
      }
      case "totalCommissionsion": {
        return (
          <>
            {formatCurrencyValue(
              (row?.ownerCommission || 0) + (row?.partnerDistribution || 0)
            )}
          </>
        );
      }

      default: {
        return cell;
      }
    }
  };

  const _getAllTemplatesList = async () => {
    try {
      await getAndUpdateTemplateList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isGetTemplatesList) {
      _getAllTemplatesList();
    }

    _onPageChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <div className="innerHeader">
        <h2>Affiliates</h2>
      </div> */}

      {isSelectAll || selectedDataIds?.length ? (
        <>
          <div>
            {isSelectAll ? (
              <>All {dataCount} Affiliates are selected</>
            ) : (
              <>
                {selectedDataIds.length}{" "}
                {selectedDataIds.length > 1 ? "Affiliates are" : "Affiliate is"}{" "}
                selected
              </>
            )}
          </div>

          {selectedDataIds?.length >= data?.length &&
          dataCount > dataPayload.limit ? (
            <FormGroup check className="mb-0">
              <Label for="isSelectAll_Affiliates">
                Select All {dataCount} Affiliates
              </Label>
              <Input
                type="checkbox"
                id="isSelectAll_Affiliates"
                checked={isSelectAll}
                onChange={(e) =>
                  _onChangeSelectedData("every", e.target.checked)
                }
              />
            </FormGroup>
          ) : null}
        </>
      ) : null}

      <CustomTable
        data={data}
        dataCount={dataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={true}
        isSelectAll={isSelectAll}
        selectedDataIds={selectedDataIds}
        onChangeSelectedData={(each, checked) =>
          _onChangeSelectedData(each?._id, checked)
        }
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      {selectedDataIds?.length ? (
        <SendEmailTextComponent
          isSelectAll={isSelectAll}
          selectedDataIds={selectedDataIds}
          setSelectedDataIds={setSelectedDataIds}
          setIsSelectAll={setIsSelectAll}
          userType={`Affiliate`}
        />
      ) : null}
    </>
  );
};

export default OwnerAffiliatesTable;
