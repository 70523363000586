import React, { useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Input,
  Label,
} from "reactstrap";
import EditWebinarSettingsModal from "../modals/EditWebinarSettingsModal";
import { daysOfWeekArray, weeksCountConfig } from "../../config/helper-config";
import moment from "moment";
import { formatDate } from "../../helper-methods";
import SvgIcons from "../SvgIcons";
import { useSelector } from "react-redux";

const SettingsWebinarCard = ({ getAndUpdateSettingsData = () => {} }) => {
  const settingsData = useSelector((state) => state?.settingsData);

  const [noOfWeek, setNoOfWeek] = useState(moment().week());

  const currentWeekData = useMemo(() => {
    if (!settingsData?.setting?.webinarSettings?.length) {
      return null;
    }

    const findData = settingsData?.setting?.webinarSettings?.find(
      (each) => +each.noOfWeek === +noOfWeek
    );

    return findData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsData?.setting?.webinarSettings, noOfWeek]);

  const [editSettingsModal, setEditSettingsModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleEditSettingsModal = (isOpen = false, data = null) => {
    setEditSettingsModal({ isOpen, data });
  };

  return (
    <>
      <Card className="my-2">
        <CardHeader>
          <CardTitle>Webinar Settings</CardTitle>

          {noOfWeek >= moment().week() &&
          moment().isBefore(
            moment(currentWeekData?.webinarTime || "18:00", "HH:mm")
              .week(noOfWeek)
              .day(
                daysOfWeekArray.indexOf(
                  currentWeekData?.webinarDay || "Tuesday"
                )
              )
          ) ? (
            <Button
              color="link"
              onClick={() => _toggleEditSettingsModal(true, currentWeekData)}
            >
              <SvgIcons type={"edit"} />
            </Button>
          ) : null}
        </CardHeader>
        <CardBody>
          <div>
            <span style={{ fontSize: "14px" }} className="fw-500">
              Timezone :
            </span>

            <span style={{ fontSize: "14px" }} className="inotItem ms-2">
              {settingsData?.setting?.webinarTimeZone || "America/Los Angeles"}
            </span>
          </div>

          <hr style={{ color: "#d3d3d3" }} />

          <div className="d-flex align-items-center justify-content-between my-3">
            <Label>
              <i className="fa fa-calendar me-2" />
              {formatDate(
                moment().week(noOfWeek).startOf("week").add(1, "d")
              )}{" "}
              - {formatDate(moment().week(noOfWeek).endOf("week").add(1, "d"))}
            </Label>

            <Input
              type="select"
              value={noOfWeek}
              style={{ maxWidth: "150px" }}
              onChange={(e) => setNoOfWeek(e.target.value)}
            >
              {weeksCountConfig.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <hr style={{ color: "#d3d3d3" }} />

          <div className="infoWrap">
            <ul>
              <li>
                <span>
                  Event{" "}
                  {currentWeekData?.isCancel ? (
                    <Badge className="outline" color="danger">
                      Canceled
                    </Badge>
                  ) : null}
                </span>
                <div className="inotItem">
                  Champion Digital Real Estate Webinar
                </div>
              </li>
              <li>
                <span>Scheduled Day</span>
                <div className="inotItem">
                  {currentWeekData?.webinarDay || "Tuesday"}
                </div>
              </li>
              <li>
                <span>Scheduled Time</span>
                <div className="inotItem">
                  {currentWeekData?.webinarTime ? (
                    <>
                      {currentWeekData?.webinarTime} -{" "}
                      {moment(currentWeekData?.webinarTime, "HH:mm")
                        .add(1, "h")
                        .format("HH:mm")}
                    </>
                  ) : (
                    "18:00 - 19:00"
                  )}
                </div>
              </li>
            </ul>
          </div>
        </CardBody>
      </Card>

      <EditWebinarSettingsModal
        isOpen={editSettingsModal.isOpen}
        data={editSettingsModal.data}
        noOfWeek={noOfWeek}
        toggle={() => _toggleEditSettingsModal()}
        onSuccess={() => getAndUpdateSettingsData()}
      />
    </>
  );
};

export default SettingsWebinarCard;
