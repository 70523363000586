import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AffiliateDetailsPage from "../../pages/protected/AffiliateDetailsPage";
import { useSelector } from "react-redux";
import ProtectedRoute from "../../components/routes/ProtectedRoute";
import OwnerDetailsPage from "../../pages/protected/OwnerDetailsPage";
import ClientDetailsPage from "../../pages/protected/ClientDetailsPage";

const OwnerRoutes = () => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  return (
    <>
      <Routes>
        <Route
          path={`${userType}/*`}
          element={<ProtectedRoute redirectRoute={"/login"} />}
        >
          <Route exact path="home" element={<OwnerDetailsPage />} />

          <Route
            exact
            path="affiliate/:id"
            element={<AffiliateDetailsPage />}
          />

          <Route exact path="client/:id" element={<ClientDetailsPage />} />

          <Route
            index
            element={<Navigate replace to={`/${userType}/home`} />}
          />

          <Route
            path="*"
            element={<Navigate replace to={`/${userType}/home`} />}
          />
        </Route>

        <Route path="*" element={<Navigate replace to={`/${userType}`} />} />
      </Routes>
    </>
  );
};

export default OwnerRoutes;
