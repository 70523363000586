import React from "react";

const NoDataDiv = ({ message = "There is no data to display." }) => {
  return (
    <div className="noData">
      <img src={require("../assets/img/noData.svg").default} alt="no data" />
      <p>{message}</p>
    </div>
  );
};

export default NoDataDiv;
