import React, { useEffect, useMemo, useState } from "react";
import { Button, Input } from "reactstrap";
import AddMemberModal from "../../components/modals/AddMemberModal";
import CustomTable from "../../components/Table/CustomTable";
import { editTeamMember } from "../../http/http-calls";
import {
  copyToClipboard,
  deepClone,
  errorHandler,
  formatDate,
  getDropdownColor,
  getFullName,
  hasPermission,
  showToast,
} from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateTeams, updateTeamsDataByIndex } from "../../redux/actions";
import SvgIcons from "../../components/SvgIcons";
import {
  permissionSettingsConfig,
  statusConfig,
} from "../../config/helper-config";
import CustomTooltip from "../../components/custom/CustomTooltip";
import { useNavigate } from "react-router-dom";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Name", id: "name" },
  { label: "Permissions", id: "permissionSettings" },
  { label: "Added By", id: "_addedBy" },
  { label: "Last Login", id: "lastLogin" },
  { label: "Status", id: "status" },
  { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const TeamMembersPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const tableData = useSelector((state) => state?.tableData);

  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [addMemberModal, setAddMemberModal] = useState({
    isOpen: false,
    data: null,
  });

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "teamMembers", action: "view" }),
    []
  );

  const hasPermissionToCreate = useMemo(
    () => hasPermission({ page: "teamMembers", action: "create" }),
    []
  );

  const hasPermissonToEdit = useMemo(
    () => hasPermission({ page: "teamMembers", action: "edit" }),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddMemberModal = (isOpen = false, data = null) => {
    setAddMemberModal({ isOpen, data });
  };

  const _getAndUpdateTeams = async (payload) => {
    try {
      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      await getAndUpdateTeams(payload)(dispatch);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateTeams(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateTeams(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAndUpdateTeams(newDataPayload);
  };

  const _onChangeStatus = async (data, status) => {
    try {
      if (!data?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      const findIndex = tableData?.teamsData?.findIndex(
        (e) => e._id === data?._id
      );

      if (findIndex <= -1) {
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        isActive: status === "Active" ? true : false,
      };

      dispatch(
        updateTeamsDataByIndex({
          index: findIndex,
          key: "isActive",
          value: payload.isActive,
        })
      );

      await editTeamMember({ id: data?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getAndUpdateTeams(dataPayload);
    }
  };

  // calculate how many permission out of total accessible permission are set
  const _getPermissionCount = (permissionSettings = {}) => {
    if (!Object.keys(permissionSettings)?.length) return "N/A";

    const totalPermission = permissionSettingsConfig?.reduce(
      (acc, curr) => (acc += curr.values.length),
      0
    );

    const givenPermission = Object.keys(permissionSettings)
      ?.map((key) => Object.values(permissionSettings[key]))
      ?.flat()
      .reduce((acc, curr) => (curr ? ++acc : acc), 0);

    return `${givenPermission} / ${totalPermission}`;
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userContact">
                <h2
                  className={`${
                    row?.isActive === false ? "text-danger" : "text-primary"
                  }`}
                  onClick={() => _toggleAddMemberModal(true, row)}
                >
                  {getFullName(row?.name) || "Anonymous"}
                </h2>
                <ul className="userAction">
                  {row?.phone && (
                    <li>
                      <>
                        <div
                          id={`user_phone_${row?._id}`}
                          onClick={() =>
                            copyToClipboard(
                              `${row?.countryCode || ""}${row?.phone}`
                            )
                          }
                        >
                          <SvgIcons type={"phone"} />
                        </div>

                        <CustomTooltip
                          target={`user_phone_${row?._id}`}
                          text={`${row?.countryCode || ""} ${row?.phone}`}
                        />
                      </>
                    </li>
                  )}
                  {row?.email && (
                    <li>
                      <>
                        <div
                          id={`user_email_${row?._id}`}
                          onClick={() => copyToClipboard(row?.email)}
                        >
                          <SvgIcons type={"email"} />
                        </div>

                        <CustomTooltip
                          target={`user_email_${row?._id}`}
                          text={row?.email}
                        />
                      </>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      }
      case "_addedBy": {
        return <>{getFullName(row?._addedBy?.name) || "-"}</>;
      }
      case "permissionSettings": {
        return <> {_getPermissionCount(row?.permissionSettings)}</>;
      }
      case "lastLogin": {
        return <>{formatDate(row?.lastLogin) || "-"}</>;
      }
      case "status": {
        return (
          <>
            <Input
              type="select"
              className={`status ${getDropdownColor(
                row?.isActive ? "Active" : "Inactive"
              )}`}
              disabled={loadingState?.status ? true : false}
              value={row?.isActive ? "Active" : "Inactive"}
              onChange={(e) => _onChangeStatus(row, e.target.value)}
            >
              {statusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </>
        );
      }
      case "action": {
        return (
          <>
            <Button
              color="link"
              onClick={() => _toggleAddMemberModal(true, row)}
              disabled={!hasPermissonToEdit}
            >
              <SvgIcons type="edit" />
            </Button>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      navigate("/");
    }

    _onPageChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Team Members{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button
            onClick={() => _toggleAddMemberModal(true)}
            color="primary"
            disabled={!hasPermissionToCreate}
          >
            Add
          </Button>
        </div>
      </div>

      <CustomTable
        data={tableData?.teamsData}
        dataCount={tableData?.teamsDataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={false}
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      <AddMemberModal
        isOpen={addMemberModal.isOpen}
        data={addMemberModal.data}
        toggle={() => _toggleAddMemberModal()}
        onSuccess={() => _onPageChange()}
      />
    </>
  );
};

export default TeamMembersPage;
