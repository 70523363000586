import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AffiliatesPage from "../../pages/protected/AffiliatesPage";
import ClientsPage from "../../pages/protected/ClientsPage";
import LearningPage from "../../pages/protected/LearningPage";
import MessageTemplatesPage from "../../pages/protected/MessageTemplatesPage";
import NewMessagePage from "../../pages/protected/NewMessagePage";
import GoalsPage from "../../pages/protected/GoalsPage";
import AffiliateGroupsPage from "../../pages/protected/AffiliateGroupsPage";
import TeamMembersPage from "../../pages/protected/TeamMembersPage";
import OwnersPage from "../../pages/protected/OwnersPage";
import Payroll from "../../pages/protected/Payroll";
import SettingsPage from "../../pages/protected/SettingsPage";
import { useSelector } from "react-redux";
import LeaderboardPage from "../../pages/protected/LeaderboardPage";
import AffiliateDetailsPage from "../../pages/protected/AffiliateDetailsPage";
import ClientDetailsPage from "../../pages/protected/ClientDetailsPage";
import ProtectedRoute from "../../components/routes/ProtectedRoute";
import OwnerDetailsPage from "../../pages/protected/OwnerDetailsPage";

const DefaultRoutes = () => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  return (
    <>
      <Routes>
        <Route
          path={`${userType}/*`}
          element={<ProtectedRoute redirectRoute={"/login"} />}
        >
          <Route exact path="leaderboard" element={<LeaderboardPage />} />

          <Route exact path="affiliates" element={<AffiliatesPage />} />

          <Route
            exact
            path="affiliate/:id"
            element={<AffiliateDetailsPage />}
          />

          <Route exact path="clients" element={<ClientsPage />} />

          <Route exact path="client/:id" element={<ClientDetailsPage />} />

          <Route exact path="learning-center" element={<LearningPage />} />

          <Route
            exact
            path="message-templates"
            element={<MessageTemplatesPage />}
          />

          <Route exact path={`message/:id`} element={<NewMessagePage />} />

          <Route exact path="goals" element={<GoalsPage />} />

          <Route
            exact
            path="affiliate-groups"
            element={<AffiliateGroupsPage />}
          />

          <Route exact path="team-members" element={<TeamMembersPage />} />

          <Route exact path="owners" element={<OwnersPage />} />

          <Route exact path="owner/:id" element={<OwnerDetailsPage />} />

          <Route exact path="payroll" element={<Payroll />} />

          <Route exact path="settings" element={<SettingsPage />} />

          <Route
            index
            element={<Navigate replace to={`/${userType}/clients`} />}
          />

          <Route
            path="*"
            element={<Navigate replace to={`/${userType}/clients`} />}
          />
        </Route>

        <Route path="*" element={<Navigate replace to={`/${userType}`} />} />
      </Routes>
    </>
  );
};

export default DefaultRoutes;
