import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AffiliateGoalsTable from "../AffiliateGoalsTable";

const GoalsAchievedModal = ({
  isOpen,
  data,
  toggle = () => {},
  isProgressShow = true,
  heading = "Goals",
}) => {
  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>{heading}</ModalHeader>

      <ModalBody className="pb-0">
        <AffiliateGoalsTable lists={data} isProgressShow={isProgressShow} />
      </ModalBody>
    </Modal>
  );
};

export default GoalsAchievedModal;
