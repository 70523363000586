import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
} from "reactstrap";
import SvgIcons from "../../components/SvgIcons";
import { useNavigate, useParams } from "react-router-dom";
import {
  capitalize,
  copyToClipboard,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  getDropdownColor,
  getFullName,
  hasPermission,
  openUrlOnNewTab,
  showToast,
} from "../../helper-methods";
import { getAffiliateById, updateAffiliate } from "../../http/http-calls";
import SkeletonLoading from "../../components/SkeletonLoading";
import AddAffiliateModal from "../../components/modals/AddAffiliateModal";
import ReferredClientsTable from "../../components/ReferredClientsTable";
import AffiliateGoalsTable from "../../components/AffiliateGoalsTable";
import AffiliateMessagesHistoryTable from "../../components/AffiliateMessagesHistoryTable";
import AffiliatePaymentSummaryTable from "../../components/AffiliatePaymentSummaryTable";
import { useSelector } from "react-redux";
import { statusConfig } from "../../config/helper-config";
import CustomTooltip from "../../components/custom/CustomTooltip";
import SupportEmailModal from "../../components/modals/SupportEmailModal";
import {
  DRE_PRODUCT_FORM_LINK,
  HOME_FORM_LINK,
  PRODUCT_FORM_LINK,
  STORE_PRODUCT_FORM_LINK,
  WEBINAR_LINK,
} from "../../config";
import ShareSocialMedia from "../../components/ShareSocialMedia";
import PlatformEngagementCard from "../../components/PlatformEngagementCard";

const AffiliateDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const hasPermissonToEdit = useMemo(
    () => hasPermission({ page: "affiliates", action: "edit" }),
    []
  );

  const [data, setData] = useState(null);
  const [platformEngagement, setPlatformEngagement] = useState(null);
  const [goalsAchieved, setGoalsAchieved] = useState([]);
  const [messages, setMessages] = useState([]);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [loadingState, setLoadingState] = useState({
    data: false,
  });

  const [addAffiliateModal, setAddAffiliateModal] = useState({
    isOpen: false,
    data: null,
  });

  const [supportEmailModal, setSupportEmailModal] = useState({
    isOpen: false,
    data: null,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddAffiliateModal = (isOpen = false, data = null) => {
    setAddAffiliateModal({ isOpen, data });
  };

  const _toggleSupportEmailModal = (isOpen = false, data = null) => {
    setSupportEmailModal({ isOpen, data });
  };

  const _onChangeStatus = async (each, status) => {
    try {
      if (!each?._id) {
        errorHandler({ reason: "Data not found" });
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        isActive: status === "Active" ? true : false,
      };

      const newData = deepClone(data);
      newData.isActive = payload.isActive;
      setData(newData);

      await updateAffiliate({ id: each?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getAffiliateById();
    }
  };

  const _getAffiliateById = async () => {
    try {
      _manageLoadingState("data", true);

      const res = await getAffiliateById(
        params?.id || userCredential?.user?._id
      );

      setData(res?.user);
      setGoalsAchieved(res?.user?.goalsAchieved || []);
      setMessages(res?.messages || []);
      setPaymentLogs(res?.paymentLogs || []);
      setPlatformEngagement(res?.platformEngagement);

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler({ reason: "User not found" });
      _manageLoadingState("data", false);

      if (params?.id) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    _getAffiliateById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <div className="backToPage">
          {params?.id ? (
            <>
              <Button color="link" onClick={() => navigate(-1)}>
                <SvgIcons type={"backArrow"} />
              </Button>
              <h2>Affiliate Details</h2>
            </>
          ) : (
            <>
              <h2>Affiliate Home</h2>
            </>
          )}
        </div>

        <div className="right">
          {params?.id && data && userType === "admin" ? (
            <Input
              type="select"
              className={`status ${getDropdownColor(
                data?.isActive ? "Active" : "Inactive"
              )}`}
              disabled={loadingState?.status ? true : false}
              value={data?.isActive ? "Active" : "Inactive"}
              onChange={(e) => _onChangeStatus(data, e.target.value)}
            >
              {statusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          ) : null}
          {userType === "affiliate" ? (
            <Button
              color="primary"
              className="ms-2"
              onClick={() => _toggleSupportEmailModal(true, data, true)}
            >
              Contact Support
            </Button>
          ) : null}
        </div>
      </div>

      {loadingState?.data && !data ? (
        <SkeletonLoading type="detailPage" />
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Personal Info</CardTitle>
                  <Button
                    color="link"
                    onClick={() => _toggleAddAffiliateModal(true, data)}
                    disabled={!hasPermissonToEdit}
                  >
                    <SvgIcons type={"edit"} />
                  </Button>
                </CardHeader>
                <CardBody>
                  <div className="infoWrap">
                    <h2>{getFullName(data?.name) || "Anonymous"}</h2>
                    <ul>
                      <li>
                        <span>Referral Code</span>
                        <div className="inotItem">
                          <SvgIcons type={"globe"} />

                          {data?.referCode ? (
                            <>
                              {data?.referCode}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.referCode)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Phone Number</span>
                        <div className="inotItem">
                          <SvgIcons type={"phone"} />

                          {data?.phone ? (
                            <>
                              {data?.countryCode} {data?.phone}
                              <Button
                                color="link"
                                onClick={() =>
                                  copyToClipboard(
                                    `${data?.countryCode}${data?.phone}`
                                  )
                                }
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Location</span>
                        <div className="inotItem">
                          <SvgIcons type={"mapMarker"} />

                          {data?.address ? (
                            <>
                              {formatAddressInSingleText(data?.address)}
                              <Button
                                color="link"
                                onClick={() =>
                                  copyToClipboard(
                                    formatAddressInSingleText(data?.address)
                                  )
                                }
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Email ID</span>
                        <div className="inotItem">
                          <SvgIcons type={"email"} />

                          {data?.email ? (
                            <>
                              {data?.email}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.email)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Owner</span>
                        <div className="inotItem">
                          <SvgIcons type={"user"} />

                          {data?._owner?.name ? (
                            <>
                              {getFullName(data?._owner?.name)}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.owner)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Telegram Channel</span>
                        <div className="inotItem">
                          <Button
                            color="link"
                            id="Join_Telegram_Channel"
                            onClick={() =>
                              openUrlOnNewTab(`https://t.me/+py6o2we2Y_hiMDAx`)
                            }
                          >
                            <SvgIcons type="telegram" />
                            <CustomTooltip
                              text="Join Group"
                              target="Join_Telegram_Channel"
                            />
                          </Button>

                          <Button
                            color="link"
                            id="Copy_Telegram_Channel"
                            onClick={() =>
                              copyToClipboard(`https://t.me/+py6o2we2Y_hiMDAx`)
                            }
                          >
                            <SvgIcons type="copy" />
                            <CustomTooltip
                              text="Copy"
                              target="Copy_Telegram_Channel"
                            />
                          </Button>
                        </div>
                      </li>
                      <li>
                        <span>Domain Sales</span>
                        <div className="inotItem">
                          <SvgIcons type={"count"} />

                          {data?.totalDomainSale || 0}
                        </div>
                      </li>
                      <li>
                        <span>Store Sales</span>
                        <div className="inotItem">
                          <SvgIcons type={"count"} />

                          {data?.totalStoreSale || 0}
                        </div>
                      </li>
                      {userType === "admin" ? (
                        <>
                          <li>
                            <span>Domain Commission</span>
                            <div className="inotItem">
                              <SvgIcons type={"count"} />
                              {data?.siteCommission || 0}%
                            </div>
                          </li>
                          <li>
                            <span>Store Commission</span>
                            <div className="inotItem">
                              <SvgIcons type={"count"} />
                              {data?.storeCommission || 0}%
                            </div>
                          </li>
                        </>
                      ) : null}
                      <li>
                        <span>Group</span>
                        <div className="inotItem">
                          <SvgIcons type={"count"} />

                          {data?._groups?.length ? (
                            <>
                              {data?._groups?.slice(0, 1)?.map((e, i) => (
                                <span
                                  key={`_groups_${
                                    data?._id
                                  }_${e}_${i}_${"index"}`}
                                  className="mr-1"
                                >
                                  {capitalize(e?.name) || "-"}
                                </span>
                              ))}

                              {data?._groups?.slice(1)?.length ? (
                                <>
                                  <Button
                                    id={`groups_customtooltip_affiliate_detail_${
                                      data?._id
                                    }_${"index"}`}
                                    color="link"
                                    className="p-0 themeColor text-decoration-none"
                                    style={{ verticalAlign: 0 }}
                                  >
                                    +{data?._groups?.slice(1)?.length}
                                  </Button>

                                  <CustomTooltip
                                    target={`groups_customtooltip_affiliate_detail_${
                                      data?._id
                                    }_${"index"}`}
                                    text={data?._groups
                                      ?.slice(1)
                                      ?.map((e, i) => (
                                        <span
                                          key={`_groups_${data?._id}_${
                                            e._id
                                          }_${i}_${"index"}`}
                                          className={"comma-separated"}
                                        >
                                          {capitalize(e?.name) || "-"}
                                        </span>
                                      ))}
                                  />
                                </>
                              ) : null}
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {data?.vsl || data?.referCode ? (
              <>
                <Col md={6}>
                  <Card className="cardDesign">
                    <CardHeader>
                      <CardTitle>Links</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="infoWrap">
                        <ul>
                          {data?.referCode ? (
                            <>
                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${PRODUCT_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Product Form
                                  </span>

                                  <ShareSocialMedia
                                    id={`Product_Form`}
                                    link={`${PRODUCT_FORM_LINK}?ref=${data.referCode}`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${STORE_PRODUCT_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Store Product Form
                                  </span>

                                  <ShareSocialMedia
                                    id={`Store_Product_Form`}
                                    link={`${STORE_PRODUCT_FORM_LINK}?ref=${data.referCode}`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${DRE_PRODUCT_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    DRE Product Form
                                  </span>

                                  <ShareSocialMedia
                                    id={`DRE_Product_Form_Link`}
                                    link={`${DRE_PRODUCT_FORM_LINK}?ref=${data.referCode}`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${WEBINAR_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Webinar Registration
                                  </span>

                                  <ShareSocialMedia
                                    id={`Webinar_Registration`}
                                    link={`${WEBINAR_LINK}?ref=${data.referCode}`}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `https://us06web.zoom.us/j/6596203934?pwd=Kj5CRdYWyROaQfcbQOZNQ9nAcb1DZm.1`
                                      )
                                    }
                                  >
                                    Webinar Link
                                  </span>

                                  <ShareSocialMedia
                                    id={`Webinar_Link`}
                                    link={`https://us06web.zoom.us/j/6596203934?pwd=Kj5CRdYWyROaQfcbQOZNQ9nAcb1DZm.1`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${HOME_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Website Link
                                  </span>

                                  <ShareSocialMedia
                                    id={`Website_Link`}
                                    link={`${HOME_FORM_LINK}?ref=${data.referCode}`}
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}

                          {data?.vsl ? (
                            <>
                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() => openUrlOnNewTab(data?.vsl)}
                                  >
                                    VSL
                                  </span>

                                  <Button
                                    color="link"
                                    onClick={() => copyToClipboard(data?.vsl)}
                                  >
                                    <SvgIcons type={"copy"} />
                                  </Button>
                                </div>
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>

                  <PlatformEngagementCard
                    platformEngagement={platformEngagement}
                  />
                </Col>
              </>
            ) : null}
          </Row>

          <Row>
            <Col md={12}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Goals</CardTitle>
                </CardHeader>
                <CardBody>
                  <AffiliateGoalsTable lists={goalsAchieved} />
                </CardBody>
              </Card>
            </Col>

            <Col lg={6} md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Messages History</CardTitle>
                </CardHeader>
                <CardBody>
                  <AffiliateMessagesHistoryTable lists={messages} />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Payment Summary</CardTitle>
                </CardHeader>
                <CardBody>
                  <AffiliatePaymentSummaryTable lists={paymentLogs} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {data && (
            <Row>
              <Col>
                <ReferredClientsTable
                  affiliateId={params?.id || userCredential?.user?._id}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      <AddAffiliateModal
        isOpen={addAffiliateModal.isOpen}
        data={addAffiliateModal.data}
        toggle={() => _toggleAddAffiliateModal()}
        onSuccess={() => _getAffiliateById()}
      />

      <SupportEmailModal
        isOpen={supportEmailModal.isOpen}
        data={supportEmailModal.data}
        toggle={() => _toggleSupportEmailModal()}
      />
    </>
  );
};

export default AffiliateDetailsPage;
