import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllGroupList,
  getAllOwnerList,
  getAllTemplatesList,
  getAllUsersList,
  getAllVendorsList,
} from "../../http/http-calls";
import { capitalize, getFullName } from "../../helper-methods";

const getAndUpdateUsersList = createAsyncThunk(
  "filtersList/getAndUpdateUsersList",
  async (payload, thunkAPI) => {
    const res = await getAllUsersList();

    const data = res?.affiliates?.length
      ? res.affiliates?.map((each) => ({
          ...each,
          label: getFullName(each.name) || "Anonymous",
          value: each._id,
        }))
      : [];

    return {
      data,
    };
  }
);

const getAndUpdateGroupList = createAsyncThunk(
  "filtersList/getAndUpdateGroupList",
  async (payload, thunkAPI) => {
    const res = await getAllGroupList();

    const data = res?.groups?.length
      ? res.groups?.map((each) => ({
          ...each,
          label: capitalize(each.name) || "Anonymous",
          value: each._id,
        }))
      : [];

    return {
      data,
    };
  }
);

const getAndUpdateTemplateList = createAsyncThunk(
  "filtersList/getAndUpdateTemplateList",
  async (payload, thunkAPI) => {
    const res = await getAllTemplatesList();

    const data = res?.template?.length
      ? res.template?.map((each) => ({
          ...each,
          label: capitalize(each.title) || "Anonymous",
          value: each._id,
        }))
      : [];

    return {
      data,
    };
  }
);

const getAndUpdateVendorsList = createAsyncThunk(
  "filtersList/getAndUpdateVendorsList",
  async (payload, thunkAPI) => {
    const res = await getAllVendorsList();

    const data = res?.vendorsList?.length
      ? res.vendorsList?.map((each) => ({
          ...each,
          label: `${capitalize(each.displayName)} (${each.email})`,
          value: each.vendorId,
        }))
      : [];

    return {
      data,
    };
  }
);

const getAndUpdateOwnerList = createAsyncThunk(
  "filtersList/getAndUpdateOwnerList",
  async (payload, thunkAPI) => {
    const res = await getAllOwnerList();

    const data = res?.owners?.length
      ? res.owners?.map((each) => ({
          ...each,
          label: getFullName(each.name) || "Anonymous",
          value: each._id,
        }))
      : [];

    return {
      data,
    };
  }
);

const updateUsersListByIndex = createAction("updateUsersListByIndex");

const clearAllFiltersListData = createAction("clearAllFiltersListData");

export {
  getAndUpdateUsersList,
  updateUsersListByIndex,
  getAndUpdateGroupList,
  getAndUpdateTemplateList,
  getAndUpdateOwnerList,
  getAndUpdateVendorsList,
  clearAllFiltersListData,
};
