import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  Badge,
  CardTitle,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  getFullName,
  showToast,
  splitFullName,
} from "../../helper-methods";
import { RegexConfig } from "../../config/RegexConfig";
import { permissionSettingsConfig } from "../../config/helper-config";
import {
  createTeamMember,
  editTeamMember,
  getTeamMemberById,
} from "../../http/http-calls";

const initialFormFields = {
  name: "",
  email: "",
  phone: "",
  countryCode: "+1",
  permissionSettings: {
    affiliates: {},
    messageTemplates: {},
    clients: {},
    goals: {},
    leaderBoard: {},
    learningCenter: {},
    teamMembers: {},
  },
};

const AddMemberModal = ({ isOpen, data, toggle, onSuccess = () => {} }) => {
  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});
  const [presetValue, setPresetValue] = useState("");
  const [isOpenKey, setIsOpenKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _resetStates = () => {
    setFormFields(deepClone(initialFormFields));
    setPresetValue("");
    setIsOpenKey("");
    setErrors({});
    setIsDirty({});
    setIsLoading(false);
  };

  const _toggleCollapse = (isOpenKey) => {
    setIsOpenKey((prev) => (prev !== isOpenKey ? isOpenKey : null));
  };

  const _closeModal = () => {
    _resetStates();
    toggle();
  };

  const _getTeamMemberById = async () => {
    try {
      setIsLoading(true);

      if (!data?._id) {
        _closeModal();
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }

      const res = await getTeamMemberById(data?._id);
      const newFormFields = { ...formFields };
      newFormFields.name = getFullName(res?.user?.name);
      newFormFields.email = res?.user?.email;
      newFormFields.phone = res?.user?.phone;
      if (res?.user?.permissionSettings) {
        newFormFields.permissionSettings = {
          ...newFormFields.permissionSettings,
          ...res?.user?.permissionSettings,
        };
      }

      setFormFields(newFormFields);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      _closeModal();
      errorHandler(error);
    }
  };

  const _validateForm = ({ newFormFields, newIsDirty }) => {
    const newErrors = { ...errors };
    return new Promise((resolve) => {
      let isFormValid = true;
      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newFormFields?.[key]?.trim()?.length) {
                if (
                  newFormFields[key]?.length >= 2 &&
                  newFormFields[key]?.length <= 50
                ) {
                  if (
                    RegexConfig?.[key]?.test(
                      String(newFormFields?.[key]).toLowerCase()
                    )
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "Invalid Name, It must start with a character";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] =
                    "*Please enter a value between 2 and 50 characters.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "Required";
                isFormValid = false;
              }

              break;
            }

            case "email": {
              if (newFormFields?.[key]?.trim()?.length) {
                if (
                  RegexConfig?.[key]?.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "Invalid Email";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "Email is Required";
                isFormValid = false;
              }

              break;
            }

            case "phone": {
              if (newFormFields?.[key]?.trim()?.length) {
                if (
                  RegexConfig?.[key]?.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "Invalid Phone";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "Phone is Required";
                isFormValid = false;
              }

              break;
            }

            default:
          }
        }
      });
      setErrors(newErrors);
      setIsDirty(newIsDirty);
      resolve(isFormValid);
    });
  };

  // marking all formfields dirty
  const _markAllIsDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      const newFormFields = { ...formFields };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _udpateFieldValue = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 10)
    ) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _onBlurHandler = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);
    _validateForm({ newFormFields, newIsDirty });
  };

  const _presetPermission = (presetValue = "") => {
    let newFormFields = deepClone(formFields);

    switch (presetValue) {
      case "lite": {
        permissionSettingsConfig.forEach((each) => {
          let permissions = {};
          each.values.forEach((subEach) => {
            if (subEach.preset?.includes(presetValue))
              permissions[subEach.string] = true;
          });
          newFormFields.permissionSettings[each.heading.string] = {
            ...permissions,
          };
        });

        break;
      }

      case "manager": {
        permissionSettingsConfig.forEach((each) => {
          let permissions = {};
          each.values.forEach((subEach) => {
            if (subEach.preset?.includes(presetValue))
              permissions[subEach.string] = true;
          });
          newFormFields.permissionSettings[each.heading.string] = {
            ...permissions,
          };
        });

        break;
      }

      case "partner": {
        permissionSettingsConfig.forEach((each) => {
          let permissions = {};
          each.values.forEach((subEach) => {
            permissions[subEach.string] = true;
          });
          newFormFields.permissionSettings[each.heading.string] = {
            ...permissions,
          };
        });

        break;
      }

      default: {
        newFormFields.permissionSettings = deepClone(
          initialFormFields?.permissionSettings
        );
        break;
      }
    }
    setFormFields(newFormFields);
    setPresetValue(presetValue);
  };

  const _selectedIndividualPermissionCount = (permissionName) => {
    try {
      let count = 0;

      const permissions = formFields.permissionSettings?.[permissionName];
      if (!permissions) return 0;

      Object.values(permissions).forEach((each) =>
        each ? (count = count + 1) : ""
      );
      return count;
    } catch (error) {
      console.log("error>>", error);
      return 0;
    }
  };

  /**
   * @param {String} permissionName - i.e. feed / vault / store
   * @param {String} permissionType - i.e. canView / canEdit / canDelete
   * @param {Boolean} value - true / false - i.e. checked or not
   */
  const _onChangeCheckbox = (permissionName, permissionType, value) => {
    const newFormFields = deepClone(formFields);

    const findConfig = permissionSettingsConfig.find(
      (each) => each.heading.string === permissionName
    );

    // set view-permission `true`; if any other permission is being set `true`
    if (
      findConfig?.values?.[0]?.string &&
      findConfig.values[0].string !== permissionType &&
      value
    ) {
      newFormFields.permissionSettings[permissionName][
        findConfig.values[0].string
      ] = true;
    }

    // remove all permission, if view-permission itself is being set `false`
    if (
      findConfig?.values?.[0]?.string &&
      findConfig.values[0].string === permissionType &&
      !value
    ) {
      newFormFields.permissionSettings[permissionName] = {};
    }

    newFormFields.permissionSettings[permissionName][permissionType] = value;
    setFormFields(newFormFields);
    setPresetValue("");
  };

  const _handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      setIsLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllIsDirty();
      const isFormValid = await _validateForm({ newFormFields, newIsDirty });

      if (!isFormValid) {
        setIsLoading(false);
        showToast("Fill Fields correctly");
        return;
      }

      // Format name
      const { firstName, lastName } = splitFullName(formFields?.name);

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: newFormFields.email.trim(),
        phone: newFormFields.phone.trim(),
        countryCode: newFormFields.countryCode,
        permissionSettings: newFormFields.permissionSettings,
      };

      if (data?._id) {
        await editTeamMember({ id: data?._id, payload });
        showToast("Member updated successfully", "success");
      } else {
        await createTeamMember(payload);
        showToast("Member added successfully", "success");
      }
      _closeModal();
      onSuccess();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (data && isOpen) {
      _getTeamMemberById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"} Team Member{" "}
        {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
      </ModalHeader>

      <ModalBody className="teamMemberModal">
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            placeholder="Enter"
            value={formFields.name}
            onChange={(e) => _udpateFieldValue("name", e.target.value)}
            onBlur={() => _onBlurHandler("name")}
          />
          {errors?.name ? (
            <div className="form-error">{errors.name}</div>
          ) : null}
        </FormGroup>

        <Row>
          <Col sm={6} className="pr-sm-1">
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                placeholder="Enter"
                value={formFields.email}
                onChange={(e) => _udpateFieldValue("email", e.target.value)}
                onBlur={() => _onBlurHandler("email")}
              />
              {errors.email ? (
                <div className="form-error">{errors.email}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col sm={6} className="pl-sm-1">
            <FormGroup>
              <Label>Phone Number</Label>
              <InputGroup>
                <InputGroupText>+1</InputGroupText>
                <Input
                  type="text"
                  placeholder="Enter"
                  value={formFields.phone}
                  onChange={(e) => _udpateFieldValue("phone", e.target.value)}
                  onBlur={() => _onBlurHandler("phone")}
                />
              </InputGroup>
              {errors.phone ? (
                <div className="form-error">{errors.phone}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-between align-items-center mb-2">
          <Label className="mb-0">Permissions</Label>

          <Input
            type="select"
            value={presetValue}
            onChange={(e) => _presetPermission(e.target.value)}
            style={{ width: 125 }}
          >
            <option value="">Select</option>
            {/* Lite (limited access) */}
            <option value="lite">Lite</option>
            {/* Manager (most access) */}
            <option value="manager">Manager</option>
            {/* Partner (full access) */}
            <option value="partner">Partner</option>
          </Input>
        </div>

        {permissionSettingsConfig.map((each, index) => {
          return (
            <div id="accordion" key={`${index}_${each?.heading?.string}`}>
              <Card className="cardDesign">
                <CardHeader
                  onClick={() => _toggleCollapse(each?.heading?.string)}
                >
                  <div className="d-flex align-items-center">
                    <CardTitle className="me-2">
                      {`${index + 1}. ${each?.heading?.label}`}
                    </CardTitle>
                    {_selectedIndividualPermissionCount(each.heading.string) ? (
                      <Badge color="success">
                        {_selectedIndividualPermissionCount(
                          each.heading.string
                        )}
                      </Badge>
                    ) : null}
                  </div>

                  {isOpenKey && each.heading.string === isOpenKey ? (
                    <i className="fa fa-chevron-up" />
                  ) : (
                    <i className="fa fa-chevron-down" />
                  )}
                </CardHeader>

                <Collapse isOpen={each.heading.string === isOpenKey}>
                  <CardBody>
                    {React.Children.toArray(
                      each.values.map((subEach, subIndex) => {
                        return (
                          <>
                            <FormGroup check inline>
                              <Input
                                type="checkbox"
                                className="newMemberPermission"
                                checked={
                                  formFields.permissionSettings?.[
                                    each?.heading?.string
                                  ]?.[subEach?.string]
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  _onChangeCheckbox(
                                    each.heading.string,
                                    subEach.string,
                                    e.target.checked
                                  );
                                }}
                                id={`${each.heading.string}_${subEach.string}`}
                                label={subEach.label}
                              />
                              <Label
                                check
                                for={`${each.heading.string}_${subEach.string}`}
                              >
                                {subEach.label}
                              </Label>
                            </FormGroup>

                            {/* <Input
                              type="checkbox"
                              className="newMemberPermission"
                              checked={
                                formFields.permissionSettings?.[
                                  each?.heading?.string
                                ]?.[subEach?.string]
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                _onChangeCheckbox(
                                  each.heading.string,
                                  subEach.string,
                                  e.target.checked
                                );
                              }}
                              id={`${each.heading.string}_${subEach.string}`}
                              label={subEach.label}
                            /> */}
                          </>
                        );
                      })
                    )}
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          );
        })}
      </ModalBody>

      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={(e) => _handleSubmit(e)}
          disabled={isLoading}
        >
          {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
          {data?._id ? "Update Member" : "Add Member"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddMemberModal;
