import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import SvgIcons from "../../components/SvgIcons";
import AddOwnerModal from "../../components/modals/AddOwnerModal";
import OwnerPaymentModal from "../../components/modals/OwnerPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateOwners, saveLocalFilters } from "../../redux/actions";
import {
  capitalize,
  copyToClipboard,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  getFullName,
} from "../../helper-methods";
import SkeletonLoading from "../../components/SkeletonLoading";
import NoDataDiv from "../../components/NoDataDiv";
import CustomPagination from "../../components/Table/CustomPagination";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import CustomTooltip from "../../components/custom/CustomTooltip";
import ShowHideText from "../../components/ShowHideText";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import { useNavigate } from "react-router-dom";

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const initialFiltersState = {
  startDate: null,
  endDate: null,
  dateType: "",
  search: "",
};

const OwnersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchIntervalRef = useRef({ current: null });

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const tableData = useSelector((state) => state?.tableData);
  const localFilters = useSelector((state) => state?.localFilters);
  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [filters, setFilters] = useState(deepClone(initialFiltersState));
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [addOwnerModal, setAddOwnerModal] = useState({
    isOpen: false,
    data: null,
  });
  const [ownerPaymentModal, setOwnerPaymentModal] = useState({
    isOpen: false,
  });
  const [filterShow, setFilterShow] = useState(false);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddOwnerModal = (isOpen = false, data = null) => {
    setAddOwnerModal({ isOpen, data });
  };

  const _toggleOwnerPaymentModal = (isOpen = false, data = null) => {
    setOwnerPaymentModal({ isOpen, data });
  };

  const _getAndUpdateOwners = async (payload) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "OwnersPagePayload",
          value: deepClone(payload),
        })
      );

      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      await getAndUpdateOwners(payload)(dispatch);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateOwners(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateOwners(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "OwnersPage",
          value: deepClone(newFilters),
        })
      );

      const newDataPayload = deepClone(initialDataPayloadState);

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (
            eachFilterKey === "referredLeadCount" ||
            eachFilterKey === "deals" ||
            eachFilterKey === "goalAchieved"
          ) {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey] === "Active" ? true : false;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAndUpdateOwners(newDataPayload);
    } catch (error) {}
  };

  // const _onChangeFilters = (key, value) => {
  //   _manageLoadingState("filters", true);

  //   const newFilters = { ...filters };
  //   newFilters[key] = value;
  //   setFilters(newFilters);

  //   _onFiltersUpdated(newFilters);
  // };

  const _onDatesChange = ({ startDate, endDate, dateType }) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    newFilters.dateType = dateType;

    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _clearFilters = () => {
    _manageLoadingState("filters", true);

    const newFilters = deepClone(initialFiltersState);

    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _initialize = () => {
    try {
      if (
        localFilters?.filters?.OwnersPage &&
        localFilters?.filters?.OwnersPagePayload
      ) {
        setFilters(localFilters.filters.OwnersPage);
        setDataPayload(localFilters.filters.OwnersPagePayload);
        _getAndUpdateOwners(localFilters.filters.OwnersPagePayload);
      } else {
        _onPageChange();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Owners{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button color="primary" onClick={() => _toggleAddOwnerModal(true)}>
            New
          </Button>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
          <Button
            color="link"
            className="hideShowFilter"
            onClick={() => setFilterShow(!filterShow)}
          >
            <SvgIcons type={"filter"} />
          </Button>
        </div>
      </div>

      <div className={`filterWrapper ${filterShow === true ? "show" : ""}`}>
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchbar">
            <Label>Search</Label>
            <InputGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {loadingState?.search ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <div className="formGroup">
            <Label>Duration</Label>
            <CustomDateRangePicker
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_OwnersPage"}
              endDateId={"endDateId_OwnersPage"}
              onDatesChange={_onDatesChange}
              dateType={filters.dateType}
            />
          </div>
        </div>
      </div>

      <Row>
        {tableData?.ownersData?.length ? (
          <>
            {tableData?.ownersData?.map((each, index) => (
              <Col
                key={`ownersData_${each._id}_${index}`}
                className="my-2"
                xl={3}
                lg={4}
                md={6}
                sm={12}
              >
                <Card className="ownerCard">
                  <CardHeader>
                    <CardTitle>
                      <Button
                        color="link"
                        className="p-0"
                        onClick={() =>
                          navigate(`/${userType}/owner/${each?._id}`)
                        }
                      >
                        {getFullName(each.name)}
                      </Button>
                    </CardTitle>
                    <Button
                      color="link"
                      className="p-0"
                      onClick={() => _toggleAddOwnerModal(true, each)}
                    >
                      <SvgIcons type={"edit"} />
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <div className="ownerImage">
                      <img
                        src={
                          each.profilePic ||
                          each.profilePicUrl ||
                          DEFAULT_PROFILE_PICTURE
                        }
                        alt="profilePic"
                      />
                    </div>

                    <div className="ownerInfo">
                      {each?.phone && (
                        <>
                          <div
                            id={`user_phone_${each?._id}`}
                            onClick={() =>
                              copyToClipboard(
                                `${each?.countryCode || ""}${each?.phone}`
                              )
                            }
                          >
                            <SvgIcons type={"phone"} />
                          </div>

                          <CustomTooltip
                            target={`user_phone_${each?._id}`}
                            text={`${each?.countryCode || ""} ${each?.phone}`}
                          />
                        </>
                      )}
                      {each?.email && (
                        <>
                          <div
                            id={`user_email_${each?._id}`}
                            onClick={() => copyToClipboard(each?.email)}
                          >
                            <SvgIcons type={"email"} />
                          </div>

                          <CustomTooltip
                            target={`user_email_${each?._id}`}
                            text={each?.email}
                          />
                        </>
                      )}
                      <span>
                        {formatCurrencyValue(each.weeklyPayout || 0)}/wk
                      </span>
                    </div>
                    <ul>
                      {each.bankDetails?.map((bank, bankIndex) => (
                        <li
                          key={`manage_owner_bankDetails_${each._id}_${index}_${bank._id}_${bankIndex}`}
                        >
                          <span>{capitalize(bank.name) || "N/A"}</span>

                          {/* account number */}
                          <ShowHideText text={bank.accountNumber} />
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="link"
                      onClick={() => _toggleOwnerPaymentModal(true, each)}
                    >
                      Payment History
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </>
        ) : loadingState?.data ? (
          <>
            {/* Col already in there */}
            <SkeletonLoading type="card" height={320} count={3} />
          </>
        ) : (
          <Col>
            <Card>
              <CardBody>
                <NoDataDiv />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>

      <CustomPagination
        data={tableData?.ownersData}
        dataCount={tableData?.ownersDataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        isPageStartFromOne={false}
        loading={loadingState?.data}
      />

      <AddOwnerModal
        isOpen={addOwnerModal.isOpen}
        data={addOwnerModal.data}
        toggle={() => _toggleAddOwnerModal()}
        onSuccess={(isEdit = false) =>
          isEdit ? _getAndUpdateOwners(dataPayload) : _onPageChange()
        }
      />

      <OwnerPaymentModal
        isOpen={ownerPaymentModal.isOpen}
        data={ownerPaymentModal.data}
        toggle={() => _toggleOwnerPaymentModal()}
      />
    </>
  );
};

export default OwnersPage;
