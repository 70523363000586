import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import {
  capitalize,
  deepClone,
  errorHandler,
  getFullName,
  splitFullName,
} from "../../helper-methods";
import { createClient, updateClient } from "../../http/http-calls";

const initialFormFields = {
  name: "",
  email: "",
  phone: "",
  countryCode: "+1",
  location: "",
};

const AddClientModal = ({ isOpen, data, toggle, onSuccess = () => {} }) => {
  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      name: data?.name ? getFullName(data?.name) : "",
      email: data?.email || "",
      phone: data?.phone || "",
      countryCode: "+1",
      location: data?.location || "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "email":
            case "phone": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*Invalid ${key}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "name": {
              if (newFormFields[key]?.length) {
                if (
                  newFormFields[key]?.length >= 2 &&
                  newFormFields[key]?.length <= 50
                ) {
                  if (
                    RegexConfig.nameWithoutSpecialCharactersAndNumber.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Invalid ${key}`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] =
                    "*Please enter a value between 2 and 50 characters.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "location": {
              if (newFormFields[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 10)
    ) {
      return;
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const { firstName, lastName } = splitFullName(
        capitalize(newFormFields?.name?.trim())
      );

      const payload = {
        firstName,
        lastName,
        email: newFormFields?.email?.trim(),
        phone: newFormFields?.phone?.trim(),
        countryCode: newFormFields?.countryCode?.trim(),
        location: newFormFields?.location?.trim(),
      };

      if (data?._id) {
        await updateClient({ id: data?._id, payload });
      } else {
        await createClient(payload);
      }

      onSuccess();

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <Modal isOpen={isOpen} scrollable centered size={"lg"}>
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                placeholder="Enter"
                value={formFields.name}
                onChange={(e) => _onChangeFormFields("name", e.target.value)}
                onBlur={() => _onBlurFormFields("name")}
              />
              {errors["name"] ? (
                <div className="form-error">{errors["name"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone Number</Label>
              <InputGroup>
                <InputGroupText>+1</InputGroupText>
                <Input
                  placeholder="Enter"
                  value={formFields.phone}
                  onChange={(e) => _onChangeFormFields("phone", e.target.value)}
                  onBlur={() => _onBlurFormFields("phone")}
                />
              </InputGroup>
              {errors["phone"] ? (
                <div className="form-error">{errors["phone"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                placeholder="Enter"
                value={formFields.email}
                onChange={(e) => _onChangeFormFields("email", e.target.value)}
                onBlur={() => _onBlurFormFields("email")}
              />
              {errors["email"] ? (
                <div className="form-error">{errors["email"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <Label>Location</Label>
            <FormGroup>
              <Input
                placeholder="Enter"
                value={formFields.location}
                onChange={(e) =>
                  _onChangeFormFields("location", e.target.value)
                }
                onBlur={() => _onBlurFormFields("location")}
              />
              {errors["location"] ? (
                <div className="form-error">{errors["location"]}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button color="primary" disabled={loading} onClick={() => _onSave()}>
          {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddClientModal;
