import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getSettingsData } from "../../http/http-calls";

const getAndUpdateSettingsData = createAsyncThunk(
  "tableData/getAndUpdateSettingsData",
  async (payload, thunkAPI) => {
    const res = await getSettingsData();

    return {
      data: res.setting,
    };
  }
);

const clearAllSettingsData = createAction("clearAllSettingsData");

export { getAndUpdateSettingsData, clearAllSettingsData };
