import React, { useMemo, useState } from "react";
import { Progress, Table } from "reactstrap";
import CompactText from "./custom/CompactText";
import { formatDate } from "../helper-methods";
import CustomPagination from "./Table/CustomPagination";
import NoDataDiv from "./NoDataDiv";

const AffiliateGoalsTable = ({ lists = [], isProgressShow = true }) => {
  const [dataPayload, setDataPayload] = useState({
    pageNumber: 1,
    limit: 5,
  });

  const paginationData = useMemo(() => {
    return lists?.slice(
      (dataPayload?.pageNumber - 1) * dataPayload?.limit,
      (dataPayload?.pageNumber - 1) * dataPayload?.limit + dataPayload?.limit
    );
  }, [lists, dataPayload]);

  const _onSizeChange = (limit = 5) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = 1;
    newDataPayload["limit"] = limit;

    setDataPayload(newDataPayload);
  };

  const _onPageChange = (pageNumber = 1) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = pageNumber;
    setDataPayload(newDataPayload);
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Objective</th>
            <th>Duration</th>
            {isProgressShow && <th>Progress</th>}
          </tr>
        </thead>
        <tbody>
          {paginationData?.length ? (
            paginationData?.map((each) => (
              <tr key={each._id}>
                <td>
                  <CompactText
                    text={each._goal?.objective || each?.objective || "N/A"}
                  />
                </td>
                <td>
                  {(each._goal?.dateRange?.from || each?.dateRange?.from) &&
                  (each._goal?.dateRange?.to || each.dateRange?.to) ? (
                    <>
                      {formatDate(
                        each._goal?.dateRange?.from || each?.dateRange?.from
                      )}{" "}
                      -{" "}
                      {formatDate(
                        each._goal?.dateRange?.to || each.dateRange?.to
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                {isProgressShow && (
                  <td>
                    <Progress color="success" value={each.progress || 0} />
                    <span className="text-end d-block text-success">
                      {each.progress || 0}%
                    </span>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>
                <NoDataDiv />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div className="mb-3">
        <CustomPagination
          data={paginationData}
          dataCount={lists?.length}
          dataPayload={dataPayload}
          onPageChange={_onPageChange}
          onSizeChange={_onSizeChange}
          isPageStartFromOne={true}
          isShowLimitOption={false}
        />
      </div>
    </>
  );
};

export default AffiliateGoalsTable;
