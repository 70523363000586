import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
} from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../helper-methods";
import { updateLeaderboardSetting } from "../../http/http-calls";
import { leaderboardVisibilitySettings } from "../../config/helper-config";

const initialFormFields = {
  affiliateName: true,
  productSales: true,
  earnings: false,
  deals: false,
  goalsAchieved: false,
};

const LeaderBoardSettingModal = ({
  isOpen,
  data,
  toggle,
  onSuccess = () => {},
}) => {
  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [loading, setLoading] = useState(false);

  const _closeModal = () => {
    setLoading(false);
    toggle();
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };

      setLoading(true);

      const payload = {
        affiliateName: newFormFields?.affiliateName ? true : false,
        productSales: newFormFields?.productSales ? true : false,
        earnings: newFormFields?.earnings ? true : false,
        deals: newFormFields?.deals ? true : false,
        goalsAchieved: newFormFields?.goalsAchieved ? true : false,
      };

      await updateLeaderboardSetting(payload);

      showToast("Setting has been updated", "success");

      onSuccess();

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _initializeState = (data) => {
    setFormFields({
      affiliateName: data?.affiliateName ? true : false,
      productSales: data?.productSales ? true : false,
      earnings: data?.earnings ? true : false,
      deals: data?.deals ? true : false,
      goalsAchieved: data?.goalsAchieved ? true : false,
    });
  };

  useEffect(() => {
    if (isOpen && data) {
      _initializeState(data);
    }
  }, [isOpen, data]);

  return (
    <Modal isOpen={isOpen} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>
        Leaderboard Settings
      </ModalHeader>

      <ModalBody>
        <p className="text-center">
          <b>Enable/Disable Visibility Settings for Affiliates</b>
        </p>

        <Row>
          {leaderboardVisibilitySettings.map((each, index) => (
            <Col
              key={`leaderboardVisibilitySettings_${each.value}_${index}`}
              md={12}
            >
              <FormGroup switch className="leaderBoardSettingWrap">
                  <Label
                    for={`leaderboardVisibilitySettings_input_${each.value}_${index}`}
                  >
                    {each.label}
                  </Label>

                  <Input
                    id={`leaderboardVisibilitySettings_input_$ {each.value}_${index}`}
                    type="switch"
                    value={each.value}
                    checked={formFields[each.value] === true}
                    onChange={(e) =>
                      _onChangeFormFields(each.value, e.target.checked)
                    }
                  />
              </FormGroup>
            </Col>
          ))}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button color="primary" disabled={loading} onClick={() => _onSave()}>
          {loading ? <i className="fa fa-spinner fa-spin" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LeaderBoardSettingModal;
