import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import {
  templateCategoryConfig,
  templateVariablesConfig,
} from "../../config/templateConfig";
import { errorHandler, showToast } from "../../helper-methods";
import {
  createTemplate,
  getTemplateById,
  updateTemplate,
} from "../../http/http-calls";
import TextEditor from "../TextEditor";

const AddTemplateModal = ({ isOpen, data, toggle, onSuccess }) => {
  const [formFields, setFormFields] = useState({
    title: "",
    category: "Email",
    subject: "",
    body: "",
    message: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      if (newFormFields) {
        Object.keys(newFormFields).forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "title":
              case "category": {
                if (newFormFields[key]?.trim().length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "subject": {
                if (
                  newFormFields[key]?.trim().length ||
                  formFields.category !== "Email"
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "message": {
                if (
                  newFormFields[key]?.trim().length ||
                  formFields.category !== "Text"
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "body": {
                if (
                  (newFormFields[key]?.trim().length &&
                    newFormFields[key] !== "<p><br></p>") ||
                  formFields.category !== "Email"
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        });
      }

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onSelectVariable = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] += value;
    setFormFields(newFormFields);

    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _setModalState = (data = null) => {
    setFormFields({
      title: data?.title || "",
      category: data?.category || "Email",
      subject: data?.subject || "",
      body: data?.body || "",
      message: data?.category === "Text" && data?.body ? data?.body : "",
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _createTemplate = (payload) => {
    createTemplate(payload)
      .then((res) => {
        showToast("Template has been added", "success");
        onSuccess();
        _closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _updateTemplate = ({ id, payload }) => {
    updateTemplate({ id, payload })
      .then((res) => {
        showToast("Template has been updated", "success");
        onSuccess();
        _closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _onSaveDetails = async () => {
    try {
      const newFormFields = { ...formFields };

      const newIsDirty = {
        title: true,
        category: true,
        subject: true,
        body: true,
        message: true,
      };

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      const payload = {
        title: formFields.title.trim(),
        category: formFields.category.trim(),
      };

      if (payload.category === "Email") {
        payload.subject = formFields.subject.trim();
        payload.body = formFields.body.trim();
      } else {
        payload.subject = "";
        payload.body = formFields.message.trim();
      }

      if (data?._id) {
        _updateTemplate({ id: data._id, payload });
      } else {
        _createTemplate(payload);
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _getTemplateById = (id) => {
    setLoading(true);
    getTemplateById(id)
      .then((res) => {
        _setModalState(res.template);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        _closeModal();
      });
  };

  useEffect(() => {
    if (isOpen && data?._id) _getTemplateById(data._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"} Template
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Template Name</Label>
          <Input
            type="text"
            value={formFields.title}
            name="title"
            placeholder="Enter"
            onChange={(e) => _onChangeFormFields("title", e.target.value)}
            onBlur={() => _onBlurFormFields("title")}
            disabled={loading}
          />
          {errors["title"] ? (
            <div className="form-error">{errors["title"]}</div>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Category</Label>
          <Input
            type="select"
            value={formFields.category}
            name="category"
            onChange={(e) => _onChangeFormFields("category", e.target.value)}
            onBlur={() => _onBlurFormFields("category")}
          >
            <option value="">Select</option>
            {templateCategoryConfig.map((each) => (
              <option key={each.value} value={each.value}>
                {each.label}
              </option>
            ))}
          </Input>
          {errors["category"] ? (
            <div className="form-error">{errors["category"]}</div>
          ) : null}
        </FormGroup>

        {formFields.category === "Email" ? (
          <>
            <FormGroup>
              <div className="messageVeriabel">
                <Label className="mb-0">Subject</Label>

                <UncontrolledDropdown direction="down" dropup={false}>
                  <DropdownToggle caret>Insert Variables</DropdownToggle>
                  <DropdownMenu end>
                    {React.Children.toArray(
                      templateVariablesConfig.map((each) => (
                        <DropdownItem
                          onClick={() =>
                            _onSelectVariable("subject", each.variable)
                          }
                        >
                          {each.variable}
                        </DropdownItem>
                      ))
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <Input
                type="text"
                value={formFields.subject}
                name="subject"
                onChange={(e) => _onChangeFormFields("subject", e.target.value)}
                onBlur={() => _onBlurFormFields("subject")}
                disabled={loading}
              />
              {errors["subject"] ? (
                <div className="form-error">{errors["subject"]}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <div className="messageVeriabel">
                <Label className="mb-0">Body</Label>

                <UncontrolledDropdown direction="down">
                  <DropdownToggle caret>Insert Variables</DropdownToggle>
                  <DropdownMenu end>
                    {React.Children.toArray(
                      templateVariablesConfig.map((each) => (
                        <DropdownItem
                          onClick={() =>
                            _onSelectVariable("body", each.variable)
                          }
                        >
                          {each.variable}
                        </DropdownItem>
                      ))
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <TextEditor
                className="editorHeight"
                content={formFields.body}
                onChange={(value) => _onChangeFormFields("body", value)}
                onBlur={() => _onBlurFormFields("body")}
              />
              {errors["body"] ? (
                <div className="form-error">{errors["body"]}</div>
              ) : null}
            </FormGroup>
          </>
        ) : null}

        {formFields.category === "Text" ? (
          <FormGroup>
            <div className="messageVeriabel">
              <Label className="mb-0">Message</Label>

              <UncontrolledDropdown direction="down">
                <DropdownToggle caret>Insert Variables</DropdownToggle>
                <DropdownMenu end>
                  {React.Children.toArray(
                    templateVariablesConfig.map((each) => (
                      <DropdownItem
                        onClick={() =>
                          _onSelectVariable("message", each.variable)
                        }
                      >
                        {each.variable}
                      </DropdownItem>
                    ))
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <Input
              type="textarea"
              rows="6"
              value={formFields.message}
              name="message"
              onChange={(e) => _onChangeFormFields("message", e.target.value)}
              onBlur={() => _onBlurFormFields("message")}
              disabled={loading}
            />
            {errors["message"] ? (
              <div className="form-error">{errors["message"]}</div>
            ) : null}
          </FormGroup>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _onSaveDetails()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTemplateModal;
