import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { hasPermission, logout } from "../../helper-methods";
import { APP_LOGO } from "../../config";
import { getNavLinks } from "../../config/navLinks";
import SvgIcons from "../../components/SvgIcons";
import { useSelector } from "react-redux";

const DashboardHeader = ({ isShow, setIsShow }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [maxShowLink, setMaxShowLink] = useState(7);

  const _logout = () => {
    logout(navigate);
  };

  // menu
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  // menu
  const _isActiveTab = (route) => {
    return location?.pathname === route ? true : false;
  };

  const _toggleSidebar = () => {
    setIsShow(!isShow);
  };

  useEffect(() => {
    const _windowSizeHandler = () => {
      const width = window.innerWidth;

      if (width > 1300) {
        setMaxShowLink(7);
      } else if (width > 1150) {
        setMaxShowLink(6);
      } else if (width > 991.98) {
        setMaxShowLink(5);
      } else if (width > 830) {
        setMaxShowLink(4);
      } else if (width > 767.98) {
        setMaxShowLink(3);
      } else {
        // 578.98
        setMaxShowLink(3);
      }
    };

    _windowSizeHandler();

    window.addEventListener("resize", _windowSizeHandler);

    return () => {
      window.removeEventListener("resize", _windowSizeHandler);
    };
  }, []);

  return (
    <Navbar expand="md" className="projectHeader" light>
      <NavbarBrand onClick={() => navigate("/")} className="order-1">
        <img src={APP_LOGO} alt="Logo" />
      </NavbarBrand>

      <Button
        onClick={() => _toggleSidebar()}
        className="order-md-1 toggleIcon "
      >
        <span></span>
      </Button>

      <Nav className="m-auto order-md-2 d-md-flex d-none" navbar>
        {getNavLinks()
          .slice(0, maxShowLink)
          .map((each, index) =>
            hasPermission({ page: each?.value, action: "view" }) ? (
              <NavItem key={`navLinks_header_${index}`}>
                <NavLink
                  className={_isActiveTab(each.link) ? "active" : ""}
                  onClick={() => navigate(each.link)}
                >
                  {each.label}
                </NavLink>
              </NavItem>
            ) : null
          )}

        {getNavLinks().slice(maxShowLink)?.length ? (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              className={
                getNavLinks()
                  .slice(maxShowLink)
                  .some((each) => _isActiveTab(each.link))
                  ? "active"
                  : ""
              }
            >
              More <SvgIcons type="arrowDown" />
            </DropdownToggle>
            <DropdownMenu end>
              {getNavLinks()
                .slice(maxShowLink)
                .map((each, index) =>
                  hasPermission({ page: each?.value, action: "view" }) ? (
                    <Fragment key={`navLinks_header_options_${index}`}>
                      <DropdownItem
                        className={_isActiveTab(each.link) ? "active" : ""}
                        onClick={() => navigate(each.link)}
                      >
                        {each.label}
                      </DropdownItem>
                      {/* {index < getNavLinks().slice(maxShowLink).length - 1 ? (
                      <DropdownItem divider />
                    ) : null} */}
                    </Fragment>
                  ) : null
                )}
            </DropdownMenu>
          </UncontrolledDropdown>
        ) : null}
      </Nav>
      <div className="order-3 navbarRight">
        {userType === "admin" && (
          <Button
            color="link"
            onClick={() => navigate(`/${userType}/settings`)}
          >
            <SvgIcons type={"setting"} />
          </Button>
        )}

        <Button onClick={() => _logout()} color="link " className="text-danger">
          <i className="fas fa-power-off" />
        </Button>
      </div>
    </Navbar>
  );
};

export default DashboardHeader;
