import React from "react";
import { Input, Label } from "reactstrap";
import SvgIcons from "../SvgIcons";

const FileUploadSection = ({ disabled = false, _handelOnChange }) => (
  <div className="topicUploadWrap">
    <Label for="uploadImage" className="uploadWrap">
      <Input
        type="file"
        id="uploadImage"
        accept="image/*"
        disabled={disabled}
        onChange={(event) => _handelOnChange("imageFiles", event)}
      />
      <SvgIcons type="image" />
      <span>Upload Thumbnail</span>
    </Label>

    <Label for="uploadVideo" className="uploadWrap">
      <Input
        type="file"
        id="uploadVideo"
        accept="video/*"
        disabled={disabled}
        onChange={(event) => _handelOnChange("videoFiles", event)}
      />
      <SvgIcons type="video" />
      <span>Upload Video</span>
    </Label>

    <Label for="uploadPdf" className="uploadWrap">
      <Input
        type="file"
        id="uploadPdf"
        accept="application/*"
        disabled={disabled}
        onChange={(event) => _handelOnChange("pdfFiles", event)}
      />
      <SvgIcons type="docFile" />
      <span>Upload PDF Notes</span>
    </Label>
  </div>
);

export default FileUploadSection;
