import React, { useMemo, useState } from "react";
import SvgIcons from "./SvgIcons";
import { Button } from "reactstrap";
import CustomTooltip from "./custom/CustomTooltip";

const ShowHideText = ({ text = "" }) => {
  const id = useMemo(() => {
    return Math.floor(Math.random() * 1000000);
  }, []);

  const [isShow, setIsShow] = useState(false);

  const _toggleIsShow = () => {
    setIsShow((prev) => !prev);
  };

  if (!text || text === "N/A") {
    return text;
  }

  return (
    <>
      <div>
        {isShow ? (
          <>{text}</>
        ) : (
          <>
            {Array(String(text).length - 4)
              .fill("*")
              .join("")}{" "}
            {String(text).slice(-4)}
          </>
        )}

        <Button
          id={`showHideText_button_tooltip_${id}`}
          color="link"
          onClick={() => _toggleIsShow()}
        >
          <SvgIcons type={isShow ? "closeEye" : "eye"} />
        </Button>

        <CustomTooltip
          text={isShow ? "Hide" : "Show"}
          target={`showHideText_button_tooltip_${id}`}
        />
      </div>
    </>
  );
};

export default ShowHideText;
