import React, { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AffiliateDetailsPage from "../../pages/protected/AffiliateDetailsPage";
import LearningUserPage from "../../pages/protected/LearningUserPage";
import LearningUserDetailPage from "../../pages/protected/LearningUserDetailPage";
import { useSelector } from "react-redux";
import LeaderboardPage from "../../pages/protected/LeaderboardPage";
import ClientDetailsPage from "../../pages/protected/ClientDetailsPage";
import ProtectedRoute from "../../components/routes/ProtectedRoute";

const AffiliateRoutes = () => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  return (
    <>
      <Routes>
        <Route
          path={`${userType}/*`}
          element={<ProtectedRoute redirectRoute={"/login"} />}
        >
          <Route exact path="home" element={<AffiliateDetailsPage />} />

          <Route exact path="leaderboard" element={<LeaderboardPage />} />

          <Route
            exact
            path="affiliate/:id"
            element={<AffiliateDetailsPage />}
          />

          <Route exact path="client/:id" element={<ClientDetailsPage />} />

          <Route
            exact
            path="learning-center-user"
            element={<LearningUserPage />}
          />

          <Route
            exact
            path="learning-center-user/:id"
            element={<LearningUserDetailPage />}
          />

          <Route
            index
            element={<Navigate replace to={`/${userType}/home`} />}
          />

          <Route
            path="*"
            element={<Navigate replace to={`/${userType}/home`} />}
          />
        </Route>

        <Route path="*" element={<Navigate replace to={`/${userType}`} />} />
      </Routes>
    </>
  );
};

export default AffiliateRoutes;
