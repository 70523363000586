import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Table,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import Swal from "sweetalert2";
import AddTemplateModal from "../../components/modals/AddTemplateModal";
import TemplateLogModal from "../../components/modals/TemplateLogModal";
import CustomPagination from "../../components/Table/CustomPagination";
import { templateCategoryConfig } from "../../config/templateConfig";
import {
  errorHandler,
  formatDate,
  hasPermission,
  showToast,
} from "../../helper-methods";
import {
  deleteTemplate,
  getAllTemplates,
  updateTemplate,
} from "../../http/http-calls";
import SvgIcons from "../../components/SvgIcons";
import SkeletonLoading from "../../components/SkeletonLoading";
import ActionButtonsComponent from "../../components/ActionButtonsComponent";
import CompactText from "../../components/custom/CompactText";
import NoDataDiv from "../../components/NoDataDiv";
import { useNavigate } from "react-router-dom";

const MessageTemplatesPage = () => {
  const searchInterval = useRef({ current: null });
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    page: 1,
    limit: 10,
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filters, setFilters] = useState({
    category: "",
    status: "",
  });

  const [addTemplateModal, setAddTemplateModal] = useState({
    isOpen: false,
    data: null,
  });
  const [templateLogModal, setTemplateLogModal] = useState({
    isOpen: false,
    data: null,
  });

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "messageTemplates", action: "view" }),
    []
  );

  const hasPermissionToCreate = useMemo(
    () => hasPermission({ page: "messageTemplates", action: "create" }),
    []
  );

  const hasPermissionToEdit = useMemo(
    () => hasPermission({ page: "messageTemplates", action: "edit" }),
    []
  );

  const _toggleAddTemplateModal = (isOpen = false, data = null) => {
    setAddTemplateModal({ isOpen, data });
  };

  const _toggleTemplateLogModal = (isOpen = false, data = null) => {
    setTemplateLogModal({ isOpen, data });
  };

  const _getAllTemplates = (payload) => {
    setLoading(true);

    getAllTemplates(payload)
      .then((res) => {
        setData(res?.templates?.length ? res.templates : []);
        setDataCount(res?.totalCount || 0);
        setLoading(false);
        setFiltersLoading(false);
        setSearchLoading(false);
        setStatusLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
        setFiltersLoading(false);
        setSearchLoading(false);
        setStatusLoading(false);
      });
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllTemplates(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllTemplates(newDataPayload);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
      }
    });

    setDataPayload(newDataPayload);

    _getAllTemplates(newDataPayload);
  };

  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);
    setSearchLoading(true);
    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    setDataPayload(newDataPayload);
    searchInterval.current = setTimeout(() => {
      _getAllTemplates(newDataPayload);
    }, 1000);
  };

  const _clearFilters = () => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = "";
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};
    setDataPayload(newDataPayload);

    _getAllTemplates(newDataPayload);
  };

  const _onChangeStatus = (template, status) => {
    if (!template?._id) {
      errorHandler({ reason: "Template data not found" });
      return;
    }

    setStatusLoading(true);

    const payload = {
      isActive: status === "active" ? true : false,
    };

    const newData = [...data];
    const findData = newData.find((each) => each._id === template._id);
    if (findData) {
      findData.isActive = payload.isActive;
      setData(newData);
    }

    updateTemplate({ id: template._id, payload })
      .then((res) => {
        setStatusLoading(false);
        showToast("Status has been updated", "success");
      })
      .catch((error) => {
        errorHandler(error);
        setStatusLoading(false);
        _getAllTemplates(dataPayload);
      });
  };

  const _deleteTemplate = (id) => {
    setDeleteLoading(id);

    const newData = [...data];
    let newDataCount = dataCount;
    const findDataIndex = newData.findIndex((each) => each._id === id);
    if (findDataIndex > -1) {
      newData.splice(findDataIndex, 1);
      setData(newData);
      newDataCount--;
      setDataCount(newDataCount);
    }

    deleteTemplate(id)
      .then(async (res) => {
        Swal.fire("Deleted!", "Template has been deleted.", "success");
        if (data.length === 0 || newDataCount <= 10) {
          _onPageChange();
        }
        setDeleteLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setDeleteLoading(false);
        _getAllTemplates(dataPayload);
      });
  };

  const _deleteTemplateAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this (Title: ${data?.title}) Template.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteTemplate(data._id);
      }
    });
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      navigate("/");
    }

    _getAllTemplates(dataPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Message Templates{" "}
          {loading || filtersLoading ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button
            color="primary"
            onClick={() => _toggleAddTemplateModal(true)}
            disabled={!hasPermissionToCreate}
          >
            Add Template
          </Button>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <div className="filterWrapper">
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchBar">
            <Label>Search</Label>
            <InputGroup className="searchTable">
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={dataPayload.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : dataPayload.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>
          <div className="formGroup">
            <Label>Category</Label>
            <Input
              type="select"
              value={filters.category}
              name="category"
              onChange={(e) => _onChangeFilters("category", e.target.value)}
            >
              <option value="">All</option>
              {templateCategoryConfig.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>
          <div className="formGroup">
            <Label>Status</Label>
            <Input
              type="select"
              value={filters.status}
              name="status"
              onChange={(e) => _onChangeFilters("status", e.target.value)}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
          </div>
        </div>
      </div>

      <div className="tableWrapper checkboxTable">
        <Table responsive>
          <thead>
            <tr>
              <th>Title</th>
              <th>Message</th>
              <th>Category</th>
              <th>Created On</th>
              <th>Updated At</th>
              <th>Log</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data.map((each) => (
                <tr key={each?._id}>
                  <td>
                    <CompactText text={each.title || "N/A"} />
                  </td>
                  <td>
                    <div
                      id={`newMessageText_${each._id}`}
                      className="themeColor cursorPointer w-auto d-inline-block pe-3"
                    >
                      <SvgIcons type="eye" />
                    </div>
                    <UncontrolledPopover
                      target={`newMessageText_${each._id}`}
                      trigger="legacy"
                    >
                      {each.category === "Email" ? (
                        <PopoverBody>
                          <div>
                            Subject:
                            <p>{each.subject}</p>
                          </div>
                          <hr />
                          <div>
                            Body:
                            <div
                              dangerouslySetInnerHTML={{
                                __html: each.body,
                              }}
                            />
                          </div>
                        </PopoverBody>
                      ) : (
                        <PopoverBody>{each.body}</PopoverBody>
                      )}
                    </UncontrolledPopover>
                  </td>
                  <td>{each.category || "-"}</td>
                  <td>{formatDate(each.createdAt)}</td>
                  <td>{formatDate(each.updatedAt)}</td>
                  <td>
                    {each.sentCount ? (
                      <div
                        className="themeColor text-decoration-underline cursorPointer"
                        onClick={() => _toggleTemplateLogModal(true, each)}
                      >
                        {each.sentCount}
                      </div>
                    ) : (
                      "0"
                    )}
                  </td>

                  <td>
                    <Input
                      style={{ width: 100 }}
                      type="select"
                      disabled={statusLoading ? true : false}
                      value={each.isActive ? "active" : "inactive"}
                      onChange={(e) => _onChangeStatus(each, e.target.value)}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Input>
                  </td>

                  <td>
                    <ActionButtonsComponent
                      data={each}
                      deleteLoading={deleteLoading}
                      onDelete={_deleteTemplateAlert}
                      onEdit={(each) => _toggleAddTemplateModal(true, each)}
                      hasPermissionToEdit={hasPermissionToEdit}
                      hasPermissionToDelete={hasPermissionToEdit}
                    />
                  </td>
                </tr>
              ))
            ) : loading ? (
              <SkeletonLoading type="table" height={20} row={5} col={8} />
            ) : (
              <tr className="text-center">
                <td colSpan="8">
                  <NoDataDiv />
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <CustomPagination
          data={data}
          dataCount={dataCount}
          dataPayload={dataPayload}
          onPageChange={_onPageChange}
          onSizeChange={_onSizeChange}
        />
      </div>

      <AddTemplateModal
        isOpen={addTemplateModal.isOpen}
        data={addTemplateModal.data}
        toggle={() => _toggleAddTemplateModal()}
        onSuccess={() => _onPageChange()}
      />

      <TemplateLogModal
        isOpen={templateLogModal.isOpen}
        data={templateLogModal.data}
        toggle={() => _toggleTemplateLogModal()}
      />
    </>
  );
};

export default MessageTemplatesPage;
