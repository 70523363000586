import React, { useMemo, useState } from "react";
import { PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import { formatDate, getFullName } from "../helper-methods";
import CustomPagination from "./Table/CustomPagination";
import SvgIcons from "./SvgIcons";
import NoDataDiv from "./NoDataDiv";

const AffiliateMessagesHistoryTable = ({ lists = [] }) => {
  const [dataPayload, setDataPayload] = useState({
    pageNumber: 1,
    limit: 5,
  });

  const paginationData = useMemo(() => {
    return lists?.slice(
      (dataPayload?.pageNumber - 1) * dataPayload?.limit,
      (dataPayload?.pageNumber - 1) * dataPayload?.limit + dataPayload?.limit
    );
  }, [lists, dataPayload]);

  const _onSizeChange = (limit = 5) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = 1;
    newDataPayload["limit"] = limit;

    setDataPayload(newDataPayload);
  };

  const _onPageChange = (pageNumber = 1) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = pageNumber;
    setDataPayload(newDataPayload);
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Message</th>
            <th>By</th>
          </tr>
        </thead>
        <tbody>
          {paginationData?.length ? (
            paginationData?.map((each, index) => (
              <tr key={`AffiliateMessagesHistoryTable_${each._id}_${index}`}>
                <td>{formatDate(each.date) || "N/A"}</td>
                <td>
                  <div
                    id={`newMessageText_${each._id}_${index}`}
                    className="themeColor cursorPointer w-auto d-inline-block pe-3"
                  >
                    <SvgIcons type="eye" />
                  </div>

                  <UncontrolledPopover
                    target={`newMessageText_${each._id}_${index}`}
                    trigger="legacy"
                  >
                    <PopoverBody>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: each.message,
                        }}
                      />
                    </PopoverBody>
                  </UncontrolledPopover>
                </td>
                <td>{getFullName(each.from?.name) || "N/A"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>
                <NoDataDiv />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <CustomPagination
        data={paginationData}
        dataCount={lists?.length}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        isPageStartFromOne={true}
      />
    </>
  );
};

export default AffiliateMessagesHistoryTable;
