import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import SvgIcons from "../SvgIcons";
import EditSettingsModal from "../modals/EditSettingsModal";
import { formatCurrencyValue } from "../../helper-methods";

const SettingsCard = ({
  heading = "Store",
  data,
  className = "",
  getAndUpdateSettingsData = () => {},
}) => {
  const [editSettingsModal, setEditSettingsModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleEditSettingsModal = (isOpen = false, data = null) => {
    setEditSettingsModal({ isOpen, data });
  };

  return (
    <>
      <Card className={className}>
        <CardHeader>
          <CardTitle>{heading}</CardTitle>
          <Button
            color="link"
            onClick={() => _toggleEditSettingsModal(true, data)}
          >
            <SvgIcons type={"edit"} />
          </Button>
        </CardHeader>
        <CardBody>
          <div className="infoWrap">
            <ul>
              <li>
                <span>Cost</span>
                <div className="inotItem">
                  {formatCurrencyValue(data?.cost || 0)}
                </div>
              </li>
              <li>
                <span>Commissionable Value</span>
                <div className="inotItem">
                  {formatCurrencyValue(data?.commissionableValue || 0)}
                </div>
              </li>
              {/* <li>
                <span>Affiliate Commission</span>
                <div className="inotItem">{data?.affiliateShare || 0}%</div>
              </li>
              <li>
                <span>Owner's Partner Distribution</span>
                <div className="inotItem">
                  {data?.ownersPartnerDistribution || 3}%
                </div>
              </li>
              <li>
                <span>Owner's Sales Commission</span>
                <div className="inotItem">
                  {data?.ownersSalesCommission || 40}%
                </div>
              </li>
              <li>
                <span>Owner Commission</span>
                <div className="inotItem">{data?.ownerShare || 0}%</div>
              </li> */}
            </ul>
          </div>
        </CardBody>
      </Card>

      <EditSettingsModal
        heading={heading}
        isOpen={editSettingsModal.isOpen}
        data={editSettingsModal.data}
        toggle={() => _toggleEditSettingsModal()}
        onSuccess={() => getAndUpdateSettingsData()}
      />
    </>
  );
};

export default SettingsCard;
