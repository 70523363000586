import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledPopover,
  PopoverBody,
  Spinner,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import {
  errorHandler,
  formatDate,
  formatTime,
  getFullName,
} from "../../helper-methods";
import { getAllActivites } from "../../http/http-calls";
import SvgIcons from "../SvgIcons";
import NoDataDiv from "../NoDataDiv";

const TemplateLogModal = ({ isOpen, data, toggle }) => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const _closeModal = () => {
    toggle();
  };

  const _getAllActivites = async () => {
    try {
      setLoading(true);

      const payload = {
        templateId: data?._id,
      };

      const res = await getAllActivites(payload);

      setActivities(res.activities?.length ? res.activities : []);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _getAllActivites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const filteredActivities = useMemo(() => {
    if (search?.trim().length) {
      const serachValue = search?.trim().toLowerCase();
      return activities.filter((each) => {
        return (
          each?.message?.toLowerCase()?.includes(serachValue) ||
          each?.subject?.toLowerCase()?.includes(serachValue) ||
          each?.description?.toLowerCase()?.includes(serachValue) ||
          getFullName(each._lead?.name)?.toLowerCase()?.includes(serachValue) ||
          getFullName(each._affiliate?.name)
            ?.toLowerCase()
            ?.includes(serachValue) ||
          getFullName(each._createdBy?.name)
            ?.toLowerCase()
            ?.includes(serachValue)
        );
      });
    }
    return activities;
  }, [activities, search]);

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => _closeModal()}
      scrollable
      centered
      className="modal-lg message-template"
      backdrop="static"
    >
      {/* the modal header name will be dynamic. Either "Email Template Log" or "Message Template Log"*/}
      <ModalHeader toggle={() => _closeModal()}>
        {data?.category === "Text" ? "Message" : data?.category || ""} Template
        Log
      </ModalHeader>
      <ModalBody>
        <div className="filterWrapper">
          <div className="filterIcon">
            <i className="fas fa-filter" />
          </div>

          <div className="filterForm">
            <div className="formGroup searchbar">
              <Label>Search</Label>
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <InputGroupText>
                  {search ? (
                    <i className="fa fa-times" onClick={() => setSearch("")} />
                  ) : (
                    <i className="fa fa-search" />
                  )}
                </InputGroupText>
              </InputGroup>
            </div>
          </div>
        </div>

        <div className="tableWrapper mb-0 shadow-none">
          <Table responsive>
            <thead>
              <tr>
                <th>Type</th>
                <th>Time</th>
                <th>Message</th>
                <th>By</th>
                <th>To</th>
              </tr>
            </thead>
            <tbody>
              {filteredActivities?.length ? (
                filteredActivities.map((each) => (
                  <tr key={each?._id}>
                    <td>
                      {each.activityType === "SMS"
                        ? "Text"
                        : each.activityType || "N/A"}
                    </td>
                    <td>
                      {formatDate(each.createdAt)} <br />
                      {formatTime(each.createdAt)}
                    </td>
                    <td>
                      {each.message ? (
                        <>
                          <div
                            id={`messageTemplate_${each._id}`}
                            className="themeColor cursorPointer w-auto d-inline-block pe-3"
                          >
                            <SvgIcons type="eye" />
                          </div>

                          <UncontrolledPopover
                            target={`messageTemplate_${each._id}`}
                            trigger="legacy"
                          >
                            {each.activityType === "Email" ? (
                              <PopoverBody>
                                <div>
                                  Subject:
                                  <p>{each.subject}</p>
                                </div>
                                <hr />
                                <div>
                                  Body:
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: each.message,
                                    }}
                                  />
                                </div>
                              </PopoverBody>
                            ) : (
                              <PopoverBody>{each.message}</PopoverBody>
                            )}
                          </UncontrolledPopover>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{getFullName(each._createdBy?.name) || "N/A"}</td>
                    <td>
                      {getFullName(each._lead?.name) ? (
                        <div
                          className="themeColor text-decoration-underline cursorPointer"
                          onClick={() =>
                            navigate(`/${userType}/client/${each._lead._id}`)
                          }
                          title={each._lead.email}
                        >
                          {getFullName(each._lead?.name)}
                        </div>
                      ) : getFullName(each._affiliate?.name) ? (
                        <div
                          className="themeColor text-decoration-underline cursorPointer"
                          onClick={() =>
                            navigate(
                              `/${userType}/affiliate/${each._affiliate._id}`
                            )
                          }
                          title={each._affiliate.email}
                        >
                          {getFullName(each._affiliate?.name)}
                        </div>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="5">{loading ? <Spinner /> : <NoDataDiv />}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" className="closeBtn" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TemplateLogModal;
