import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomTable from "../../components/Table/CustomTable";
import SendEmailTextComponent from "../../components/SendEmailTextComponent";
import { updateClient } from "../../http/http-calls";
import {
  copyToClipboard,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  getFullName,
  hasPermission,
  showToast,
} from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndUpdateClients,
  getAndUpdateTemplateList,
  getAndUpdateUsersList,
  saveLocalFilters,
  updateClientsDataByIndex,
} from "../../redux/actions";
import SvgIcons from "../../components/SvgIcons";
import {
  leadsProjectStatusConfig,
  domainPurchasedConfig,
  amountSpendConfig,
  storePurchasedConfig,
  webinarCountConfig,
} from "../../config/helper-config";
import SearchableInput from "../../components/SearchableInput";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../components/custom/CustomTooltip";
import LeadStatusNotes from "../../components/modals/LeadStatusNotes";
import ClientNotesModal from "../../components/modals/ClientNotesModal";
import CompactText from "../../components/custom/CompactText";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Name", id: "name" },
  { label: "Added Date", id: "createdAt" },
  { label: "Referred By", id: "_referBy" },
  { label: "Contact Status", id: "leadsProjectStatus" },
  { label: "Contract Signed", id: "contractSigned" },
  { label: "Invoice Paid", id: "invoicePaid" },
  { label: "Registered for webinar", id: "webinarAttended" },
  { label: "Domain Purchased", id: "domainPurchased" },
  { label: "Store Purchased", id: "storePurchased" },
  { label: "Pipeline Payment", id: "paymentDue" },
  { label: "Amount Spent", id: "amountSpend" },
  { label: "Notes", id: "notes" },
  { label: "DRE Link", id: "dreLink" },
  // { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const initialFiltersState = {
  startDate: null,
  endDate: null,
  _referBy: "",
  noOfDomain: "",
  noOfStore: "",
  webinarCount: "",
  amountSpend: "",
  isContractSign: false,
  isInvoicePaid: false,
  search: "",
  dateType: "",
  leadsProjectStatus: "",
};

const ClientsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchIntervalRef = useRef({ current: null });

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "clients", action: "view" }),
    []
  );

  const tableData = useSelector((state) => state?.tableData);
  const localFilters = useSelector((state) => state?.localFilters);
  const filtersList = useSelector((state) => state?.filtersList);

  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [filters, setFilters] = useState(deepClone(initialFiltersState));
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);

  const [clientNotesModal, setClientNotesModal] = useState({
    isOpen: false,
    data: null,
  });
  const [leadStatusNotes, setLeadStatusNotes] = useState({
    isOpen: false,
    data: null,
  });

  const [filterShow, setFilterShow] = useState(false);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleClientNotesModal = (isOpen = false, data = null) => {
    setClientNotesModal({ isOpen, data });
  };

  const _toggleLeadStatusNotes = (
    isOpen = false,
    data = null,
    isUpdateNote = false
  ) => {
    setLeadStatusNotes({ isOpen, data, isUpdateNote });
  };

  const _getAndUpdateUsersList = async () => {
    try {
      await getAndUpdateUsersList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllTemplatesList = async () => {
    try {
      await getAndUpdateTemplateList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAndUpdateClients = async (payload) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "ClientsPagePayload",
          value: deepClone(payload),
        })
      );

      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      await getAndUpdateClients(payload)(dispatch);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateClients(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateClients(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAndUpdateClients(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "ClientsPage",
          value: deepClone(newFilters),
        })
      );

      const newDataPayload = deepClone(initialDataPayloadState);

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (
            eachFilterKey === "noOfDomain" ||
            eachFilterKey === "noOfStore" ||
            eachFilterKey === "webinarCount" ||
            eachFilterKey === "amountSpend"
          ) {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey] === "Active" ? true : false;
          } else if (eachFilterKey === "_referBy") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey]?.value;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAndUpdateClients(newDataPayload);
    } catch (error) {}
  };

  const _onChangeFilters = (key, value) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate, dateType }) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    newFilters.dateType = dateType;

    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _clearFilters = () => {
    _manageLoadingState("filters", true);

    const newFilters = deepClone(initialFiltersState);

    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onChangeSelectedData = (value = "all", checked = false) => {
    if (value === "every") {
      setIsSelectAll(checked);
    } else {
      setIsSelectAll(false);
      let newSelectedDataIds = [...selectedDataIds];
      if (value === "all") {
        if (checked) {
          const currentPageIds = tableData?.clientsData
            ?.filter((each) => !newSelectedDataIds?.includes(each._id))
            .map((each) => each._id);
          newSelectedDataIds = [...newSelectedDataIds, ...currentPageIds];
        } else {
          const currentPageIds = tableData?.clientsData?.map(
            (each) => each._id
          );
          newSelectedDataIds = newSelectedDataIds.filter(
            (sid) => !currentPageIds.includes(sid)
          );
        }
      } else {
        if (checked) {
          newSelectedDataIds.push(value);
        } else {
          const findIndex = newSelectedDataIds.findIndex(
            (each) => each === value
          );
          if (findIndex >= 0) newSelectedDataIds.splice(findIndex, 1);
        }
      }
      setSelectedDataIds(newSelectedDataIds);
    }
  };

  const _onChangeStatus = async (data, status) => {
    try {
      if (!data?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      const findIndex = tableData?.clientsData?.findIndex(
        (e) => e._id === data?._id
      );

      if (findIndex <= -1) {
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        leadsProjectStatus: status,
      };

      dispatch(
        updateClientsDataByIndex({
          index: findIndex,
          key: "leadsProjectStatus",
          value: payload.leadsProjectStatus,
        })
      );

      await updateClient({ id: data?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getAndUpdateClients(dataPayload);
    }
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userContact">
                <h2
                  className={`${
                    row?.isActive === false ? "text-danger" : "text-primary"
                  }`}
                  onClick={() => navigate(`/${userType}/client/${row?._id}`)}
                >
                  {getFullName(row?.name) || "Anonymous"}
                </h2>
                <ul className="userAction">
                  {row?.phone && (
                    <li>
                      <div
                        id={`user_phone_${row?._id}`}
                        onClick={() =>
                          copyToClipboard(
                            `${row?.countryCode || ""}${row?.phone}`
                          )
                        }
                      >
                        <SvgIcons type={"phone"} />
                        <CustomTooltip
                          target={`user_phone_${row?._id}`}
                          text={`${row?.countryCode || ""} ${row?.phone}`}
                        />
                      </div>
                    </li>
                  )}
                  {row?.email && (
                    <li>
                      <div
                        id={`user_email_${row?._id}`}
                        onClick={() => copyToClipboard(row?.email)}
                      >
                        <SvgIcons type={"email"} />
                        <CustomTooltip
                          target={`user_email_${row?._id}`}
                          text={row?.email}
                        />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      }
      case "createdAt": {
        return <>{formatDate(row?.createdAt) || "-"}</>;
      }
      case "_referBy": {
        return <>{getFullName(row?._referBy?.name) || "-"}</>;
      }
      case "dreLink": {
        return (
          <>
            <CompactText text={row?.dreLink || "-"} isCopy />
          </>
        );
      }
      case "contractSigned":
      case "invoicePaid": {
        return cell ? "Yes" : "No";
      }
      case "webinarAttended": {
        return row?.webinarHistory?.length || 0;
      }
      case "domainPurchased": {
        return <>{row?.domainPurchased || 0}</>;
      }
      case "storePurchased": {
        return <>{row?.storePurchased || 0}</>;
      }
      case "paymentDue": {
        return <>{formatCurrencyValue(row?.paymentDue || 0)}</>;
      }
      case "amountSpend": {
        return <>{formatCurrencyValue(row?.amountSpend || 0)}</>;
      }
      case "notes": {
        return (
          <>
            <Button
              color="link"
              onClick={() => _toggleClientNotesModal(true, row)}
            >
              <SvgIcons type={"docFile"} /> {row?.notesCount || 0}
            </Button>
          </>
        );
      }
      case "leadsProjectStatus": {
        return (
          <>
            <div className="d-flex">
              <Input
                type="select"
                className={`status`}
                disabled={loadingState?.status}
                value={cell}
                onChange={(e) => _onChangeStatus(row, e.target.value)}
              >
                {leadsProjectStatusConfig?.map((each) => (
                  <option key={each.value} value={each.value}>
                    {each.label}
                  </option>
                ))}
              </Input>

              <Button
                color="link"
                onClick={() => _toggleLeadStatusNotes(true, row, true)}
              >
                <i className="fas fa-pencil-alt" />
              </Button>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _initialize = () => {
    try {
      _getAndUpdateUsersList();
      _getAllTemplatesList();

      if (
        localFilters?.filters?.ClientsPage &&
        localFilters?.filters?.ClientsPagePayload
      ) {
        setFilters(localFilters.filters.ClientsPage);
        setDataPayload(localFilters.filters.ClientsPagePayload);
        _getAndUpdateClients(localFilters.filters.ClientsPagePayload);
      } else {
        _onPageChange();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      // navigate("/");
    }

    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Clients{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button
            color="link"
            className="remove"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
          <Button
            color="link"
            className="hideShowFilter"
            onClick={() => setFilterShow(!filterShow)}
          >
            <SvgIcons type={"filter"} />
          </Button>
        </div>
      </div>

      <div className={`filterWrapper ${filterShow === true ? "show" : ""}`}>
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchbar">
            <Label>Search</Label>
            <InputGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {loadingState?.search ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <div className="formGroup">
            <Label>Contact Status</Label>
            <Input
              type="select"
              value={filters.leadsProjectStatus}
              name="leadsProjectStatus"
              onChange={(e) =>
                _onChangeFilters("leadsProjectStatus", e.target.value)
              }
            >
              <option value="">All</option>
              {leadsProjectStatusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Added Date</Label>
            <CustomDateRangePicker
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_ClientsPage"}
              endDateId={"endDateId_ClientsPage"}
              onDatesChange={_onDatesChange}
              dateType={filters.dateType}
            />
          </div>

          <div className="formGroup">
            <Label>Referred By</Label>
            <SearchableInput
              options={filtersList?.usersList}
              value={filters._referBy}
              onChange={(value) => _onChangeFilters("_referBy", value)}
            />
          </div>

          <div className="formGroup">
            <Label>Domain Purchased</Label>
            <Input
              type="select"
              value={filters.noOfDomain}
              name="noOfDomain"
              onChange={(e) => _onChangeFilters("noOfDomain", e.target.value)}
            >
              <option value="">All</option>
              {domainPurchasedConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Store Purchased</Label>
            <Input
              type="select"
              value={filters.noOfStore}
              name="noOfStore"
              onChange={(e) => _onChangeFilters("noOfStore", e.target.value)}
            >
              <option value="">All</option>
              {storePurchasedConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Registered for webinar</Label>
            <Input
              type="select"
              value={filters.webinarCount}
              name="webinarCount"
              onChange={(e) => _onChangeFilters("webinarCount", e.target.value)}
            >
              <option value="">All</option>
              {webinarCountConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Amount Spent</Label>
            <Input
              type="select"
              value={filters.amountSpend}
              name="amountSpend"
              onChange={(e) => _onChangeFilters("amountSpend", e.target.value)}
            >
              <option value="">All</option>
              {amountSpendConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup mt-4">
            <Input
              type="checkbox"
              checked={filters.isInvoicePaid}
              name="isInvoicePaid"
              onChange={(e) =>
                _onChangeFilters("isInvoicePaid", e.target.checked)
              }
              id="isInvoicePaid"
            />
            <Label for="isInvoicePaid" className="ms-2">
              Invoice Paid
            </Label>
          </div>

          <div className="formGroup mt-4">
            <Input
              type="checkbox"
              checked={filters.isContractSign}
              name="isContractSign"
              onChange={(e) =>
                _onChangeFilters("isContractSign", e.target.checked)
              }
              id="isContractSign"
            />
            <Label for="isContractSign" className="ms-2">
              Contract Signed
            </Label>
          </div>
        </div>
      </div>

      {isSelectAll || selectedDataIds?.length ? (
        <>
          <div>
            {isSelectAll ? (
              <>All {tableData?.clientsDataCount} Clients are selected</>
            ) : (
              <>
                {selectedDataIds.length}{" "}
                {selectedDataIds.length > 1 ? "Clients are" : "Client is"}{" "}
                selected
              </>
            )}
          </div>

          {selectedDataIds?.length >= tableData?.clientsData?.length &&
          tableData?.clientsDataCount > dataPayload.limit ? (
            <FormGroup check className="mb-0">
              <Label for="isSelectAll_Clients">
                Select All {tableData?.clientsDataCount} Clients
              </Label>
              <Input
                type="checkbox"
                id="isSelectAll_Clients"
                checked={isSelectAll}
                onChange={(e) =>
                  _onChangeSelectedData("every", e.target.checked)
                }
              />
            </FormGroup>
          ) : null}
        </>
      ) : null}

      <CustomTable
        data={tableData?.clientsData}
        dataCount={tableData?.clientsDataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={true}
        isSelectAll={isSelectAll}
        selectedDataIds={selectedDataIds}
        onChangeSelectedData={(each, checked) =>
          _onChangeSelectedData(each?._id, checked)
        }
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      {selectedDataIds?.length ? (
        <SendEmailTextComponent
          isSelectAll={isSelectAll}
          selectedDataIds={selectedDataIds}
          setSelectedDataIds={setSelectedDataIds}
          setIsSelectAll={setIsSelectAll}
          userType={`Lead`}
        />
      ) : null}

      <ClientNotesModal
        isOpen={clientNotesModal.isOpen}
        data={clientNotesModal.data}
        toggle={() => _toggleClientNotesModal()}
        onSuccess={() => _getAndUpdateClients(dataPayload)}
      />

      <LeadStatusNotes
        isOpen={leadStatusNotes.isOpen}
        data={leadStatusNotes.data}
        isUpdateNote={leadStatusNotes.isUpdateNote}
        toggle={() => _toggleLeadStatusNotes()}
        onSuccess={() => _getAndUpdateClients(dataPayload)}
      />
    </>
  );
};

export default ClientsPage;
