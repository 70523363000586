import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  capitalize,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  getFullName,
  showToast,
} from "../../helper-methods";
import { markCompletedPayroll, sendPayrollEmail } from "../../http/http-calls";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoading from "../SkeletonLoading";
import {
  getAndUpdatePayrollSummary,
  getAndUpdateSettingsData,
} from "../../redux/actions";
import SvgIcons from "../SvgIcons";
import NoDataDiv from "../NoDataDiv";
import ShowHideText from "../ShowHideText";

const PayrollSummary = ({
  settingsLoading = false,
  isPayrollSubmitted = false,
}) => {
  const dispatch = useDispatch();

  const tableData = useSelector((state) => state?.tableData);
  const settingsData = useSelector((state) => state?.settingsData);

  const [loadingState, setLoadingState] = useState({
    data: false,
    sendSummary: false,
    manually: false,
    online: false,
  });
  const [sendSummary, setSendSummary] = useState({
    forSelf: false,
    forOwners: false,
    forAffiliates: false,
    startDate: null,
    endDate: null,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _getAndUpdatePayrollSummary = async () => {
    try {
      _manageLoadingState("data", true);

      await getAndUpdatePayrollSummary()(dispatch);

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("data", false);
    }
  };

  const _onChangeSendSummary = (key = "", value = false) => {
    const newSendSummary = { ...sendSummary };
    newSendSummary[key] = value;
    setSendSummary(newSendSummary);
  };

  const _onSubmit = async (paymentType) => {
    try {
      _manageLoadingState(paymentType, true);

      await markCompletedPayroll({ paymentType });
      await getAndUpdateSettingsData()(dispatch);

      _manageLoadingState(paymentType, false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState(paymentType, false);
    }
  };

  const _sendPayrollEmail = async () => {
    try {
      _manageLoadingState("sendSummary", true);

      const newSendSummary = { ...sendSummary };

      const payload = {
        ...newSendSummary,
      };

      await sendPayrollEmail(payload);

      newSendSummary.forOwners = false;
      newSendSummary.forAffiliates = false;
      newSendSummary.forSelf = false;
      setSendSummary(newSendSummary);

      _manageLoadingState("sendSummary", false);

      showToast("Summary has been sent", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("sendSummary", false);
    }
  };

  useEffect(() => {
    if (isPayrollSubmitted && !settingsLoading) {
      _getAndUpdatePayrollSummary();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPayrollSubmitted, settingsLoading]);

  return (
    <>
      <div className="innerHeader">
        <div className="backToPage">
          <h2>
            Summary{" "}
            {loadingState?.data || settingsLoading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : null}
          </h2>
        </div>

        <div className="right">
          {settingsData?.setting?.payrollDoneTill ? (
            <span>
              {formatDate(
                moment(settingsData?.setting?.payrollDoneTill).add(1, "d")
              )}{" "}
              -{" "}
              {formatDate(
                moment(settingsData?.setting?.payrollDoneTill).add(7, "d")
              )}
            </span>
          ) : loadingState?.data || settingsLoading ? (
            <SkeletonLoading type="box" width={220} height={25} />
          ) : null}

          <Button
            color="link"
            className="btn-reset"
            onClick={() => {
              dispatch(getAndUpdateSettingsData());
              _getAndUpdatePayrollSummary();
            }}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <Row>
        {tableData?.payrollSummaryData?.length ? (
          <>
            {tableData?.payrollSummaryData?.map((each, index) => (
              <Col md={6} key={`payrollSummaryData_${each._id}_${index}`}>
                <Card className="summaryCard my-2">
                  <CardHeader>
                    <CardTitle>{each.payrollFor || "N/A"}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <h3>{getFullName(each._user?.name) || "N/A"}</h3>
                    <ul>
                      {each._owner?.name ? (
                        <li>
                          <span>Owner:</span>
                          <span>{getFullName(each._owner?.name)}</span>
                        </li>
                      ) : null}
                      {each.payrollFor === "Owner" ? (
                        <li>
                          <span>Salary:</span>
                          <span>
                            {formatCurrencyValue(each.salaryAmount || 0)}
                          </span>
                        </li>
                      ) : null}
                      <li>
                        <span>Share:</span>
                        <span>
                          {formatCurrencyValue(each.shareAmount || 0)}
                        </span>
                      </li>

                      {each.transferBankDetails?.map((bank, bankIndex) => (
                        <li
                          key={`manage_owner_bankDetails_${each._id}_${index}_${bank._id}_${bankIndex}`}
                        >
                          <span>{capitalize(bank.name) || "N/A"}:</span>

                          <span>{formatCurrencyValue(bank?.amount || 0)}</span>

                          {/* account number */}
                          <ShowHideText text={bank.accountNumber} />
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            ))}

            <Col md={6}>
              <Card className="summaryCard my-2">
                <CardHeader>
                  <CardTitle>Send Summary</CardTitle>
                </CardHeader>
                <CardBody>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      id="forOwners"
                      checked={sendSummary?.forOwners}
                      onChange={(e) =>
                        _onChangeSendSummary("forOwners", e.target.checked)
                      }
                    />
                    <Label for="forOwners" check>
                      Owners
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      id="forAffiliates"
                      checked={sendSummary?.forAffiliates}
                      onChange={(e) =>
                        _onChangeSendSummary("forAffiliates", e.target.checked)
                      }
                    />
                    <Label for="forAffiliates" check>
                      Affiliate
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input
                      type="checkbox"
                      id="forSelf"
                      checked={sendSummary?.forSelf}
                      onChange={(e) =>
                        _onChangeSendSummary("forSelf", e.target.checked)
                      }
                    />
                    <Label for="forSelf" check>
                      Me (All Pay Info)
                    </Label>
                  </FormGroup>
                  <Button
                    className="h-auto"
                    color="primary"
                    outline
                    disabled={
                      loadingState?.sendSummary ||
                      (!sendSummary?.forOwners &&
                        !sendSummary?.forAffiliates &&
                        !sendSummary?.forSelf)
                    }
                    onClick={() => _sendPayrollEmail()}
                  >
                    {loadingState?.sendSummary ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : null}{" "}
                    Send Email
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </>
        ) : loadingState?.data || settingsLoading ? (
          <>
            {/* Col already in there */}
            <SkeletonLoading type="card" height={200} count={4} md={6} />
          </>
        ) : (
          <Col md="12">
            <NoDataDiv message={"There is no transaction to display."} />
          </Col>
        )}
      </Row>

      {tableData?.payrollSummaryData?.length ? (
        <>
          <div className="text-center">
            <Button
              color="primary"
              outline
              disabled={loadingState?.online || loadingState?.manually}
              onClick={() => _onSubmit("manually")}
            >
              {loadingState?.manually ? (
                <i className="fa fa-spinner fa-spin" />
              ) : null}{" "}
              Pay Manually
            </Button>
            <Button
              color="primary"
              className="ms-3"
              disabled={loadingState?.online || loadingState?.manually}
              onClick={() => _onSubmit("online")}
            >
              {loadingState?.online ? (
                <i className="fa fa-spinner fa-spin" />
              ) : null}{" "}
              Initiate Transfers
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PayrollSummary;
