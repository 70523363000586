import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import { leadsProjectStatusConfig } from "../../config/helper-config";
import { errorHandler, showToast } from "../../helper-methods";
import { updateClient } from "../../http/http-calls";

const LeadStatusNotes = ({
  isOpen,
  data,
  isUpdateNote = false,
  toggle = () => { },
  onSuccess = () => { },
}) => {
  const [formFields, setFormFields] = useState({
    noteText: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const _setModalState = () => {
    const newFormFields = {
      noteText: "",
    };

    const findNotes = data?.statusTimeLine?.find(
      (each) => each.status === data?.leadsProjectStatus
    );
    newFormFields.noteText = findNotes?.notes?.noteText || "";

    setFormFields({ ...newFormFields });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "noteText": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = {
      [key]: true,
    };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _updateClient = async (e) => {
    try {
      if (e) e.preventDefault();

      if (!data?._id) {
        showToast("User not found", "error");
        _closeModal();
        return;
      }

      const newFormFields = { ...formFields };

      const newIsDirty = {};

      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) return;

      setLoading(true);

      await updateClient({
        id: data._id,
        payload: {
          noteText: formFields.noteText?.trim(),
          leadsProjectStatus: data?.leadsProjectStatus,
        },
      });

      showToast(
        `${isUpdateNote ? "Notes" : "Status"} has been updated`,
        "success"
      );

      onSuccess();

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      _setModalState();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data, isUpdateNote]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      scrollable
      centered
      backdrop="static"
    >
      <ModalHeader toggle={() => _closeModal()}>
        {
          leadsProjectStatusConfig.find(
            (each) => each.value === data?.leadsProjectStatus
          )?.label
        }{" "}
        Notes
      </ModalHeader>

      <ModalBody className="pb-0">
        <Form onSubmit={(e) => _updateClient(e)}>
          <div>
            <FormGroup>
              <Label>Note</Label>
              <div className="d-flex">
                <Input
                  type="textarea"
                  name="noteText"
                  value={formFields.noteText}
                  onChange={(e) =>
                    _onChangeFormFields("noteText", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("noteText")}
                />
              </div>
              {errors?.noteText && (
                <div className="form-error">{errors?.noteText}</div>
              )}
            </FormGroup>
          </div>

          <div className="text-end border-top py-3">
            <Button
              color="link"
              className="closeBtn"
              onClick={() => _closeModal()}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Update
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default LeadStatusNotes;
