import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import SvgIcons from "../SvgIcons";
import { createOwner, updateOwner } from "../../http/http-calls";
import {
  capitalize,
  deepClone,
  errorHandler,
  getFullName,
  showToast,
  splitFullName,
  uploadFileOnServer,
} from "../../helper-methods";
import { RegexConfig } from "../../config/RegexConfig";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { useSelector } from "react-redux";

const initialFormFields = {
  name: "",
  email: "",
  phone: "",
  countryCode: "+1",
  weeklyPayout: "",
  referCode: "",

  ownersPartnerDistribution: "3",
  ownerShare: "20",
  ownersSalesCommission: "40",

  bankDetails: [
    {
      name: "",
      accountNumber: "",
      routingNumber: "",
      payoutPercentage: "",
    },
  ],
};

const initialProfilePicData = {
  uploadData: null,
  previewBlob: null,
  type: "image",
  url: null,
};

const AddOwnerModal = ({ isOpen, data, toggle, onSuccess = () => {} }) => {
  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [isDirty, setIsDirty] = useState({});
  const [profilePicData, setProfilePicData] = useState(
    deepClone(initialProfilePicData)
  );
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      name: data?.name ? getFullName(data?.name) : "",
      email: data?.email || "",
      phone: data?.phone || "",
      countryCode: "+1",
      weeklyPayout:
        data?.weeklyPayout || data?.weeklyPayout === 0
          ? String(data?.weeklyPayout)
          : "",
      referCode: data?.referCode || "",

      ownersPartnerDistribution:
        data?.ownersPartnerDistribution || data?.ownersPartnerDistribution === 0
          ? String(data?.ownersPartnerDistribution)
          : "3",
      ownerShare:
        data?.ownerShareAffiliateDomain || data?.ownerShareAffiliateDomain === 0
          ? String(data?.ownerShareAffiliateDomain)
          : "20",
      ownersSalesCommission:
        data?.siteCommission || data?.siteCommission === 0
          ? String(data?.siteCommission)
          : "40",

      bankDetails: data?.bankDetails?.length
        ? data?.bankDetails?.map((each) => ({
            name: each?.name || "",
            accountNumber: each?.accountNumber || "",
            routingNumber: each?.routingNumber || "",
            payoutPercentage:
              each?.payoutPercentage || each?.payoutPercentage === 0
                ? String(each?.payoutPercentage)
                : "",
          }))
        : [
            {
              name: "",
              accountNumber: "",
              routingNumber: "",
              payoutPercentage: "",
            },
          ],
    });
    setIsDirty({});
    setProfilePicData({
      uploadData: null,
      previewBlob: null,
      type: "image",
      url: data?.profilePic || data?.profilePicUrl || null,
    });
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "email":
            case "phone": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*Invalid ${key}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "referCode": {
              if (userType === "admin") {
                if (newFormFields[key]?.length) {
                  if (
                    RegexConfig.username.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Invalid code`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              } else {
                delete newErrors[key];
                newIsDirty[key] = false;
              }
              break;
            }
            case "name": {
              if (newFormFields[key]?.length) {
                if (
                  newFormFields[key]?.length >= 2 &&
                  newFormFields[key]?.length <= 50
                ) {
                  if (
                    RegexConfig.nameWithoutSpecialCharactersAndNumber.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Invalid ${key}`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] =
                    "*Please enter a value between 2 and 50 characters.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "weeklyPayout": {
              if (userType === "admin") {
                if (newFormFields[key]?.length) {
                  if (!isNaN(newFormFields[key]) && newFormFields[key] >= 0) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Invalid payout must be integer value`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              } else {
                delete newErrors[key];
                newIsDirty[key] = false;
              }
              break;
            }
            // case "ownersPartnerDistribution":
            case "ownerShare":
            case "ownersSalesCommission": {
              if (userType === "admin") {
                if (newFormFields[key]?.length) {
                  if (
                    !isNaN(newFormFields[key]) &&
                    newFormFields[key] >= 0 &&
                    newFormFields[key] <= 100
                  ) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[
                      key
                    ] = `*Invalid value % must be between 0 and 100.`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              } else {
                delete newErrors[key];
                newIsDirty[key] = false;
              }
              break;
            }
            default:
          }
        }
      });

      newFormFields?.bankDetails?.forEach((each, index) => {
        Object.keys(each).forEach((key) => {
          if (newIsDirty[`bankDetails_${key}_${index}`]) {
            switch (key) {
              case "name": {
                if (each[key]?.length) {
                  if (each[key]?.length >= 2 && each[key]?.length <= 50) {
                    if (
                      RegexConfig.nameWithoutSpecialCharactersAndNumber.test(
                        String(each[key]).toLowerCase()
                      )
                    ) {
                      delete newErrors[`bankDetails_${key}_${index}`];
                      newIsDirty[`bankDetails_${key}_${index}`] = false;
                    } else {
                      newErrors[
                        `bankDetails_${key}_${index}`
                      ] = `*Invalid ${key}`;
                      isFormValid = false;
                    }
                  } else {
                    newErrors[`bankDetails_${key}_${index}`] =
                      "*Please enter a value between 2 and 50 characters.";
                    isFormValid = false;
                  }
                } else {
                  newErrors[`bankDetails_${key}_${index}`] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "accountNumber": {
                if (each[key]?.length) {
                  if (
                    !isNaN(each[key]) &&
                    each[key]?.length >= 8 &&
                    each[key]?.length <= 14
                  ) {
                    delete newErrors[`bankDetails_${key}_${index}`];
                    newIsDirty[`bankDetails_${key}_${index}`] = false;
                  } else {
                    newErrors[`bankDetails_${key}_${index}`] =
                      "*Please enter an account number between 8 and 14 digits.";
                    isFormValid = false;
                  }
                } else {
                  newErrors[`bankDetails_${key}_${index}`] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "routingNumber": {
                if (each[key]?.length) {
                  if (
                    !isNaN(each[key]) &&
                    each[key]?.length >= 8 &&
                    each[key]?.length <= 14
                  ) {
                    delete newErrors[`bankDetails_${key}_${index}`];
                    newIsDirty[`bankDetails_${key}_${index}`] = false;
                  } else {
                    newErrors[`bankDetails_${key}_${index}`] =
                      "*Please enter an account number between 8 and 14 digits.";
                    isFormValid = false;
                  }
                } else {
                  newErrors[`bankDetails_${key}_${index}`] = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "payoutPercentage": {
                if (newFormFields?.bankDetails?.length > 1) {
                  if (each[key]?.length) {
                    if (
                      !isNaN(each[key]) &&
                      +each[key] >= 0 &&
                      +each[key] <= 100
                    ) {
                      delete newErrors[`bankDetails_${key}_${index}`];
                      newIsDirty[`bankDetails_${key}_${index}`] = false;
                    } else {
                      newErrors[
                        `bankDetails_${key}_${index}`
                      ] = `*Invalid Payout % must be between 0 and 100.`;
                      isFormValid = false;
                    }
                  } else {
                    newErrors[`bankDetails_${key}_${index}`] = "*Required";
                    isFormValid = false;
                  }
                } else {
                  delete newErrors[`bankDetails_${key}_${index}`];
                  newIsDirty[`bankDetails_${key}_${index}`] = false;
                }
                break;
              }
              default:
            }
          }
        });
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _addBankAccountsRow = () => {
    const newFormFields = { ...formFields };
    newFormFields.bankDetails.push({
      name: "",
      accountNumber: "",
      routingNumber: "",
      payoutPercentage: "",
    });
    setFormFields(newFormFields);

    const newIsDirty = { ...isDirty };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _removeBankAccountsRow = (index) => {
    const newFormFields = { ...formFields };

    if (+index < 0 && newFormFields?.bankDetails?.length <= 1) {
      return;
    }

    newFormFields.bankDetails.splice(index, 1);
    setFormFields(newFormFields);

    const newIsDirty = { ...isDirty };
    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onChangeBankDetails = (key, value, index) => {
    const newFormFields = { ...formFields };

    if (
      (key === "accountNumber" || key === "routingNumber") &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 14)
    ) {
      return;
    }

    if (
      key === "payoutPercentage" &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 3)
    ) {
      return;
    }

    if (+index < 0) {
      return;
    }

    newFormFields.bankDetails[index][key] = value;
    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[`bankDetails_${key}_${index}`]) {
      newErrors[`bankDetails_${key}_${index}`] = false;
      setErrors(newErrors);
    }
  };

  const _onChangeFormFields = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 10)
    ) {
      return;
    }

    if (
      (key === "ownersPartnerDistribution" ||
        key === "ownerShare" ||
        key === "ownersSalesCommission") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1]?.length > 2) ||
        +value < 0 ||
        (value.includes(".") && String(value)?.length > 5) ||
        (!value.includes(".") && String(value)?.length > 3))
    ) {
      return;
    }

    if (
      key === "weeklyPayout" &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1]?.length > 2) ||
        +value < 0 ||
        String(value)?.length > 8)
    ) {
      return;
    }

    const newFormFields = { ...formFields };

    newFormFields[key] = value;

    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  // const _resetProfilePicData = () => {
  //   setProfilePicData(deepClone(initialProfilePicData));
  // };

  const _onChangeFile = async (event) => {
    try {
      if (!event?.target?.files?.length) {
        return;
      }

      const file = event?.target?.files?.[0];
      const fileType = file.type.split("/")[0];

      if (fileType !== "image") {
        showToast("Only image file is allowed", "error");
        return;
      }

      const previewBlob = URL.createObjectURL(file);

      setProfilePicData({
        uploadData: file,
        previewBlob,
        type: fileType,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newProfilePicData = { ...profilePicData };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });
      newFormFields?.bankDetails?.forEach((each, index) => {
        Object.keys(each).forEach((key) => {
          newIsDirty[`bankDetails_${key}_${index}`] = true;
        });
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      const totalPayoutPercentage = newFormFields?.bankDetails?.reduce(
        (acc, each) => +each.payoutPercentage + acc,
        0
      );

      if (
        newFormFields?.bankDetails?.length > 1 &&
        totalPayoutPercentage !== 100
      ) {
        showToast("The total payout percentage must equal to 100%.");
        return;
      }

      const totalSharePercentage =
        +newFormFields?.ownersPartnerDistribution +
        +newFormFields?.ownerShare +
        +newFormFields?.ownersSalesCommission;

      if (totalSharePercentage > 100) {
        showToast("The total commission % must be less than 100%.");
        return;
      }

      setLoading(true);

      const { firstName, lastName } = splitFullName(
        capitalize(newFormFields?.name?.trim())
      );

      const payload = {
        name: {
          first: firstName,
          last: lastName,
        },
        email: newFormFields?.email?.trim(),
        phone: newFormFields?.phone?.trim(),
        countryCode: newFormFields?.countryCode?.trim(),
        weeklyPayout: newFormFields?.weeklyPayout?.trim(),
        profilePic: newProfilePicData?.url || "",
        referCode: newFormFields?.referCode?.trim(),

        bankDetails: [],
      };

      if (userType === "admin") {
        payload.ownersPartnerDistribution =
          +newFormFields.ownersPartnerDistribution;
        payload.ownerShare = +newFormFields.ownerShare;
        payload.ownerSalesCommission = +newFormFields.ownersSalesCommission;
      }

      if (newFormFields?.bankDetails?.length) {
        payload.bankDetails = newFormFields?.bankDetails?.map((each) => ({
          name: each.name?.trim(),
          accountNumber: each.accountNumber,
          routingNumber: each.routingNumber,
          payoutPercentage:
            newFormFields?.bankDetails?.length > 1
              ? each.payoutPercentage
              : 100,
        }));
      }

      if (newProfilePicData?.uploadData) {
        const uploadFileOnServerRes = await uploadFileOnServer([
          { ...newProfilePicData },
        ]);
        payload.profilePic = uploadFileOnServerRes?.[0]?.url;
        newProfilePicData.url = uploadFileOnServerRes?.[0]?.url;
        newProfilePicData.uploadData = null;
        setProfilePicData(newProfilePicData);
      }

      if (data?._id) {
        await updateOwner({ id: data?._id, payload });
      } else {
        await createOwner(payload);
      }

      onSuccess(data?._id ? true : false);

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => _closeModal()}>Owner</ModalHeader>

        <ModalBody>
          <Row>
            <Col xl={12}>
              <Label className="userProfile">
                <Input
                  type="file"
                  accept="image/*"
                  value=""
                  onChange={(e) => _onChangeFile(e)}
                  disabled={loading}
                />
                <div className="profilePic">
                  <img
                    src={
                      profilePicData?.url ||
                      profilePicData?.previewBlob ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    height={100}
                    alt="profile"
                  />
                </div>
                <div className="editIcon">
                  <SvgIcons type={"edit"} />
                </div>
              </Label>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  placeholder="Enter"
                  value={formFields.name}
                  onChange={(e) => _onChangeFormFields("name", e.target.value)}
                  onBlur={() => _onBlurFormFields("name")}
                />
                {errors["name"] ? (
                  <div className="form-error">{errors["name"]}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Phone Number</Label>
                <InputGroup>
                  <InputGroupText>+1</InputGroupText>
                  <Input
                    placeholder="Enter"
                    value={formFields.phone}
                    onChange={(e) =>
                      _onChangeFormFields("phone", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("phone")}
                  />
                </InputGroup>
                {errors["phone"] ? (
                  <div className="form-error">{errors["phone"]}</div>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  placeholder="Enter"
                  value={formFields.email}
                  onChange={(e) => _onChangeFormFields("email", e.target.value)}
                  onBlur={() => _onBlurFormFields("email")}
                />
                {errors["email"] ? (
                  <div className="form-error">{errors["email"]}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Weekly Payout</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    placeholder="Enter"
                    value={formFields.weeklyPayout}
                    onChange={(e) =>
                      _onChangeFormFields("weeklyPayout", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("weeklyPayout")}
                    disabled={loading || userType !== "admin"}
                  />
                </InputGroup>
                {errors["weeklyPayout"] ? (
                  <div className="form-error">{errors["weeklyPayout"]}</div>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={6}>
              <Label>Referral Code</Label>
              <FormGroup>
                <Input
                  placeholder="Enter"
                  value={formFields.referCode}
                  onChange={(e) =>
                    _onChangeFormFields("referCode", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("referCode")}
                  disabled={loading || userType !== "admin"}
                />
                {errors["referCode"] ? (
                  <div className="form-error">{errors["referCode"]}</div>
                ) : null}
              </FormGroup>
            </Col>

            {userType === "admin" ? (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label>Partner Distribution</Label>
                    <InputGroup>
                      <Input
                        placeholder="Enter"
                        value={formFields.ownersPartnerDistribution}
                        readOnly
                        disabled
                        // onChange={(e) =>
                        //   _onChangeFormFields(
                        //     "ownersPartnerDistribution",
                        //     e.target.value
                        //   )
                        // }
                        // onBlur={() =>
                        //   _onBlurFormFields("ownersPartnerDistribution")
                        // }
                      />
                      <InputGroupText>%</InputGroupText>
                    </InputGroup>
                    {errors["ownersPartnerDistribution"] ? (
                      <div className="form-error">
                        {errors["ownersPartnerDistribution"]}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Commission on Affiliate's Sales</Label>
                    <InputGroup>
                      <Input
                        placeholder="Enter"
                        value={formFields.ownerShare}
                        onChange={(e) =>
                          _onChangeFormFields("ownerShare", e.target.value)
                        }
                        onBlur={() => _onBlurFormFields("ownerShare")}
                      />
                      <InputGroupText>%</InputGroupText>
                    </InputGroup>
                    {errors["ownerShare"] ? (
                      <div className="form-error">{errors["ownerShare"]}</div>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Owner's Sales Commission</Label>
                    <InputGroup>
                      <Input
                        placeholder="Enter"
                        value={formFields.ownersSalesCommission}
                        onChange={(e) =>
                          _onChangeFormFields(
                            "ownersSalesCommission",
                            e.target.value
                          )
                        }
                        onBlur={() =>
                          _onBlurFormFields("ownersSalesCommission")
                        }
                      />
                      <InputGroupText>%</InputGroupText>
                    </InputGroup>
                    {errors["ownersSalesCommission"] ? (
                      <div className="form-error">
                        {errors["ownersSalesCommission"]}
                      </div>
                    ) : null}
                  </FormGroup>
                </Col>
              </>
            ) : null}
          </Row>

          <div className="d-flex justify-content-between">
            <h6>Bank Accounts</h6>

            {formFields?.bankDetails?.length < 5 ? (
              <Button
                color="success"
                outline
                className="btnAdd"
                disabled={loading}
                onClick={() => _addBankAccountsRow()}
              >
                <SvgIcons type={"plus"} />
              </Button>
            ) : null}
          </div>

          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Account #</th>
                <th>Routing #</th>
                <th>Payout %</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {formFields?.bankDetails?.length ? (
                formFields?.bankDetails?.map((each, index) => (
                  <tr key={`bankDetails_${index}`}>
                    <td>
                      {/* Name */}
                      <Input
                        placeholder="Enter"
                        value={each.name}
                        onChange={(e) =>
                          _onChangeBankDetails("name", e.target.value, index)
                        }
                        onBlur={() =>
                          _onBlurFormFields(`bankDetails_name_${index}`)
                        }
                      />
                      {errors[`bankDetails_name_${index}`] ? (
                        <div className="form-error">
                          {errors[`bankDetails_name_${index}`]}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {/* Account No */}
                      <Input
                        placeholder="Enter"
                        value={each.accountNumber}
                        onChange={(e) =>
                          _onChangeBankDetails(
                            "accountNumber",
                            e.target.value,
                            index
                          )
                        }
                        onBlur={() =>
                          _onBlurFormFields(
                            `bankDetails_accountNumber_${index}`
                          )
                        }
                      />
                      {errors[`bankDetails_accountNumber_${index}`] ? (
                        <div className="form-error">
                          {errors[`bankDetails_accountNumber_${index}`]}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {/* Routing */}
                      <Input
                        placeholder="Enter"
                        value={each.routingNumber}
                        onChange={(e) =>
                          _onChangeBankDetails(
                            "routingNumber",
                            e.target.value,
                            index
                          )
                        }
                        onBlur={() =>
                          _onBlurFormFields(
                            `bankDetails_routingNumber_${index}`
                          )
                        }
                      />
                      {errors[`bankDetails_routingNumber_${index}`] ? (
                        <div className="form-error">
                          {errors[`bankDetails_routingNumber_${index}`]}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {/* % */}
                      <Input
                        placeholder="Enter"
                        value={
                          formFields?.bankDetails?.length === 1
                            ? 100
                            : each.payoutPercentage
                        }
                        disabled={formFields?.bankDetails?.length === 1}
                        onChange={(e) =>
                          _onChangeBankDetails(
                            "payoutPercentage",
                            e.target.value,
                            index
                          )
                        }
                        onBlur={() =>
                          _onBlurFormFields(
                            `bankDetails_payoutPercentage_${index}`
                          )
                        }
                      />
                      {errors[`bankDetails_payoutPercentage_${index}`] ? (
                        <div className="form-error">
                          {errors[`bankDetails_payoutPercentage_${index}`]}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formFields?.bankDetails?.length > 1 ? (
                        <Button
                          color="danger"
                          outline
                          className="btnRemove"
                          disabled={loading}
                          onClick={() => _removeBankAccountsRow(index)}
                        >
                          <SvgIcons type={"remove"} />
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan={5}>
                    Please click on the plus icon to add your bank details.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={() => _onSave()}>
            {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
            {data?._id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddOwnerModal;
