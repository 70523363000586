import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  getFullName,
  showToast,
} from "../../helper-methods";
import {
  createAffiliateGroup,
  updateAffiliateGroup,
} from "../../http/http-calls";
import SearchableInput from "../SearchableInput";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateUsersList } from "../../redux/actions";
import SvgIcons from "../SvgIcons";

const initialFormFields = {
  groupName: "",
  assignedTo: [],
};

const AddAffiliateGroupModal = ({
  isOpen,
  data,
  toggle,
  onSuccess = () => {},
}) => {
  const dispatch = useDispatch();

  const filtersList = useSelector((state) => state?.filtersList);

  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      groupName: data?.name || "",
      assignedTo: data?._members?.length
        ? data?._members?.map((each) => ({
            label: getFullName(each?.name),
            value: each?._id,
          }))
        : [],
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "groupName": {
              if (newFormFields[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "assignedTo": {
              if (newFormFields[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };
    if (key === "assignedTo") {
      newFormFields[key].push(...value);
    } else {
      newFormFields[key] = value;
    }
    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _deleteAssignedTo = (index) => {
    const newFormFields = { ...formFields };
    newFormFields.assignedTo.splice(index, 1);
    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };

      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const payload = {
        groupName: newFormFields?.groupName?.trim(),
        assignedTo: newFormFields?.assignedTo?.map((each) => each.value),
      };

      if (data?._id) {
        await updateAffiliateGroup({ id: data?._id, payload });
        showToast("Group has been updated", "success");
      } else {
        await createAffiliateGroup(payload);
        showToast("Group has been created", "success");
      }

      onSuccess();

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _getAndUpdateUsersList = () => {
    try {
      dispatch(getAndUpdateUsersList());
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      _getAndUpdateUsersList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && data?._id) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <Modal isOpen={isOpen} scrollable centered size={"lg"}>
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>Group Name</Label>
              <Input
                placeholder="Enter"
                value={formFields.groupName}
                onChange={(e) =>
                  _onChangeFormFields("groupName", e.target.value)
                }
                onBlur={() => _onBlurFormFields("groupName")}
              />
              {errors["groupName"] ? (
                <div className="form-error">{errors["groupName"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <Label>Add Members</Label>
            <SearchableInput
              options={filtersList?.usersList?.filter(
                (each) =>
                  !formFields?.assignedTo?.find(
                    (subEach) => subEach?.value === each?.value
                  )
              )}
              value={[]}
              onChange={(value) => _onChangeFormFields("assignedTo", value)}
              onBlur={() => _onBlurFormFields("assignedTo")}
              isMulti={true}
              disabled={formFields.assignedType === "All"}
            />
            {errors?.assignedTo ? (
              <div className="form-error">{errors?.assignedTo}</div>
            ) : null}
          </Col>

          {formFields?.assignedTo?.length ? (
            <Col md={12} className="mt-3">
              <Label>Members</Label>
              <ListGroup>
                {formFields?.assignedTo?.map((each, index) => (
                  <ListGroupItem
                    className="d-flex justify-content-between"
                    key={`assignedTo_${each?.value}_${index}`}
                  >
                    {each.label}
                    <Button
                      color="link"
                      className="text-danger"
                      onClick={() => _deleteAssignedTo(index)}
                    >
                      <SvgIcons type="delete" />
                    </Button>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          ) : null}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button color="primary" disabled={loading} onClick={() => _onSave()}>
          {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAffiliateGroupModal;
