import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import CustomTable from "../components/Table/CustomTable";
import SendEmailTextComponent from "../components/SendEmailTextComponent";
import { updateClient } from "../http/http-calls";
import {
  copyToClipboard,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  getDropdownColor,
  getFullName,
  showToast,
} from "../helper-methods";
import SvgIcons from "../components/SvgIcons";
import { leadsProjectStatusConfig } from "../config/helper-config";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../components/custom/CustomTooltip";
import LeadStatusNotes from "../components/modals/LeadStatusNotes";
import ClientNotesModal from "../components/modals/ClientNotesModal";
import { getAllReferredClients } from "../http/http-calls";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateTemplateList } from "../redux/actions";
import CompactText from "./custom/CompactText";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Name", id: "name" },
  { label: "Added Date", id: "createdAt" },
  // { label: "Referred By", id: "_referBy" },
  { label: "Contact Status", id: "leadsProjectStatus" },
  { label: "Contract Signed", id: "contractSigned" },
  { label: "Invoice Paid", id: "invoicePaid" },
  { label: "Domain Purchased", id: "domainPurchased" },
  { label: "Store Purchased", id: "storePurchased" },
  { label: "Pipeline Payment", id: "paymentDue" },
  { label: "Amount Spent", id: "amountSpend" },
  { label: "Potential Commission", id: "potentialCommision" },
  { label: "Actual Commission", id: "estCommision" },
  // { label: "Owner Commission", id: "ownerCommission" },
  { label: "Notes", id: "notes" },
  { label: "Registered for webinar", id: "webinarAttended" },
  { label: "DRE Link", id: "dreLink" },
  // { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const ReferredClientsTable = ({ affiliateId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );

  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [totalPotentialCommission, setTotalPotentialCommission] = useState(0);
  const [totalActualCommission, setTotalActualCommission] = useState(0);
  const [totalPendingAmount, setTotalPendingAmount] = useState(0);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);

  const [clientNotesModal, setClientNotesModal] = useState({
    isOpen: false,
    data: null,
  });
  const [leadStatusNotes, setLeadStatusNotes] = useState({
    isOpen: false,
    data: null,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleClientNotesModal = (isOpen = false, data = null) => {
    setClientNotesModal({ isOpen, data });
  };

  const _toggleLeadStatusNotes = (
    isOpen = false,
    data = null,
    isUpdateNote = false
  ) => {
    setLeadStatusNotes({ isOpen, data, isUpdateNote });
  };

  const _getAllReferredClients = async (payload) => {
    try {
      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      const res = await getAllReferredClients({ id: affiliateId, payload });

      setData(res.referredClients);
      setDataCount(res.referredClientsCount);

      setTotalPotentialCommission(res?.totalPotentialCommission || 0);
      setTotalActualCommission(res?.totalActualCommission || 0);
      setTotalPendingAmount(res?.totalPendingAmount || 0);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllReferredClients(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllReferredClients(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAllReferredClients(newDataPayload);
  };

  const _onChangeSelectedData = (value = "all", checked = false) => {
    if (value === "every") {
      setIsSelectAll(checked);
    } else {
      setIsSelectAll(false);
      let newSelectedDataIds = [...selectedDataIds];
      if (value === "all") {
        if (checked) {
          const currentPageIds = data
            ?.filter((each) => !newSelectedDataIds?.includes(each._id))
            .map((each) => each._id);
          newSelectedDataIds = [...newSelectedDataIds, ...currentPageIds];
        } else {
          const currentPageIds = data?.map((each) => each._id);
          newSelectedDataIds = newSelectedDataIds.filter(
            (sid) => !currentPageIds.includes(sid)
          );
        }
      } else {
        if (checked) {
          newSelectedDataIds.push(value);
        } else {
          const findIndex = newSelectedDataIds.findIndex(
            (each) => each === value
          );
          if (findIndex >= 0) newSelectedDataIds.splice(findIndex, 1);
        }
      }
      setSelectedDataIds(newSelectedDataIds);
    }
  };

  const _onChangeStatus = async (each, status) => {
    try {
      if (!each?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }
      const newData = [...data];

      const findData = newData?.find((e) => e._id === each?._id);

      if (!findData) {
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        leadsProjectStatus: status,
      };

      findData.leadsProjectStatus = payload.leadsProjectStatus;
      setData(newData);

      await updateClient({ id: each?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getAllReferredClients(dataPayload);
    }
  };

  const _footerDataFormat = (header) => {
    switch (header) {
      case "paymentDue": {
        return <>{formatCurrencyValue(totalPendingAmount || 0)}</>;
      }
      case "potentialCommision": {
        return <>{formatCurrencyValue(totalPotentialCommission || 0)}</>;
      }
      case "estCommision": {
        return <>{formatCurrencyValue(totalActualCommission || 0)}</>;
      }
      default:
        return "";
    }
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userContact">
                <h2
                  className={`${
                    row?.isActive === false ? "text-danger" : "text-primary"
                  }`}
                  onClick={() => navigate(`/${userType}/client/${row?._id}`)}
                >
                  {getFullName(row?.name) || "Anonymous"}
                </h2>
                <ul className="userAction">
                  {row?.phone && (
                    <li>
                      <div
                        id={`user_phone_${row?._id}`}
                        onClick={() =>
                          copyToClipboard(
                            `${row?.countryCode || ""}${row?.phone}`
                          )
                        }
                      >
                        <SvgIcons type={"phone"} />
                      </div>

                      <CustomTooltip
                        target={`user_phone_${row?._id}`}
                        text={`${row?.countryCode || ""} ${row?.phone}`}
                      />
                    </li>
                  )}

                  {row?.email && (
                    <li>
                      <div
                        id={`user_email_${row?._id}`}
                        onClick={() => copyToClipboard(row?.email)}
                      >
                        <SvgIcons type={"email"} />
                      </div>

                      <CustomTooltip
                        target={`user_email_${row?._id}`}
                        text={row?.email}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      }
      case "createdAt": {
        return <>{formatDate(row?.createdAt) || "-"}</>;
      }
      case "_referBy": {
        return <>{getFullName(row?._referBy?.name) || "-"}</>;
      }
      case "dreLink": {
        return (
          <>
            <CompactText text={row?.dreLink || "-"} isCopy />
          </>
        );
      }
      case "contractSigned":
      case "invoicePaid": {
        return cell ? "Yes" : "No";
      }
      case "webinarAttended": {
        return row?.webinarHistory?.length || 0;
      }
      case "domainPurchased": {
        return <>{row?.domainPurchased || 0}</>;
      }
      case "storePurchased": {
        return <>{row?.storePurchased || 0}</>;
      }
      case "amountSpend": {
        return <>{formatCurrencyValue(row?.amountSpend || 0)}</>;
      }
      case "paymentDue": {
        return <>{formatCurrencyValue(row?.paymentDue || 0)}</>;
      }
      case "potentialCommision": {
        return <>{formatCurrencyValue(row?.potentialCommision || 0)}</>;
      }
      case "estCommision": {
        return <>{formatCurrencyValue(row?.estCommision || 0)}</>;
      }
      case "ownerCommission": {
        return <>{formatCurrencyValue(row?.ownerTotalCommision || 1212120)}</>;
      }
      case "notes": {
        return (
          <>
            <Button
              color="link"
              onClick={() => _toggleClientNotesModal(true, row)}
            >
              <SvgIcons type={"docFile"} /> {row?.notesCount || 0}
            </Button>
          </>
        );
      }
      case "leadsProjectStatus": {
        return (
          <>
            <div className="d-flex">
              <Input
                type="select"
                className={`status ${getDropdownColor(
                  row?.isActive ? "Active" : "Inactive"
                )}`}
                disabled={loadingState?.status ? true : false}
                value={cell}
                onChange={(e) => _onChangeStatus(row, e.target.value)}
              >
                {leadsProjectStatusConfig?.map((each) => (
                  <option key={each.value} value={each.value}>
                    {each.label}
                  </option>
                ))}
              </Input>

              <Button
                color="link"
                onClick={() => _toggleLeadStatusNotes(true, row, true)}
              >
                <i className="fas fa-pencil-alt" />
              </Button>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _getAllTemplatesList = async () => {
    try {
      await getAndUpdateTemplateList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAllTemplatesList();

    _onPageChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="cardDesign">
        <CardHeader>
          <CardTitle>Referred Clients</CardTitle>

          {/* <div className="text-end">
            <div className="fs-12">
              Total Pipeline Payment:{" "}
              <b>{formatCurrencyValue(totalPendingAmount || 0)}</b>
            </div>
            <div className="fs-12">
              Total Potential Commission:{" "}
              <b>{formatCurrencyValue(totalPotentialCommission || 0)}</b>
            </div>
            <div className="fs-12">
              Total Actual Commission:{" "}
              <b>{formatCurrencyValue(totalActualCommission || 0)}</b>
            </div>
          </div> */}
        </CardHeader>

        <CardBody>
          {isSelectAll || selectedDataIds?.length ? (
            <>
              <div>
                {isSelectAll ? (
                  <>All {dataCount} Clients are selected</>
                ) : (
                  <>
                    {selectedDataIds.length}{" "}
                    {selectedDataIds.length > 1 ? "Clients are" : "Client is"}{" "}
                    selected
                  </>
                )}
              </div>

              {selectedDataIds?.length >= data?.length &&
              dataCount > dataPayload.limit ? (
                <FormGroup check className="mb-0">
                  <Label for="isSelectAll_Clients">
                    Select All {dataCount} Clients
                  </Label>
                  <Input
                    type="checkbox"
                    id="isSelectAll_Clients"
                    checked={isSelectAll}
                    onChange={(e) =>
                      _onChangeSelectedData("every", e.target.checked)
                    }
                  />
                </FormGroup>
              ) : null}
            </>
          ) : null}

          <CustomTable
            data={data}
            dataCount={dataCount}
            dataPayload={dataPayload}
            onPageChange={_onPageChange}
            onSizeChange={_onSizeChange}
            loading={loadingState?.data}
            isRowSelection={true}
            isSelectAll={isSelectAll}
            selectedDataIds={selectedDataIds}
            onChangeSelectedData={(each, checked) =>
              _onChangeSelectedData(each?._id, checked)
            }
            headerKeys={headerKeys}
            isFooter={true}
            footerDataFormat={_footerDataFormat}
            dataFormat={_dataFormat}
            toggleSortBy={_toggleSortBy}
          />
        </CardBody>
      </Card>

      {selectedDataIds?.length ? (
        <SendEmailTextComponent
          isSelectAll={isSelectAll}
          selectedDataIds={selectedDataIds}
          setSelectedDataIds={setSelectedDataIds}
          setIsSelectAll={setIsSelectAll}
          userType={`Lead`}
        />
      ) : null}

      <ClientNotesModal
        isOpen={clientNotesModal.isOpen}
        data={clientNotesModal.data}
        toggle={() => _toggleClientNotesModal()}
        onSuccess={() => _getAllReferredClients(dataPayload)}
      />

      <LeadStatusNotes
        isOpen={leadStatusNotes.isOpen}
        data={leadStatusNotes.data}
        isUpdateNote={leadStatusNotes.isUpdateNote}
        toggle={() => _toggleLeadStatusNotes()}
        onSuccess={() => _getAllReferredClients(dataPayload)}
      />
    </>
  );
};

export default ReferredClientsTable;
