import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  showToast,
  uploadFileOnServer,
} from "../../helper-methods";
import { addTopic, editTopic } from "../../http/http-calls";
import FileDisplaySection from "../TopicComponent/FileDisplaySection";
import FileUploadSection from "../TopicComponent/FileUploadSection";

const AddTopics = ({
  isOpen,
  toggle,
  fetchAllTopics,
  editDetails,
  viewOnly = false,
}) => {
  const [formData, setFormData] = useState({
    topicName: "",
    description: "",
    videoFiles: [],
    imageFiles: [],
    pdfFiles: [],
  });

  const [isDirty, setIsDirty] = useState({
    topicName: false,
    description: false,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [isFieldDirty, setIsFieldDirty] = useState(false);

  const _closeModal = () => {
    toggle();
    setFormData({
      topicName: "",
      description: "",
      videoFiles: [],
      imageFiles: [],
      pdfFiles: [],
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _validateForm = (data, dirties) => {
    const newErrors = { ...errors };

    Object.keys(dirties).forEach((each) => {
      if (each === "topicName" && dirties[each]) {
        if (!data?.topicName?.length) {
          newErrors.topicName = "*Required";
        } else if (
          data.topicName.trim().length < 2 ||
          data.topicName.length > 150
        ) {
          newErrors.topicName =
            "*Topic name should be greater than 2 characters and less than 150 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      } else if (each === "description" && dirties[each]) {
        if (!data?.description?.length) {
          newErrors.description = "*Required";
        } else if (
          data.description.trim().length < 2 ||
          data.description.length > 500
        ) {
          newErrors.description =
            "*Description should be greater than 2 characters and less than 500 characters";
        } else {
          delete newErrors[each];
          dirties[each] = false;
        }
      }
    });
    setIsDirty(dirties);
    setErrors(newErrors);
    return Object.keys(newErrors).length ? true : false;
  };

  const _handelOnChange = (field, event) => {
    if (!isFieldDirty) {
      setIsFieldDirty(true);
    }

    const newFormData = { ...formData };
    const newIsDirty = { ...isDirty };
    if (
      field === "videoFiles" ||
      field === "imageFiles" ||
      field === "pdfFiles"
    ) {
      if (!event?.target?.files?.length) return;

      const uploadData = event?.target?.files[0];
      let fileType = uploadData.type.split("/")[0];

      if (field === "imageFiles" && fileType !== "image") {
        showToast("Only Image file is allowed", "error");
        return;
      }
      if (field === "videoFiles" && fileType !== "video") {
        showToast("Only Video file is allowed", "error");
        return;
      }
      if (field === "pdfFiles") {
        fileType = uploadData.type.split("/")[1];

        if (fileType !== "pdf") {
          showToast("Only PDF file is allowed", "error");
          return;
        }
      }

      newFormData[field] = [
        {
          uploadData,
          previewBlob: URL.createObjectURL(uploadData),
          type: fileType,
          name: uploadData.name,
          forKeyName: field,
        },
      ];
    } else {
      newFormData[field] = event.target.value;
    }

    newIsDirty[field] = true;
    setFormData(newFormData);
    setIsDirty(newIsDirty);
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formData };
    const newIsDirty = {
      [key]: true,
    };
    _validateForm(newFormFields, newIsDirty);
  };

  const _topicSubmitHandler = async () => {
    try {
      const newIsDirty = {
        topicName: true,
        description: true,
      };

      const error = _validateForm(formData, newIsDirty);

      if (error) {
        return;
      }

      if (!formData?.videoFiles?.length) {
        showToast("Video content is mandatory", "error");
        return;
      }

      setLoading(true);

      const payload = { ...formData };

      try {
        const uploadedData = [
          ...formData?.imageFiles,
          ...formData?.videoFiles,
          ...formData?.pdfFiles,
        ]?.filter((each) => each.uploadData);

        if (uploadedData?.length) {
          const res = await uploadFileOnServer(uploadedData);

          res.forEach((each) => {
            payload[each.forKeyName] = [
              {
                url: each.url,
                docType: each.contentType,
                title: each.name,
                duration: each.duration,
              },
            ];
          });
        }
      } catch (error) {
        console.log({ error });
      }

      if (editDetails) {
        await editTopic(editDetails?._id, payload);

        showToast("Topic Updated SuccessFully", "success");
      } else {
        await addTopic(payload);

        showToast("Topic Created SuccessFully", "success");
      }

      fetchAllTopics();
      _closeModal();
      setLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onLoadedMetadata = (duration, index) => {
    const newFormData = deepClone(formData);
    newFormData.videoFiles[index].duration = duration;
    setFormData(newFormData);
  };

  const _deleteAfile = (field, index) => {
    if (!isFieldDirty) {
      setIsFieldDirty(true);
    }

    const newFormData = deepClone(formData);
    newFormData[field].splice(index, 1);
    setFormData(newFormData);
  };

  useEffect(() => {
    if (editDetails) {
      setFormData({
        topicName: editDetails?.topicName || "",
        description: editDetails?.content || "",
        videoFiles: editDetails?.videoFiles || [],
        imageFiles: editDetails?.imageFiles || [],
        pdfFiles: editDetails?.pdfFiles || [],
      });
    }
  }, [editDetails]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        scrollable
        centered
        className="modal-lg message-template"
        backdrop="static"
      >
        <ModalHeader toggle={() => _closeModal()}>
          {viewOnly ? "" : editDetails ? "Edit" : "Add"} Topics
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label>Topic</Label>
            <Input
              placeholder="Enter"
              type="text"
              disabled={loading}
              value={formData?.topicName}
              onChange={(event) => _handelOnChange("topicName", event)}
              onBlur={() => _onBlurFormFields("topicName")}
              readOnly={viewOnly}
            />
            {errors.topicName ? (
              <div className="form-error">{errors.topicName}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label>Content</Label>
            <Input
              placeholder="Enter"
              type="textarea"
              rows="4"
              disabled={loading}
              value={formData?.description}
              onChange={(event) => _handelOnChange("description", event)}
              onBlur={() => _onBlurFormFields("description")}
              readOnly={viewOnly}
            />
            {errors.description ? (
              <div className="form-error">{errors.description}</div>
            ) : null}
          </FormGroup>

          <Label>{viewOnly ? "Uploaded" : "Upload"} Content</Label>

          <div className="topicUploadWrapper">
            {viewOnly ? null : (
              <FileUploadSection
                disabled={loading}
                _handelOnChange={_handelOnChange}
              />
            )}

            <FileDisplaySection
              formData={formData}
              deleteAfile={viewOnly ? null : _deleteAfile}
              onLoadedMetadata={_onLoadedMetadata}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color={viewOnly ? "danger" : "link"} onClick={toggle}>
            {viewOnly ? "Close" : "Cancel"}
          </Button>
          {viewOnly ? null : (
            <Button
              color="primary"
              disabled={loading || !isFieldDirty}
              onClick={() => _topicSubmitHandler()}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              {editDetails ? "Update" : "Add"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddTopics;
