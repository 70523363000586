import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../helper-methods";
import { createGoal } from "../../http/http-calls";
import CustomDateRangePicker from "../CustomDateRangePicker";
import SearchableInput from "../SearchableInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndUpdateGroupList,
  getAndUpdateUsersList,
} from "../../redux/actions";
import SvgIcons from "../SvgIcons";

const initialFormFields = {
  objective: "",
  goalType: "Store",
  assignedType: "All",
  objectiveAmount: "",
  startDate: "",
  endDate: "",
  assignedTo: [],
};

const AddGoalModal = ({ isOpen, data, toggle, onSuccess = () => { } }) => {
  const dispatch = useDispatch();

  const filtersList = useSelector((state) => state?.filtersList);

  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields(deepClone(initialFormFields));
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "objective": {
              if (newFormFields[key]?.length) {
                if (
                  newFormFields[key]?.length >= 2 &&
                  newFormFields[key]?.length <= 250
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Please enter a value between 2 and 250 characters.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "goalType":
            case "assignedType": {
              if (newFormFields[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "startDate": {
              if (newFormFields[key]) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "endDate": {
              if (newFormFields[key]) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "assignedTo": {
              if (newFormFields.assignedType === "All") {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                if (newFormFields[key]?.length) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "objectiveAmount": {
              if (newFormFields[key]?.length) {
                if (isNaN(newFormFields[key]) || +newFormFields[key] <= 0) {
                  newErrors[key] = "*Invalid, It must be greater than 0";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      key === "objectiveAmount" &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 15)
    ) {
      return;
    }

    const newFormFields = { ...formFields };

    if (key === "assignedTo") {
      newFormFields[key].push(...value);
    } else {
      newFormFields[key] = value;
    }

    if (key === "assignedType") {
      newFormFields.assignedTo = [];
      const newErrors = { ...errors };
      newErrors.assignedTo = false;
      setErrors(newErrors);
    }

    // if (key === "goalType") {
    //   newFormFields.objectiveAmount = "";
    // }

    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _deleteAssignedTo = (index) => {
    const newFormFields = { ...formFields };
    newFormFields.assignedTo.splice(index, 1);
    setFormFields(newFormFields);
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    const newFormFields = { ...formFields };

    newFormFields.startDate = startDate;
    newFormFields.endDate = endDate;

    setFormFields(newFormFields);

    const newIsDirty = { ...isDirty };

    newIsDirty.startDate = true;
    newIsDirty.endDate = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };

      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const payload = {
        objective: newFormFields?.objective?.trim(),
        goalType: newFormFields?.goalType?.trim(),
        objectiveAmount: newFormFields?.objectiveAmount?.trim(),
        daysToComplete: newFormFields?.daysToComplete?.trim(),
        startDate: newFormFields?.startDate,
        endDate: newFormFields?.endDate,
        assignedType: newFormFields?.assignedType?.trim(),
        assignedTo: newFormFields?.assignedTo?.map((each) => each.value),
      };

      await createGoal(payload);

      showToast("Goal has been created", "success");

      onSuccess();

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _getAndUpdateUsersList = () => {
    try {
      dispatch(getAndUpdateUsersList());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAndUpdateGroupList = () => {
    try {
      dispatch(getAndUpdateGroupList());
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      _getAndUpdateUsersList();
      _getAndUpdateGroupList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} scrollable centered size={"lg"}>
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Goal Objective</Label>
              <Input
                placeholder="Enter"
                value={formFields.objective}
                onChange={(e) =>
                  _onChangeFormFields("objective", e.target.value)
                }
                onBlur={() => _onBlurFormFields("objective")}
              />
              {errors["objective"] ? (
                <div className="form-error">{errors["objective"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Duration</Label>
              <CustomDateRangePicker
                startDate={formFields?.startDate}
                endDate={formFields?.endDate}
                startDateId={"startDateId_AddGoalModal"}
                endDateId={"endDateId_AddGoalModal"}
                onDatesChange={_onDatesChange}
                isShowDateType={false}
                isDisabledPreviousRange={true}
              />
              {errors?.startDate || errors?.endDate ? (
                <div className="form-error">
                  {errors?.startDate || errors?.endDate}
                </div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup check>
              <Input
                id={`goalType_Store`}
                name="goalType"
                type="radio"
                value="Store"
                checked={formFields.goalType === "Store"}
                onChange={(e) =>
                  _onChangeFormFields("goalType", e.target.value)
                }
              />
              <Label check for={`goalType_Store`}>
                Store
              </Label>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup check>
              <Input
                id={`goalType_Sites`}
                name="goalType"
                type="radio"
                value="Sites"
                checked={formFields.goalType === "Sites"}
                onChange={(e) =>
                  _onChangeFormFields("goalType", e.target.value)
                }
              />
              <Label check for={`goalType_Sites`}>
                Domain
              </Label>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup check>
              <Input
                id={`goalType_Sales_Amount`}
                name="goalType"
                type="radio"
                value="Sales Amount"
                checked={formFields.goalType === "Sales Amount"}
                onChange={(e) =>
                  _onChangeFormFields("goalType", e.target.value)
                }
              />
              <Label check for={`goalType_Sales_Amount`}>
                Sales Amount
              </Label>
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <InputGroup>
                {formFields.goalType === "Sales Amount" ? (
                  <InputGroupText>$</InputGroupText>
                ) : null}
                <Input
                  placeholder="Enter"
                  value={formFields.objectiveAmount}
                  onChange={(e) =>
                    _onChangeFormFields("objectiveAmount", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("objectiveAmount")}
                />
              </InputGroup>
              {errors["objectiveAmount"] ? (
                <div className="form-error">{errors["objectiveAmount"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup check>
              <Input
                id={`assignedType_All`}
                name="assignedType"
                type="radio"
                value="All"
                checked={formFields.assignedType === "All"}
                onChange={(e) =>
                  _onChangeFormFields("assignedType", e.target.value)
                }
              />
              <Label check for={`assignedType_All`}>
                All
              </Label>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup check>
              <Input
                id={`assignedType_Affiliate`}
                name="assignedType"
                type="radio"
                value="Affiliate"
                checked={formFields.assignedType === "Affiliate"}
                onChange={(e) =>
                  _onChangeFormFields("assignedType", e.target.value)
                }
              />
              <Label check for={`assignedType_Affiliate`}>
                Affiliate(s)
              </Label>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup check>
              <Input
                id={`assignedType_Group`}
                name="assignedType"
                type="radio"
                value="Group"
                checked={formFields.assignedType === "Group"}
                onChange={(e) =>
                  _onChangeFormFields("assignedType", e.target.value)
                }
              />
              <Label check for={`assignedType_Group`}>
                Group(s)
              </Label>
            </FormGroup>
          </Col>

          <Col md={12}>
            <Label>Assigned To</Label>
            <SearchableInput
              menuIsOpen={true}
              options={
                formFields.assignedType === "Group"
                  ? filtersList?.groupList?.filter(
                    (each) =>
                      !formFields?.assignedTo?.find(
                        (subEach) => subEach?.value === each?.value
                      )
                  )
                  : filtersList?.usersList?.filter(
                    (each) =>
                      !formFields?.assignedTo?.find(
                        (subEach) => subEach?.value === each?.value
                      )
                  )
              }
              value={[]}
              onChange={(value) => _onChangeFormFields("assignedTo", value)}
              onBlur={(value) => _onBlurFormFields("assignedTo")}
              isMulti={true}
              disabled={formFields.assignedType === "All"}
              className="withRelative"
            />
            {errors?.assignedTo ? (
              <div className="form-error">{errors?.assignedTo}</div>
            ) : null}
          </Col>

          {formFields?.assignedTo?.length ? (
            <Col md={12} className="mt-3">
              <Label>
                {formFields.assignedType === "Group" ? "Groups" : "Members"}
              </Label>
              <ListGroup>
                {formFields?.assignedTo?.map((each, index) => (
                  <ListGroupItem
                    className="d-flex justify-content-between"
                    key={`assignedTo_${each?.value}_${index}`}
                  >
                    {each.label}
                    <Button
                      color="link"
                      className="text-danger"
                      onClick={() => _deleteAssignedTo(index)}
                    >
                      <SvgIcons type="delete" />
                    </Button>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          ) : null}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button color="primary" disabled={loading} onClick={() => _onSave()}>
          {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddGoalModal;
