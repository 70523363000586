import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  capitalize,
  formatCurrencyValue,
  getFullName,
} from "../helper-methods";
import moment from "moment";

function PaymentAccordion({ data = [] }) {
  const [isOpen, setIsOpen] = useState("");

  const _toggle = (id = "") => {
    setIsOpen((prev) => (prev === id ? "" : id));
  };

  return (
    <>
      <Accordion
        flush
        open={isOpen}
        toggle={_toggle}
        className="paymentAccordion"
      >
        {data?.map((each, index) => (
          <Fragment key={`payrollsData_${each._id}_${index}`}>
            {/*add this class when you want to disabled any accordion accordionDisabled */}
            <AccordionItem >
              <AccordionHeader targetId={String(index)}>
                <div className="dateWrap">
                  <span>{moment(each.endDate).format("DD")}</span>
                  <span>{moment(each.endDate).format("MMM")}</span>
                </div>

                <div className="accordionTitle">
                  <h6>
                    {formatCurrencyValue(
                      each.salaryAmount + each.shareAmount || 0
                    )}{" "}
                    transfer
                  </h6>
                  {each?.transferBankDetails?.length ? (
                    <span>
                      {each.transferBankDetails.map((bank, bankIndex) => (
                        <Fragment
                          key={`transferBankDetails_${each._id}_${index}_${bank._id}_${bankIndex}`}
                        >
                          {formatCurrencyValue(bank.amount || 0)} to{" "}
                          {capitalize(bank.name) || "N/A"}
                          {bankIndex + 1 < each?.transferBankDetails?.length
                            ? " || "
                            : ""}
                        </Fragment>
                      ))}
                    </span>
                  ) : null}
                </div>
              </AccordionHeader>
              <AccordionBody accordionId={String(index)}>
                <ul>
                  {each?._transactions?.length ? (
                    <>
                      {each?._transactions?.map(
                        (transaction, transactionIndex) => (
                          <li
                            key={`transactions_${each._id}_${index}_${transaction._id}_${transactionIndex}`}
                          >
                            {formatCurrencyValue(
                              transaction?.ownerCommission || 0
                            )}{" "}
                            commission for{" "}
                            {transaction?.domainsPurchased > 0 &&
                            transaction?.storesPurchased > 0
                              ? `Domain(${transaction?.domainsPurchased}) & Store(${transaction?.storesPurchased})`
                              : transaction?.domainsPurchased > 0
                              ? `Domain(${transaction?.domainsPurchased})`
                              : `Store(${transaction?.storesPurchased})`}{" "}
                            to {getFullName(transaction?._from?.name)} by{" "}
                            {getFullName(transaction?._to?.name)}
                          </li>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      <li>
                        Salary has been credited{" "}
                        {formatCurrencyValue(each.salaryAmount || 0)}
                      </li>
                    </>
                  )}
                </ul>
              </AccordionBody>
            </AccordionItem>
          </Fragment>
        ))}
      </Accordion>
    </>
  );
}

export default PaymentAccordion;
