import React, { useMemo, useState } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import { sendEmailTextToUsers } from "../http/http-calls";
import TextEditor from "./TextEditor";
import SvgIcons from "./SvgIcons";
import {
  templateCategoryConfig,
  templateVariablesConfig,
} from "../config/templateConfig";
import SearchableInput from "./SearchableInput";
import { useSelector } from "react-redux";

const SendEmailTextComponent = ({
  isSelectAll = false,
  userType = "Lead", // enum = ["Lead", "Affiliate"]
  selectedDataIds = [],
  setSelectedDataIds = () => {},
  setIsSelectAll = () => {},
}) => {
  const filtersList = useSelector((state) => state?.filtersList);

  const [bulkMessage, setBulkMessage] = useState({
    type: "Email", // or Text
    template: "",
    subject: "",
    message: "",
  });
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);

  const filteredTemplateList = useMemo(() => {
    return filtersList?.templateList?.length
      ? filtersList?.templateList.filter(
          (each) => each.category === bulkMessage.type
        )
      : [];
  }, [filtersList?.templateList, bulkMessage.type]);

  const _onChangeBulkMessage = (key, value) => {
    const newBulkMessage = { ...bulkMessage };

    if (key === "template" && value) {
      newBulkMessage.subject = value.subject || "";
      newBulkMessage.message = value.body || "";
    }

    if (key === "type") {
      newBulkMessage.subject = "";
      newBulkMessage.message = "";
      newBulkMessage.template = "";
    }

    newBulkMessage[key] = value;

    setBulkMessage(newBulkMessage);
  };

  const _onSelectVariable = (key, value) => {
    const newFormFields = { ...bulkMessage };
    newFormFields[key] += value;
    setBulkMessage(newFormFields);
  };

  const _handleFileUpload = (event) => {
    const newImageData = [...imageFiles];
    let allFiles = [];

    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      allFiles.push(element);
    }
    setImageFiles([...newImageData, ...allFiles]);
  };

  const _deleteAfile = (index) => {
    let newImageData = deepClone(imageFiles);

    if (Array.isArray(newImageData)) {
      newImageData = newImageData
        .slice(0, index)
        .concat(newImageData.slice(index + 1));
    }
    setImageFiles(newImageData);
  };

  const _sendBulkMessage = async () => {
    try {
      let newBulkMessage = { ...bulkMessage };

      if (newBulkMessage.type === "Email" && !newBulkMessage.subject) {
        showToast("Please enter the subject", "error");
        return;
      }

      if (!newBulkMessage?.message) {
        showToast("Please enter the message", "error");
        return;
      }

      if (!selectedDataIds?.length) {
        showToast("Please select at least one user", "error");
        return;
      }

      setSendMessageLoading(true);

      const payload = {
        isSelectAll: false,
        to: [],
        subject: newBulkMessage.subject,
        body: newBulkMessage.message,
        messageType: newBulkMessage.type,
        userType, // enum = ["Lead", "Affiliate"]
      };

      if (isSelectAll) {
        payload.isSelectAll = true;
      } else {
        payload.to = [...selectedDataIds];
      }

      if (imageFiles?.length) {
        const fileUploadPayload = imageFiles.map((data) => ({
          uploadData: data,
        }));

        const res = await uploadFileOnServer(fileUploadPayload);

        payload.attachmentDetails = res;
        payload.isAttachment = true;
      }

      if (newBulkMessage.template?._id) {
        payload.templateId = newBulkMessage.template?._id;
      }

      await sendEmailTextToUsers(payload);

      showToast(`${newBulkMessage.type} has been sent`, "success");

      newBulkMessage = {
        type: "Email", // or Text or notification
        subject: "",
        message: "",
        template: "",
      };

      setBulkMessage(newBulkMessage);
      setSelectedDataIds([]);
      setIsSelectAll(false);

      setSendMessageLoading(false);
    } catch (error) {
      errorHandler(error);
      setSendMessageLoading(false);
    }
  };

  return (
    <>
      <div className="box-container cardWrap mt-4">
        <h2 className="fs-20 mb-3">Contact</h2>

        <Row>
          <Col md="2">
            <FormGroup className="FormGroup">
              <Input
                type="select"
                value={bulkMessage.type}
                name="type"
                onChange={(e) => _onChangeBulkMessage("type", e.target.value)}
                disabled={sendMessageLoading}
              >
                {templateCategoryConfig.map((each) => (
                  <option key={each.value} value={each.value}>
                    {each.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Col md={10}>
            {bulkMessage.type === "Email" ? (
              <>
                <FormGroup>
                  <Label>Template</Label>
                  <SearchableInput
                    options={filteredTemplateList}
                    value={bulkMessage.template}
                    onChange={(value) =>
                      _onChangeBulkMessage("template", value)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <div className="messageVeriabel">
                    <Label>Subject</Label>
                    <UncontrolledDropdown>
                      <DropdownToggle caret>Insert Variables</DropdownToggle>
                      <DropdownMenu end>
                        {React?.Children?.toArray(
                          templateVariablesConfig?.map((each) => (
                            <DropdownItem
                              onClick={() =>
                                _onSelectVariable("subject", each.variable)
                              }
                            >
                              {each?.variable}
                            </DropdownItem>
                          ))
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Input
                    placeholder="Enter"
                    value={bulkMessage.subject}
                    disabled={sendMessageLoading}
                    onChange={(e) =>
                      _onChangeBulkMessage("subject", e.target.value)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <div className="messageVeriabel">
                    <Label>Body</Label>
                    <UncontrolledDropdown>
                      <DropdownToggle caret>Insert Variables</DropdownToggle>
                      <DropdownMenu end>
                        {React.Children.toArray(
                          templateVariablesConfig.map((each) => (
                            <DropdownItem
                              onClick={() =>
                                _onSelectVariable("message", each.variable)
                              }
                            >
                              {each.variable}
                            </DropdownItem>
                          ))
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <TextEditor
                    className="editorHeight"
                    content={bulkMessage.message}
                    onChange={(value) => _onChangeBulkMessage("message", value)}
                  />
                </FormGroup>
                <div className="uploadSection">
                  {userType === "Affiliate" ? (
                    <Label className="uploadItem">
                      <Input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={(event) => _handleFileUpload(event)}
                      />

                      <SvgIcons type={"upload"} />
                    </Label>
                  ) : null}

                  <div className="uploadBody">
                    {imageFiles?.length
                      ? imageFiles?.map((file, index) => (
                          <div className="uploadItem" key={index}>
                            <img src={URL.createObjectURL(file)} alt="file" />
                            <div
                              className="deleteIcon"
                              onClick={() => _deleteAfile(index)}
                            >
                              <SvgIcons type={"remove"} />
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : bulkMessage.type === "Text" ? (
              <>
                <FormGroup>
                  <Label>Template</Label>
                  <SearchableInput
                    options={filteredTemplateList}
                    value={bulkMessage.template}
                    onChange={(value) =>
                      _onChangeBulkMessage("template", value)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <div className="messageVeriabel">
                    <Label className="mb-0">Message</Label>

                    <UncontrolledDropdown direction="down">
                      <DropdownToggle caret>Insert Variables</DropdownToggle>
                      <DropdownMenu end>
                        {React.Children.toArray(
                          templateVariablesConfig.map((each) => (
                            <DropdownItem
                              onClick={() =>
                                _onSelectVariable("message", each.variable)
                              }
                            >
                              {each.variable}
                            </DropdownItem>
                          ))
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <Input
                    type="textarea"
                    rows="3"
                    value={bulkMessage.message}
                    disabled={sendMessageLoading}
                    placeholder="Enter message here"
                    onChange={(e) =>
                      _onChangeBulkMessage("message", e.target.value)
                    }
                  />
                </FormGroup>
              </>
            ) : (
              "Please select type"
            )}

            <div className="text-center mt-3">
              <Button
                color="primary"
                size="md"
                disabled={sendMessageLoading}
                onClick={() => _sendBulkMessage()}
              >
                {sendMessageLoading ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}{" "}
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SendEmailTextComponent;
