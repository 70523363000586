import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getAllTopics } from "../../http/http-calls";
import { capitalize, errorHandler } from "../../helper-methods";
import SkeletonLoading from "../../components/SkeletonLoading";
import CustomPagination from "../../components/Table/CustomPagination";
import { useSelector } from "react-redux";
import { APP_LOGO } from "../../config";
import CompactText from "../../components/custom/CompactText";
import NoDataDiv from "../../components/NoDataDiv";

const LearningUserPage = () => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [payload, setPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
  });

  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [topicCount, setTopicCount] = useState(0);

  const _getAllTopics = async (newPayload) => {
    setLoading(true);
    try {
      const res = await getAllTopics(newPayload ? newPayload : payload);

      const newTopics = res?.faqTopics?.length ? res.faqTopics : [];

      if (res.onGoingTopic?.length) {
        newTopics.forEach((each, index) => {
          const findTracking = res.onGoingTopic.find(
            (subEach) => subEach._topic === each._id
          );
          if (findTracking) {
            newTopics[index].isStarted = findTracking.isStarted;
            newTopics[index].isCompleted = findTracking.isCompleted;
            newTopics[index].watchedTill = findTracking.watchedTill;
          }
        });
      }

      setTopics(newTopics);

      setTopicCount(res?.totalCount);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...payload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setPayload(newDataPayload);
    _getAllTopics(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...payload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setPayload(newDataPayload);
    _getAllTopics(newDataPayload);
  };

  useEffect(() => {
    _getAllTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>Learning Center</h2>
      </div>

      <div className="trainingWrap">
        <div className="trainingCard">
          <Row>
            {topics?.length ? (
              topics?.map((each, index) => (
                <Col
                  xxl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  className="mb-4"
                  key={each?._id + index}
                >
                  <div
                    className="learningCard"
                    onClick={() =>
                      navigate(`/${userType}/learning-center-user/${each?._id}`)
                    }
                  >
                    <img
                      src={each?.imageFiles?.[0]?.url || APP_LOGO}
                      alt="cover"
                    />
                    <div className="learningContent">
                      <h2>{capitalize(each?.topicName) || "N/A"}</h2>
                      <p>
                        <CompactText text={each?.content} length={50} />
                      </p>
                    </div>
                  </div>
                </Col>
              ))
            ) : loading ? (
              <>
                {/* Col already in there */}
                <SkeletonLoading type="card" height={120} count={3} />
              </>
            ) : (
              <Col>
                <Card>
                  <CardBody>
                    <NoDataDiv />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </div>

      {/* <Card className="cardDesign">
        <CardHeader>
          <CardTitle>{params?.categoryName}</CardTitle>
          
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Topic</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody></tbody>
          </Table>
        </CardBody>
      </Card> */}

      <CustomPagination
        data={topics}
        dataCount={topicCount}
        dataPayload={payload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
      />
    </>
  );
};

export default LearningUserPage;
