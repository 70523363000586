import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  // CardFooter,
  // FormGroup,
  // Input,
  // InputGroup,
  // InputGroupText,
  // Label,
  // Button,
} from "reactstrap";
import {
  errorHandler,
  formatCurrencyValue,
  getFullName,
} from "../../helper-methods";
import { useDispatch } from "react-redux";
import { updateAndSkipTransaction } from "../../http/http-calls";
import { removePayrollTransactionsDataByIndex } from "../../redux/actions";
import PayrollAffiliateModal from "../modals/PayrollAffiliateModal";

const AffiliateCard = ({
  data,
  dataIndex,
  getAndUpdatePayrollTransactions = () => {},
}) => {
  const dispatch = useDispatch();

  const [formFields, setFormFields] = useState({
    owner: "",
    ownerCommission: "",
    affiliateCommission: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [loadingState, setLoadingState] = useState({
    data: false,
    update: false,
    skip: false,
    submit: false,
    settings: false,
  });

  const [payrollAffiliateModal, setPayrollAffiliateModal] = useState({
    isOpen: false,
    data: null,
    payrollInfo: null,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _togglePayrollAffiliateModal = (
    isOpen = false,
    data = null,
    payrollInfo = null
  ) => {
    if (isOpen && !data?.length) return;

    setPayrollAffiliateModal({
      isOpen,
      data,
      payrollInfo,
    });
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            // case "owner": {
            //   if (newFormFields[key]) {
            //     delete newErrors[key];
            //     newIsDirty[key] = false;
            //   } else {
            //     newErrors[key] = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            case "affiliateCommission": {
              if (newFormFields[key]?.length) {
                if (!isNaN(newFormFields[key]) && newFormFields[key] >= 0) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*Invalid value must be integer`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "ownerCommission": {
            //   if (newFormFields[key]?.length) {
            //     if (!isNaN(newFormFields[key]) && newFormFields[key] >= 0) {
            //       if (
            //         +newFormFields.affiliateCommission + +newFormFields[key] >
            //         data?.commissionAmount
            //       ) {
            //         newErrors[
            //           key
            //         ] = `*Owner Commission + Affiliate Commission must be less than Commissionable Amount ${formatCurrencyValue(
            //           data?.commissionAmount
            //         )}`;
            //         isFormValid = false;
            //       } else {
            //         delete newErrors[key];
            //         newIsDirty[key] = false;
            //       }
            //     } else {
            //       newErrors[key] = `*Invalid value must be integer`;
            //       isFormValid = false;
            //     }
            //   } else {
            //     newErrors[key] = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  // eslint-disable-next-line no-unused-vars
  const _onChangeFormFields = (key, value) => {
    if (
      (key === "affiliateCommission" || key === "ownerCommission") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1]?.length > 2) ||
        +value < 0 ||
        String(value)?.length > 8)
    ) {
      return;
    }

    const newFormFields = { ...formFields };

    newFormFields[key] = value;

    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  // eslint-disable-next-line no-unused-vars
  const _updateAndSkipTransaction = async ({ id, type = "update" }) => {
    try {
      if (!id) {
        console.error("ID is not defined");
        return;
      }
      if (type !== "update" && type !== "skip") {
        console.error("Transaction type is not defined");
        return;
      }

      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      _manageLoadingState(type, id);

      const payload = {
        // owner: newFormFields?.owner?.value,
        // ownerCommission: newFormFields?.ownerCommission,
        affiliateCommission: newFormFields?.affiliateCommission,
      };

      if (type === "update") {
        payload.isUpdated = true;
      }
      if (type === "skip") {
        payload.isSkipped = true;
        dispatch(removePayrollTransactionsDataByIndex({ index: dataIndex }));
      }

      await updateAndSkipTransaction({ id, payload });

      getAndUpdatePayrollTransactions();

      _manageLoadingState(type, false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState(type, false);
      getAndUpdatePayrollTransactions();
    }
  };

  const _setFormFields = (data) => {
    setFormFields({
      // owner: data?._owner?._id
      //   ? { label: getFullName(data?._owner?.name), value: data?._owner?._id }
      //   : "",
      // ownerCommission:
      //   data?.ownerCommission || data?.ownerCommission === 0
      //     ? String(data?.ownerCommission)
      //     : "",
      affiliateCommission:
        data?.affiliateCommission || data?.affiliateCommission === 0
          ? String(data?.affiliateCommission)
          : "",
    });
  };

  useEffect(() => {
    if (data) {
      _setFormFields(data);
    }
  }, [data]);

  return (
    <>
      <Card className="payrollList">
        <CardHeader>
          <CardTitle>{getFullName(data?.affName) || "N/A"}</CardTitle>
        </CardHeader>
        <CardBody>
          <ul>
            <li>
              <span>Sales</span>
              <span
                onClick={() =>
                  _togglePayrollAffiliateModal(true, data?.clients, "Sales")
                }
                className="cursorPointer text-primary"
              >
                {data?.noOfSales || 0}
              </span>
            </li>
            <li>
              <span>Owner</span>
              <span>{getFullName(data?.ownerName) || "N/A"}</span>
            </li>
            <li>
              <span>Sites</span>
              <span
                onClick={() =>
                  _togglePayrollAffiliateModal(
                    true,
                    data?.clients?.filter((each) => each.noOfSites > 0),
                    "Sites"
                  )
                }
                className={data?.noOfSites ? "cursorPointer text-primary" : ""}
              >
                {data?.noOfSites || 0}
              </span>
            </li>
            <li>
              <span>Site Amount</span>
              <span>{formatCurrencyValue(data?.siteAmount || 0)}</span>
            </li>
            <li>
              <span>Store</span>
              <span
                onClick={() =>
                  _togglePayrollAffiliateModal(
                    true,
                    data?.clients?.filter((each) => each.noOfStores > 0),
                    "Store"
                  )
                }
                className={data?.noOfStores ? "cursorPointer text-primary" : ""}
              >
                {data?.noOfStores || 0}
              </span>
            </li>
            <li>
              <span>Store Amount</span>
              <span>{formatCurrencyValue(data?.storeAmount || 0)}</span>
            </li>
            <li>
              <span>Site Commissionable Amount</span>
              <span>
                {formatCurrencyValue(data?.siteCommissionableAmount || 0)}
              </span>
            </li>
            <li>
              <span>Store Commissionable Amount</span>
              <span>
                {formatCurrencyValue(data?.storeCommissionableAmount || 0)}
              </span>
            </li>
            <li>
              <span>Affiliate Commission</span>
              <span>{formatCurrencyValue(data?.affiliateCommission || 0)}</span>
            </li>

            {/* <li className="fullWidth">
              <FormGroup>
                <Label>Affiliate Commission</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    placeholder="Enter"
                    value={formFields.affiliateCommission}
                    onChange={(e) =>
                      _onChangeFormFields("affiliateCommission", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("affiliateCommission")}
                  />
                </InputGroup>
                {errors["affiliateCommission"] ? (
                  <div className="form-error">
                    {errors["affiliateCommission"]}
                  </div>
                ) : null}
              </FormGroup>
            </li> */}
          </ul>
        </CardBody>
        {/* <CardFooter>
          <div className="text-center my-2">
            <Button
              color="primary"
              className="mx-2"
              disabled={
                loadingState?.update || loadingState?.skip ? true : false
              }
              onClick={() =>
                _updateAndSkipTransaction({ id: data?._id, type: "update" })
              }
            >
              {loadingState?.update === data?._id ? (
                <i className="fa fa-spinner fa-spin" />
              ) : null}{" "}
              Update
            </Button>
            <Button
              color="primary"
              className="mx-2"
              outline
              disabled={
                loadingState?.update || loadingState?.skip ? true : false
              }
              onClick={() =>
                _updateAndSkipTransaction({ id: data?._id, type: "skip" })
              }
            >
              {loadingState?.skip === data?._id ? (
                <i className="fa fa-spinner fa-spin" />
              ) : null}{" "}
              Skip
            </Button>
          </div>
        </CardFooter> */}
      </Card>

      <PayrollAffiliateModal
        isOpen={payrollAffiliateModal.isOpen}
        data={payrollAffiliateModal.data}
        payrollInfo={payrollAffiliateModal.payrollInfo}
        toggle={() => _togglePayrollAffiliateModal()}
      />
    </>
  );
};

export default AffiliateCard;
