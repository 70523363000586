import { createSlice } from "@reduxjs/toolkit";
import {
  getAndUpdateAffiliates,
  updateAffiliatesDataByIndex,
  getAndUpdateClients,
  updateClientsDataByIndex,
  getAndUpdateLeaderboard,
  getAndUpdateGoals,
  getAndUpdateTeams,
  updateTeamsDataByIndex,
  getAndUpdateAffiliateGroups,
  updateAffiliateGroupsDataByIndex,
  getAndUpdateOwners,
  getAndUpdatePayrollTransactions,
  removePayrollTransactionsDataByIndex,
  clearAllTableData,
  getAndUpdatePayrollSummary,
} from "../actions";
import { deepClone } from "../../helper-methods";

const initialState = {
  affiliatesData: [],
  affiliatesDataCount: 0,
  clientsData: [],
  clientsDataCount: 0,
  leaderboardData: [],
  leaderboardDataCount: 0,
  goalsData: [],
  goalsDataCount: 0,
  teamsData: [],
  teamsDataCount: 0,
  affiliateGroupsData: [],
  affiliateGroupsDataCount: 0,
  ownersData: [],
  ownersDataCount: 0,
  payrollTransactionsData: [],
  payrollSummaryData: [],
};

const tableDataSlice = createSlice({
  name: "tableData",
  initialState: deepClone(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAffiliatesDataByIndex, (state, action) => {
        const { index, key, value } = action.payload;
        state.affiliatesData[index][key] = value;
      })

      .addCase(getAndUpdateAffiliates.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateAffiliates.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.affiliatesData = data;
        state.affiliatesDataCount = count;
      })

      .addCase(getAndUpdateAffiliates.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateOwners.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateOwners.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.ownersData = data;
        state.ownersDataCount = count;
      })

      .addCase(getAndUpdateOwners.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateClientsDataByIndex, (state, action) => {
        const { index, key, value } = action.payload;
        state.clientsData[index][key] = value;
      })

      .addCase(getAndUpdateClients.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateClients.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.clientsData = data;
        state.clientsDataCount = count;
      })

      .addCase(getAndUpdateClients.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateLeaderboard.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateLeaderboard.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.leaderboardData = data;
        state.leaderboardDataCount = count;
      })

      .addCase(getAndUpdateLeaderboard.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateGoals.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateGoals.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.goalsData = data;
        state.goalsDataCount = count;
      })

      .addCase(getAndUpdateGoals.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateTeams.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateTeams.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.teamsData = data;
        state.teamsDataCount = count;
      })

      .addCase(getAndUpdateTeams.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateTeamsDataByIndex, (state, action) => {
        const { index, key, value } = action.payload;
        state.teamsData[index][key] = value;
      })

      .addCase(getAndUpdateAffiliateGroups.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateAffiliateGroups.fulfilled, (state, action) => {
        const { data, count } = action.payload;
        state.affiliateGroupsData = data;
        state.affiliateGroupsDataCount = count;
      })

      .addCase(getAndUpdateAffiliateGroups.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateAffiliateGroupsDataByIndex, (state, action) => {
        const { index, key, value } = action.payload;
        state.affiliateGroupsData[index][key] = value;
      })

      .addCase(getAndUpdatePayrollTransactions.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdatePayrollTransactions.fulfilled, (state, action) => {
        const { data, isEmptyData } = action.payload;
        state.payrollTransactionsData = data;
        state.payrollTransactionsDataIsEmptyData = isEmptyData;
      })

      .addCase(getAndUpdatePayrollTransactions.rejected, (state) => {
        state.loading = false;
      })

      .addCase(removePayrollTransactionsDataByIndex, (state, action) => {
        const { index } = action.payload;
        if (typeof state?.payrollTransactionsData === "object") {
          state.payrollTransactionsData.splice(index, 1);
        }
      })

      .addCase(getAndUpdatePayrollSummary.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdatePayrollSummary.fulfilled, (state, action) => {
        const { data, isEmptyData } = action.payload;
        state.payrollSummaryData = data;
        state.payrollSummaryDataIsEmptyData = isEmptyData;
      })

      .addCase(getAndUpdatePayrollSummary.rejected, (state) => {
        state.loading = false;
      })

      .addCase(clearAllTableData, (state, action) => {
        const resetData = deepClone(initialState);

        Object.keys(resetData).forEach((key) => {
          if (typeof state[key] === "object") {
            state[key] = [];
          } else {
            state[key] = 0;
          }
        });
      });
  },
});

export const tableDataReducer = tableDataSlice.reducer;
