import React from "react";
import ReactPaginate from "react-paginate";
import { Input } from "reactstrap";

const CustomPagination = ({
  data,
  dataCount,
  dataPayload,
  onPageChange = () => {},
  onSizeChange = () => {},
  isPageStartFromOne = false,
  loading = false,
  isShowLimitOption = true,
}) => {
  if (!data?.length || !dataCount || dataCount <= dataPayload?.limit) {
    return <></>;
  }

  const limitOptions = [10, 20, 50, 100];

  return (
    <div className="paginationWrap">
      <div className="showng_entries"><span>{dataCount}</span> Data Found</div>

      <div className="paginationWithCount">
        {isShowLimitOption && (
          <>
            <span className="me-2">Show</span>
            <div className="d-flex">
              <Input
                type="select"
                value={dataPayload.limit}
                onChange={(e) => onSizeChange(+e.target.value || 10)}
              >
                {!limitOptions.includes(dataPayload.limit) ? (
                  <option value={dataPayload.limit}>{dataPayload.limit}</option>
                ) : null}
                {limitOptions.map((each) => (
                  <option key={each} value={each}>
                    {each}
                  </option>
                ))}
              </Input>
              {loading ? (
                <span>
                  <i className="fa fa-spinner fa-spin" />
                </span>
              ) : null}
            </div>{" "}
            <span className="ms-2">Per Page</span>
          </>
        )}
        <ReactPaginate
          forcePage={
            isPageStartFromOne
              ? dataPayload.pageNumber - 1
              : dataPayload.pageNumber
          }
          className="pagination justify-content-end"
          breakLabel="..."
          nextLabel={<i className="fa fa-chevron-right" />}
          previousLabel={<i className="fa fa-chevron-left" />}
          previousClassName="previous"
          nextClassName="next"
          onPageChange={({ selected }) =>
            onPageChange(isPageStartFromOne ? selected + 1 : selected)
          }
          pageRangeDisplayed={3}
          pageCount={Math.ceil(dataCount / dataPayload.limit)}
          pageClassName="page-item"
          renderOnZeroPageCount={null}
          containerClassName={"pagination"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
          disabledClassName={"page-item"}
        />
      </div>
    </div>
  );
};

export default CustomPagination;
