export const templateCategoryConfig = [
  { label: "Email", value: "Email" },
  { label: "Text Message", value: "Text" },
];

export const templateVariablesConfig = [
  { variable: "{current_user_email}" },
  { variable: "{current_user_first_name}" },
  { variable: "{current_user_last_name}" },
  { variable: "{current_user_name}" },
  { variable: "{current_user_phone_number}" },
  { variable: "{current_user_company_name}" },
  // { variable: "{current_user_documents_list}" },
  // { variable: "{current_user_agreementLink}" },
  // { variable: "{current_user_payout}" },
  { variable: "{current_user_totalCommision}" },
  { variable: "{sender_email}" },
  { variable: "{sender_first_name}" },
  { variable: "{sender_last_name}" },
  { variable: "{sender_name}" },
  { variable: "{sender_phone_number}" },
  { variable: "{current_date}" },
  { variable: "{current_time}" },
];
