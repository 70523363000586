import React from "react";
import { Button } from "reactstrap";
import SvgIcons from "./SvgIcons";

const ActionButtonsComponent = ({
  data,
  deleteLoading,
  onDelete,
  onEdit,
  hasPermissionToView,
  hasPermissionToEdit,
  hasPermissionToDelete,
}) => {
  console.log({
    hasPermissionToView,
    hasPermissionToEdit,
    hasPermissionToDelete,
  });
  return (
    <>
      {hasPermissionToView && !hasPermissionToEdit && !hasPermissionToDelete ? (
        <div
          onClick={() => onEdit(data)}
          className="themeColor cursorPointer w-auto d-inline-block pe-3"
        >
          <SvgIcons type="eye" />
        </div>
      ) : (
        <div className="action" style={{ width: 100 }}>
          <Button
            color="link"
            onClick={() => onEdit(data)}
            disabled={!hasPermissionToEdit}
          >
            <SvgIcons type="edit" />
          </Button>

          <Button
            color="link"
            className="ms-1 text-danger"
            disabled={deleteLoading ? true : false || !hasPermissionToDelete}
            onClick={() => onDelete(data)}
          >
            <SvgIcons type="delete" />

            {/* {deleteLoading === data?._id ? (
          <i className="fa fa-spinner fa-spin text-danger" />
        ) : (
          <i className="fa fa-trash-alt text-danger" />
        )} */}
          </Button>
        </div>
      )}
    </>
  );
};

export default ActionButtonsComponent;
