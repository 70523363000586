import { store as REDUX_STORE } from "../redux/store";

const navLinksAdmin = [
  {
    label: "Leaderboard",
    value: "leaderBoard",
    link: "/admin/leaderboard",
  },
  {
    label: "Affiliates",
    value: "affiliates",
    link: "/admin/affiliates",
  },
  {
    label: "Clients",
    value: "clients",
    link: "/admin/clients",
  },
  {
    label: "Owners",
    value: "owners",
    link: "/admin/owners",
  },
  {
    label: "Goals",
    value: "goals",
    link: "/admin/goals",
  },
  {
    label: "Payroll",
    value: "payroll",
    link: "/admin/payroll",
  },
  {
    label: "Learning Center",
    value: "learningCenter",
    link: "/admin/learning-center",
  },
  {
    label: "Message Templates",
    value: "messageTemplates",
    link: "/admin/message-templates",
  },
  {
    label: "Affiliate Groups",
    value: "affiliateGroups",
    link: "/admin/affiliate-groups",
  },
  {
    label: "Team Members",
    value: "teamMembers",
    link: "/admin/team-members",
  },
];

const navLinksAffiliate = [
  {
    label: "Leaderboard",
    link: "/affiliate/leaderboard",
  },
  {
    label: "Home",
    link: "/affiliate/home",
  },
  {
    label: "Learning Center",
    link: "/affiliate/learning-center-user",
  },
];

const navLinksOwner = [
  {
    label: "Home",
    link: "/owner/home",
  },
];

export const getNavLinks = () => {
  const state = REDUX_STORE.getState();

  const userType = state?.userCredential?.user?.userType?.toLowerCase();

  if (userType === "affiliate") {
    return navLinksAffiliate;
  }

  if (userType === "owner") {
    return navLinksOwner;
  }

  // admin nav bar
  return navLinksAdmin;
};
