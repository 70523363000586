import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
  capitalize,
  errorHandler,
  openUrlOnNewTab,
} from "../../helper-methods";
import {
  getTopicById,
  // maintainWatchlist
} from "../../http/http-calls";
import SkeletonLoading from "../../components/SkeletonLoading";
import SvgIcons from "../../components/SvgIcons";
import CustomVideoPlayer from "../../components/custom/CustomVideoPlayer";
import { withRouter } from "../../hooks/withRouter";
import NoDataDiv from "../../components/NoDataDiv";
// import LessonVideoPlayer from "../../components/LessonVideoPlayer";

class LearningUserDetailPage extends Component {
  state = {
    topicDetails: null,
    runningLessonData: null,
    isCompletedLesson: false,
    loading: false,
  };

  // _updateLessonDuration = async (payload) => {
  //   try {
  //     const { runningLessonData } = this.state;

  //     await maintainWatchlist({ id: runningLessonData?._id, payload });
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  // _onProgress = (videoRef) => {
  //   try {
  //     const { topicDetails } = deepClone(this.state);

  //     if (!topicDetails || topicDetails?.isCompleted) return;

  //     const watchedTill = Number(videoRef?.current?.currentTime?.toFixed(0));
  //     const wholeDuration = Number(videoRef?.current?.duration?.toFixed(0));

  //     if (watchedTill && wholeDuration) {
  //       const payload = {
  //         watchedTill,
  //       };

  //       if (topicDetails) {
  //         if (!topicDetails.isStarted && watchedTill <= 5) {
  //           topicDetails.isStarted = true;

  //           // api payload updated for lesson started
  //           payload.isStarted = true;
  //         }

  //         const remainingTimeInSeconds = wholeDuration - watchedTill;

  //         if (
  //           topicDetails.isStarted &&
  //           !topicDetails.isCompleted &&
  //           remainingTimeInSeconds <= 5
  //         ) {
  //           // api payload updated for lesson completed
  //           payload.isCompleted = true;

  //           topicDetails.isCompleted = true;

  //           this.setState({ isCompletedLesson: true });
  //         }

  //         topicDetails.watchedTill = watchedTill;

  //         this.setState({ topicDetails });
  //       }

  //       // api hit for track lesson duration
  //       this._updateLessonDuration(payload);
  //     }
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  _topicDetails = async () => {
    try {
      this.setState({ loading: true });

      const res = await getTopicById(this.props?.params?.id);

      const topicDetails = res?.faqTopic;

      if (res.onGoingTopic?.length) {
        const findTracking = res.onGoingTopic.find(
          (subEach) => subEach._topic === res?.faqTopic?._id
        );
        if (findTracking) {
          topicDetails.isStarted = findTracking.isStarted;
          topicDetails.isCompleted = findTracking.isCompleted;
          topicDetails.watchedTill = findTracking.watchedTill;
        }
      }

      this.setState({
        topicDetails,
        runningLessonData: {
          _id: res?.faqTopic?._id,
          title: res?.faqTopic?.topicName,
          videoUrl: res?.faqTopic?.videoFiles?.[0]?.url,
          duration: res?.faqTopic?.videoFiles?.[0]?.duration,
          poster: res?.faqTopic?.imageFiles?.[0]?.url,
          isStarted: topicDetails?.isStarted || false,
          isCompleted: topicDetails?.isCompleted || false,
          watchedTill: topicDetails?.watchedTill || 0,
        },
        loading: false,
      });
    } catch (error) {
      errorHandler({ reason: "Data not found" });
      this.setState({ loading: false });
      this.props.navigate && this.props.navigate(-1);
    }
  };

  componentDidMount = () => {
    this._topicDetails();
  };

  render() {
    const {
      topicDetails,
      runningLessonData,
      // isCompletedLesson,
      loading,
    } = this.state;

    return (
      <>
        <div className="innerHeader">
          <div className="backToPage">
            <Button
              color="link"
              className="backBtn"
              onClick={() => this.props?.navigate && this.props.navigate(-1)}
            >
              <SvgIcons type={"backArrow"} />
            </Button>

            {loading ? (
              <SkeletonLoading
                type="box"
                count={1}
                borderRadius={8}
                width={Math.random() * 60 + 100}
                height={25}
              />
            ) : (
              <>
                <h2>
                  {capitalize(topicDetails?.topicName) || "Learning Details"}
                </h2>{" "}
                {topicDetails?.isCompleted ? <SvgIcons type="check" /> : null}
              </>
            )}
          </div>
        </div>

        <Card className="cardDesign">
          {topicDetails ? (
            <>
              <CardBody className="traningContent">
                <div className="topicVideo">
                  {/* <video
                  src={topicDetails?.videoFiles?.[0]?.url}
                  poster={topicDetails?.imageFiles?.[0]?.url}
                /> */}

                  {/* <LessonVideoPlayer
                  runningLessonVideoRef={runningLessonVideoRef}
                  runningLessonData={runningLessonData}
                  onProgress={_onProgress}
                /> */}

                  <CustomVideoPlayer
                    runningLessonData={runningLessonData}
                    // isCompletedLesson={isCompletedLesson}
                    isCompletedLesson={true}
                    fullScreenMode
                    controls
                    isProgress={false}
                    // onProgress={this._onProgress}
                  />
                </div>

                {/* <h1>{topicDetails?.topicName}</h1> */}
                <p>{topicDetails?.content}</p>

                {topicDetails?.pdfFiles?.[0]?.url && (
                  <Button
                    color="link"
                    className="remove p-0"
                    onClick={() =>
                      openUrlOnNewTab(topicDetails?.pdfFiles?.[0]?.url, true)
                    }
                  >
                    <SvgIcons type={"pdf"} />
                    {/* {topicDetails?.pdfFiles?.[0]?.title || "PDF Notes"} */}
                    <span className="pr-2">PDF Notes</span>
                  </Button>
                )}
              </CardBody>
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <CardHeader>
                    <CardTitle>
                      <SkeletonLoading type="box" height={30} />
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <SkeletonLoading type="box" height={400} />
                  </CardBody>
                </>
              ) : (
                <>
                  <NoDataDiv />
                </>
              )}
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(LearningUserDetailPage);
