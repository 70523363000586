import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Table,
} from "reactstrap";
import AddTopics from "../../components/modals/AddTopics";
import {
  deleteTopic,
  editTopic,
  getAllTopics,
  rearrangeTopics,
} from "../../http/http-calls";
import { errorHandler, hasPermission, showToast } from "../../helper-methods";
import CustomPagination from "../../components/Table/CustomPagination";
import Swal from "sweetalert2";
import SkeletonLoading from "../../components/SkeletonLoading";
import SvgIcons from "../../components/SvgIcons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import ActionButtonsComponent from "../../components/ActionButtonsComponent";
import StatusChangeComponent from "../../components/StatusChangeComponent";
import { categoryTopicStatus } from "../../config/helper-config";
import CompactText from "../../components/custom/CompactText";
import NoDataDiv from "../../components/NoDataDiv";
import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { getAndUpdateUserData } from "../../redux/actions";

// Create a drag handle component
const DragHandle = SortableHandle(() => (
  <span style={{ cursor: "grab" }}>
    <i
      className="fas fa-grip-vertical"
      style={{ marginRight: "8px", cursor: "grabbing" }}
    />
  </span>
));

const SortableContainerList = SortableContainer(({ children }) => {
  return <tbody className="w-full">{children}</tbody>;
});

const SortableRow = SortableElement(
  ({
    topic,
    topicIndex,
    _changeStatus,
    _toggleAddTopic,
    _deleteTemplateAlert,
    flag,
    deleteLoading,
    index,
    hasPermissionToView,
    hasPermissionToEdit,
  }) => {
    return (
      <tr key={index}>
        <td>
          <div className="d-flex align-items-center" style={{ width: 150 }}>
            <DragHandle /> <CompactText text={topic?.topicName || "N/A"} />
          </div>
        </td>
        <td>
          <StatusChangeComponent
            value={topic?.isActive}
            onChange={(event) => _changeStatus(event, topic?._id, topicIndex)}
            disabled={flag}
            options={categoryTopicStatus}
          />
        </td>
        <td>
          <ActionButtonsComponent
            data={topic}
            deleteLoading={deleteLoading}
            onDelete={_deleteTemplateAlert}
            onEdit={(topic) => _toggleAddTopic(true, topic)}
            hasPermissionToView={hasPermissionToView}
            hasPermissionToEdit={hasPermissionToEdit}
            hasPermissionToDelete={hasPermissionToEdit}
          />
        </td>
      </tr>
    );
  }
);

// Create a sortable table container
const SortableTable = ({ items, loading, ...props }) => {
  return (
    <>
      {items?.length ? (
        <SortableContainerList
          useDragHandle={true}
          onSortEnd={props?.onSortEnd}
        >
          {items.map((topic, index) => (
            <SortableRow
              key={index}
              index={index}
              topicIndex={index}
              topic={topic}
              {...props}
            />
          ))}
        </SortableContainerList>
      ) : loading ? (
        <tbody>
          <SkeletonLoading type="table" height={20} row={5} col={3} />
        </tbody>
      ) : (
        <tbody>
          <tr className="text-center">
            <td colSpan="3">
              <NoDataDiv />
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

const LearningPage = () => {
  const searchIntervalRef = useRef({ current: null });
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [addTopicModal, setAddTopicModal] = useState({
    isOpen: false,
    data: null,
  });

  const [dataPayload, setDataPayload] = useState({
    pageNumber: 0,
    limit: 10,
    page: 1,
  });
  const [filters, setFilters] = useState({
    search: "",
    status: "",
  });
  const [topics, setTopics] = useState([]);
  const [topicCount, setTopicCount] = useState(0);
  const [flag, setFlag] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "learningCenter", action: "view" }),
    []
  );

  const hasPermissionToCreate = useMemo(
    () => hasPermission({ page: "learningCenter", action: "create" }),
    []
  );

  const hasPermissionToEdit = useMemo(
    () => hasPermission({ page: "learningCenter", action: "edit" }),
    []
  );

  const _toggleAddTopic = (isOpen = false, data = null) => {
    setAddTopicModal({ isOpen, data });
  };

  const _getAllTopics = async (payload) => {
    try {
      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      setLoading(true);

      const res = await getAllTopics(payload);

      setTopics(res?.faqTopics);
      setTopicCount(res?.totalCount);
      setLoading(false);
      setSearchLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      setSearchLoading(false);
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllTopics(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllTopics(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      const newDataPayload = {
        pageNumber: 0,
        limit: 10,
        page: 1,
        filters: {},
      };

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (
            eachFilterKey === "referredLeadCount" ||
            eachFilterKey === "deals" ||
            eachFilterKey === "goalAchieved"
          ) {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey] === "Active" ? true : false;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAllTopics(newDataPayload);
    } catch (error) {
      console.log({ error });
    }
  };

  const _onChangeFilters = (key, value) => {
    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    setSearchLoading(true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _changeStatus = async (event, id, index) => {
    let oldReports = JSON.parse(JSON.stringify(topics));

    const value = event.target.value;
    topics[index].isActive = value;

    setTopics([...topics]);
    setFlag(true);

    try {
      await editTopic(id, {
        status: value === "true" ? true : false,
      });

      showToast("Status successfully changed", "success");
    } catch (error) {
      setTopics(oldReports);
      errorHandler(error);
    }
    setFlag(false);
  };

  const _deleteTemplate = async (id) => {
    // deleteCategory
    setDeleteLoading(id);

    const newData = [...topics];
    let newDataCount = topicCount;
    const findDataIndex = newData.findIndex((each) => each._id === id);
    if (findDataIndex > -1) {
      newData.splice(findDataIndex, 1);
      setTopics(newData);
      newDataCount--;
      setTopicCount(newDataCount);
    }

    try {
      await deleteTopic(id);

      Swal.fire("Deleted!", "Template has been deleted.", "success");
      if (topics.length === 0 || newDataCount <= 10) {
        _onPageChange();
      }
      setDeleteLoading(false);
    } catch (error) {
      errorHandler(error);
      setDeleteLoading(false);
      _getAllTopics(dataPayload);
    }
  };

  const _deleteTemplateAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this (Title: ${data?.topicName}) Template.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteTemplate(data._id);
      }
    });
  };

  const _clearFilters = () => {
    const newPayload = {
      pageNumber: 0,
      limit: 10,
      page: 1,
    };
    setDataPayload(newPayload);
    setFilters({
      search: "",
      status: "",
    });
    setDataPayload(newPayload);
    _getAllTopics(newPayload);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // Use the array's slice method to create a new array with the rearranged items
    const newTopics = [...topics];
    newTopics.splice(newIndex, 0, newTopics.splice(oldIndex, 1)[0]);

    let startingNumber = dataPayload.pageNumber * 10; // Use orderNumber of the first item or default to

    let updatedArray = newTopics.map((item, index) => {
      return { ...item, orderNumber: startingNumber + index };
    });
    const neworderNumber = updatedArray[newIndex].orderNumber;
    const id = updatedArray[newIndex]._id;

    // apicall(oldorderNumber, neworderNumber)

    setTopics(updatedArray);
    let apiPayload = {
      newOrder: neworderNumber,
      topicId: id,
    };
    _reArrangeArray(apiPayload);
  };

  const _reArrangeArray = async (newPayload) => {
    try {
      await rearrangeTopics(newPayload);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      navigate("/");
    }

    // use in all place where pemission settings are used
    // dispatch(getAndUpdateUserData());

    _getAllTopics(dataPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Learning Center{" "}
          {loading ? <i className="fa fa-spinner fa-spin" /> : null}
        </h2>

        <div className="right">
          <Button
            color="primary"
            onClick={() => _toggleAddTopic(true)}
            disabled={!hasPermissionToCreate}
          >
            Add Topic
          </Button>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <div className="filterWrapper">
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchBar">
            <Label>Search</Label>
            <InputGroup className="searchTable">
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <div className="formGroup">
            <Label>Status</Label>
            <Input
              type="select"
              value={filters.status}
              name="status"
              onChange={(e) => _onChangeFilters("status", e.target.value)}
            >
              <option value="">All</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Input>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <Table responsive>
          <thead>
            <tr>
              <th>Topic</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <SortableTable
            items={topics}
            loading={loading}
            onSortEnd={onSortEnd}
            _changeStatus={_changeStatus}
            _toggleAddTopic={_toggleAddTopic}
            _deleteTemplateAlert={_deleteTemplateAlert}
            flag={flag}
            deleteLoading={deleteLoading}
            hasPermissionToView={hasPermissionToView}
            hasPermissionToEdit={hasPermissionToEdit}
          />
        </Table>

        {addTopicModal.isOpen && (
          <AddTopics
            isOpen={addTopicModal.isOpen}
            toggle={() => _toggleAddTopic()}
            fetchAllTopics={() => _getAllTopics(dataPayload)}
            editDetails={addTopicModal?.data}
            viewOnly={!hasPermissionToEdit}
          />
        )}

        <CustomPagination
          data={topics}
          dataCount={topicCount}
          dataPayload={dataPayload}
          onPageChange={_onPageChange}
          onSizeChange={_onSizeChange}
        />
      </div>
    </>
  );
};

export default LearningPage;
