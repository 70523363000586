import React, { useMemo, useState } from "react";
import { Table } from "reactstrap";
import { formatCurrencyValue, formatDate } from "../helper-methods";
import CustomPagination from "./Table/CustomPagination";
import NoDataDiv from "./NoDataDiv";

const AffiliatePaymentSummaryTable = ({ lists = [] }) => {
  const [dataPayload, setDataPayload] = useState({
    pageNumber: 1,
    limit: 5,
  });

  const paginationData = useMemo(() => {
    return lists?.slice(
      (dataPayload?.pageNumber - 1) * dataPayload?.limit,
      (dataPayload?.pageNumber - 1) * dataPayload?.limit + dataPayload?.limit
    );
  }, [lists, dataPayload]);

  const _onSizeChange = (limit = 5) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = 1;
    newDataPayload["limit"] = limit;

    setDataPayload(newDataPayload);
  };

  const _onPageChange = (pageNumber = 1) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["pageNumber"] = pageNumber;
    setDataPayload(newDataPayload);
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            {/* <th>Status</th> */}
          </tr>
        </thead>
        <tbody>
          {paginationData?.length ? (
            paginationData?.map((each) => (
              <tr key={each._id}>
                <td>{formatDate(each.paymentDate) || "N/A"}</td>
                <td>{formatCurrencyValue(each.amount || 0)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>
                <NoDataDiv />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <CustomPagination
        data={paginationData}
        dataCount={lists?.length}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        isPageStartFromOne={true}
      />
    </>
  );
};

export default AffiliatePaymentSummaryTable;
