import { createSlice } from "@reduxjs/toolkit";
import {
  getAndUpdateUsersList,
  updateUsersListByIndex,
  getAndUpdateGroupList,
  getAndUpdateTemplateList,
  getAndUpdateOwnerList,
  clearAllFiltersListData,
  getAndUpdateVendorsList,
} from "../actions";

const initialState = {
  usersList: [],
  groupList: [],
  templateList: [],
  ownerList: [],
  vendorsList: [],
};

const filtersListSlice = createSlice({
  name: "filtersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUsersListByIndex, (state, action) => {
        const { index, key, value } = action.payload;
        state.usersList[index][key] = value;
      })

      .addCase(getAndUpdateUsersList.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateUsersList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.usersList = data;
      })

      .addCase(getAndUpdateUsersList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateGroupList.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateGroupList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.groupList = data;
      })

      .addCase(getAndUpdateGroupList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateTemplateList.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateTemplateList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.templateList = data;
      })

      .addCase(getAndUpdateTemplateList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateOwnerList.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateOwnerList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.ownerList = data;
      })

      .addCase(getAndUpdateOwnerList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getAndUpdateVendorsList.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateVendorsList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.vendorsList = data;
      })

      .addCase(getAndUpdateVendorsList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(clearAllFiltersListData, (state) => {
        state.usersList = [];
        state.groupList = [];
        state.templateList = [];
        state.ownerList = [];
        state.vendorsList = [];
      });
  },
});

export const filtersListReducer = filtersListSlice.reducer;
