import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Label, Input, InputGroup, InputGroupText } from "reactstrap";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomTable from "../../components/Table/CustomTable";
import {
  capitalize,
  deepClone,
  errorHandler,
  formatDate,
  getFullName,
  hasPermission,
} from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateGoals, saveLocalFilters } from "../../redux/actions";
import SvgIcons from "../../components/SvgIcons";
import AddGoalModal from "../../components/modals/AddGoalModal";
import CompactText from "../../components/custom/CompactText";
import CustomTooltip from "../../components/custom/CustomTooltip";
import { useNavigate } from "react-router-dom";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Goal Objective", id: "goalObjective" },
  { label: "Duration", id: "dateRange" },
  { label: "Assigned To", id: "_assignees" },
  // { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const initialFiltersState = {
  startDate: null,
  endDate: null,
  dateType: "",
  search: "",
};

const GoalsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchIntervalRef = useRef({ current: null });

  const tableData = useSelector((state) => state?.tableData);
  const localFilters = useSelector((state) => state?.localFilters);

  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [filters, setFilters] = useState(deepClone(initialFiltersState));
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [addGoalModal, setAddGoalModal] = useState({
    isOpen: false,
    data: null,
  });
  const [filterShow, setFilterShow] = useState(false);

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "goals", action: "view" }),
    []
  );

  const hasPermissionToCreate = useMemo(
    () => hasPermission({ page: "goals", action: "create" }),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddGoalModal = (isOpen = false, data = null) => {
    setAddGoalModal({ isOpen, data });
  };
  const _getAndUpdateGoals = async (payload) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "GoalsPagePayload",
          value: deepClone(payload),
        })
      );

      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      await getAndUpdateGoals(payload)(dispatch);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateGoals(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateGoals(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAndUpdateGoals(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "GoalsPage",
          value: deepClone(newFilters),
        })
      );

      const newDataPayload = deepClone(initialDataPayloadState);

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (
            eachFilterKey === "referredLeadCount" ||
            eachFilterKey === "deals" ||
            eachFilterKey === "goalAchieved"
          ) {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey] === "Active" ? true : false;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAndUpdateGoals(newDataPayload);
    } catch (error) {}
  };

  // const _onChangeFilters = (key, value) => {
  //   _manageLoadingState("filters", true);

  //   const newFilters = { ...filters };
  //   newFilters[key] = value;
  //   setFilters(newFilters);

  //   _onFiltersUpdated(newFilters);
  // };

  const _onDatesChange = ({ startDate, endDate, dateType }) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    newFilters.dateType = dateType;

    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _clearFilters = () => {
    _manageLoadingState("filters", true);

    const newFilters = deepClone(initialFiltersState);

    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _dataFormat = (cell, row, header, index) => {
    switch (header) {
      case "goalObjective": {
        return (
          <>
            <CompactText length={60} text={row?.objective || "N/A"} />
          </>
        );
      }
      case "_assignees": {
        return (
          <>
            {row?.assignedType === "All" ? (
              "All"
            ) : (
              <>
                {row?._assignees?.length ? (
                  <>
                    {row?._assignees?.slice(0, 1)?.map((e, i) => (
                      <span
                        key={`_assignees_${row?._id}_${e}_${i}_${index}`}
                        className="mr-1"
                      >
                        {getFullName(e?.name) || capitalize(e?.name) || "-"}
                      </span>
                    ))}

                    {row?._assignees?.slice(1)?.length ? (
                      <>
                        <Button
                          id={`_assignees_customtooltip_${row?._id}_${index}`}
                          color="link"
                          className="p-0 themeColor text-decoration-none"
                          style={{ verticalAlign: 0 }}
                        >
                          +{row?._assignees?.slice(1)?.length}
                        </Button>

                        <CustomTooltip
                          target={`_assignees_customtooltip_${row?._id}_${index}`}
                          text={row?._assignees?.slice(1)?.map((e, i) => (
                            <span
                              key={`_assignees_${row?._id}_${e._id}_${i}_${index}`}
                              className={"comma-separated"}
                            >
                              {getFullName(e?.name) ||
                                capitalize(e?.name) ||
                                "-"}
                            </span>
                          ))}
                        />
                      </>
                    ) : null}
                  </>
                ) : (
                  "-"
                )}
              </>
            )}
          </>
        );
      }
      case "dateRange": {
        return (
          <>
            {cell?.from && cell?.to ? (
              <>
                {formatDate(cell?.from)} - {formatDate(cell?.to)}
              </>
            ) : (
              "-"
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _initialize = () => {
    try {
      if (
        localFilters?.filters?.GoalsPage &&
        localFilters?.filters?.GoalsPagePayload
      ) {
        setFilters(localFilters.filters.GoalsPage);
        setDataPayload(localFilters.filters.GoalsPagePayload);
        _getAndUpdateGoals(localFilters.filters.GoalsPagePayload);
      } else {
        _onPageChange();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      navigate("/");
    }

    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Goals{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button
            onClick={() => _toggleAddGoalModal(true)}
            color="primary"
            disabled={!hasPermissionToCreate}
          >
            Add
          </Button>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
          <Button
            color="link"
            className="hideShowFilter"
            onClick={() => setFilterShow(!filterShow)}
          >
            <SvgIcons type={"filter"} />
          </Button>
        </div>
      </div>

      <div className={`filterWrapper ${filterShow === true ? "show" : ""}`}>
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchbar">
            <Label>Search</Label>
            <InputGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {loadingState?.search ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <div className="formGroup">
            <Label>Duration</Label>
            <CustomDateRangePicker
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_GoalsPage"}
              endDateId={"endDateId_GoalsPage"}
              onDatesChange={_onDatesChange}
              dateType={filters.dateType}
            />
          </div>
        </div>
      </div>

      <CustomTable
        data={tableData?.goalsData}
        dataCount={tableData?.goalsDataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={false}
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      <AddGoalModal
        isOpen={addGoalModal.isOpen}
        data={addGoalModal.data}
        toggle={() => _toggleAddGoalModal()}
        onSuccess={() => _onPageChange()}
      />
    </>
  );
};

export default GoalsPage;
