import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import NoDataDiv from "../NoDataDiv";
import {
  formatCurrencyValue,
  formatDate,
  getFullName,
} from "../../helper-methods";

const PayrollAffiliateModal = ({
  isOpen = false,
  data = [],
  toggle = () => {},
  payrollInfo = "",
}) => {
  const totalEarning = useMemo(() => {
    if (!data || !data?.length) return 0;

    return data.reduce((acc, each) => acc + (each.affiliateCommission || 0), 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const _closeModal = () => {
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        className="payRollModal"
        size="xl"
      >
        <ModalHeader className="d-flex" toggle={() => _closeModal()}>
          {payrollInfo === "Sales" && <>Sales</>}
          {payrollInfo === "Sites" && <>Sites</>}
          {payrollInfo === "Store" && <>Store</>}

          <div className="fs-14">
            Total Earning: {formatCurrencyValue(totalEarning)}
          </div>
        </ModalHeader>

        <ModalBody>
          {data?.length ? (
            <Table>
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Date of Transactions</th>

                  {payrollInfo === "Sites" || payrollInfo === "Store" ? (
                    <>
                      <th>Qty</th>
                    </>
                  ) : null}

                  {payrollInfo === "Sales" && (
                    <>
                      <th>Sites</th>
                      <th>Stores</th>
                    </>
                  )}

                  <th>Sales Amount</th>

                  {payrollInfo === "Sites" || payrollInfo === "Store" ? (
                    <>
                      <th>Commissionable Amount</th>
                    </>
                  ) : null}

                  <th>Affiliate Commission</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((each, index) => (
                  <tr key={`data_${each._id}_${index}`}>
                    <td>{getFullName(each?.clientName) || "N/A"}</td>

                    <td>{formatDate(each.dateOfTransaction) || "N/A"}</td>

                    {payrollInfo === "Sites" || payrollInfo === "Store" ? (
                      <>
                        <td>{each?.noOfSales || 0}</td>
                      </>
                    ) : null}

                    {payrollInfo === "Sales" && (
                      <>
                        <td>{each?.noOfSites || 0}</td>
                        <td>{each?.noOfStores || 0}</td>
                      </>
                    )}

                    <td>{formatCurrencyValue(each.salesAmount || 0)}</td>

                    {payrollInfo === "Sites" || payrollInfo === "Store" ? (
                      <>
                        <td>
                          {formatCurrencyValue(
                            (each.domainCommissionableAmount || 0) +
                              (each.storeCommissionableAmount || 0)
                          )}
                        </td>
                      </>
                    ) : null}

                    <td>
                      {formatCurrencyValue(each.affiliateCommission || 0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <NoDataDiv />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PayrollAffiliateModal;
