import React, { useState } from "react";
import { Collapse, Card, CardHeader, CardBody } from "reactstrap";

const Questionnaires = ({ leadQuestionResponse = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!leadQuestionResponse?.length) {
    return <></>;
  }

  return (
    <>
      <Card>
        <CardHeader
          className="cursorPointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          Questionnaire
          <i className={`fa fa-angle-${isOpen ? "up" : "down"}`} />
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            {leadQuestionResponse?.map((each, index) => (
              <div key={each._id + index} className="quesAnsWrap">
                <h5>
                  {index + 1}. {each.questionText}
                </h5>
                <p>{each.response}</p>
              </div>
            ))}
          </CardBody>
        </Collapse>
      </Card>
    </>
  );
};

export default Questionnaires;
