import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import SvgIcons from "../../components/SvgIcons";
import { useNavigate, useParams } from "react-router-dom";
import {
  capitalize,
  copyToClipboard,
  errorHandler,
  formatCurrencyValue,
  getFullName,
  openUrlOnNewTab,
} from "../../helper-methods";
import { getOwnerById } from "../../http/http-calls";
import SkeletonLoading from "../../components/SkeletonLoading";
import AffiliateMessagesHistoryTable from "../../components/AffiliateMessagesHistoryTable";
import AffiliatePaymentSummaryTable from "../../components/AffiliatePaymentSummaryTable";
import { useSelector } from "react-redux";
import {
  DRE_PRODUCT_FORM_LINK,
  HOME_FORM_LINK,
  PRODUCT_FORM_LINK,
  WEBINAR_LINK,
} from "../../config";
import ShareSocialMedia from "../../components/ShareSocialMedia";
import OwnerAffiliatesTable from "../../components/OwnerDetailsPage/OwnerAffiliatesTable";
import OwnerClientsTable from "../../components/OwnerDetailsPage/OwnerClientsTable";
import AddOwnerModal from "../../components/modals/AddOwnerModal";
import ShowHideText from "../../components/ShowHideText";
import PlatformEngagementCard from "../../components/PlatformEngagementCard";

const OwnerDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const userCredential = useSelector((state) => state?.userCredential);

  const [data, setData] = useState(null);
  const [platformEngagement, setPlatformEngagement] = useState(null);
  const [messages, setMessages] = useState([]);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [loadingState, setLoadingState] = useState({
    data: false,
  });

  const [addOwnerModal, setAddOwnerModal] = useState({
    isOpen: false,
    data: null,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddOwnerModal = (isOpen = false, data = null) => {
    setAddOwnerModal({ isOpen, data });
  };

  const _getOwnerById = async () => {
    try {
      _manageLoadingState("data", true);

      const res = await getOwnerById(params?.id || userCredential?.user?._id);

      setData(res?.user);
      setMessages(res?.messages || []);
      setPaymentLogs(res?.paymentLogs || []);
      setPlatformEngagement(res?.platformEngagement);

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler({ reason: "User not found" });
      _manageLoadingState("data", false);

      if (params?.id) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    _getOwnerById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <div className="backToPage">
          {params?.id ? (
            <>
              <Button color="link" onClick={() => navigate(-1)}>
                <SvgIcons type={"backArrow"} />
              </Button>
              <h2>Owner Details</h2>
            </>
          ) : (
            <>
              <h2>Owner Home</h2>
            </>
          )}
        </div>
      </div>

      {loadingState?.data && !data ? (
        <SkeletonLoading type="detailPage" />
      ) : (
        <>
          <Row>
            <Col md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Personal Info</CardTitle>
                  <Button
                    color="link"
                    onClick={() => _toggleAddOwnerModal(true, data)}
                  >
                    <SvgIcons type={"edit"} />
                  </Button>
                </CardHeader>
                <CardBody>
                  <div className="infoWrap">
                    <h2>{getFullName(data?.name) || "Anonymous"}</h2>
                    <ul>
                      <li>
                        <span>Referral Code</span>
                        <div className="inotItem">
                          <SvgIcons type={"globe"} />

                          {data?.referCode ? (
                            <>
                              {data?.referCode}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.referCode)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Phone Number</span>
                        <div className="inotItem">
                          <SvgIcons type={"phone"} />

                          {data?.phone ? (
                            <>
                              {data?.countryCode} {data?.phone}
                              <Button
                                color="link"
                                onClick={() =>
                                  copyToClipboard(
                                    `${data?.countryCode}${data?.phone}`
                                  )
                                }
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Weekly Payout</span>
                        <div className="inotItem">
                          <SvgIcons type={"dollar"} />
                          {formatCurrencyValue(data?.weeklyPayout || 0)}/wk
                        </div>
                      </li>
                      <li>
                        <span>Email ID</span>
                        <div className="inotItem">
                          <SvgIcons type={"email"} />

                          {data?.email ? (
                            <>
                              {data?.email}
                              <Button
                                color="link"
                                onClick={() => copyToClipboard(data?.email)}
                              >
                                <SvgIcons type={"copy"} />
                              </Button>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </li>
                      <li>
                        <span>Bank Accounts</span>
                        <div>
                          {data?.bankDetails?.map((bank, bankIndex) => (
                            <div
                              key={`manage_owner_bankDetails_${bank._id}_${bankIndex}`}
                            >
                              <span>{capitalize(bank.name) || "N/A"}</span>

                              {/* account number */}
                              <ShowHideText text={bank.accountNumber} />
                            </div>
                          ))}
                        </div>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {data?.vsl || data?.referCode ? (
              <>
                <Col md={6}>
                  <Card className="cardDesign">
                    <CardHeader>
                      <CardTitle>Links</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="infoWrap">
                        <ul>
                          {data?.referCode ? (
                            <>
                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${PRODUCT_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Product Form
                                  </span>

                                  <ShareSocialMedia
                                    id={`Product_Form`}
                                    link={`${PRODUCT_FORM_LINK}?ref=${data.referCode}`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${DRE_PRODUCT_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    DRE Product Form
                                  </span>

                                  <ShareSocialMedia
                                    id={`DRE_Product_Form_Link`}
                                    link={`${DRE_PRODUCT_FORM_LINK}?ref=${data.referCode}`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${WEBINAR_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Webinar Registration
                                  </span>

                                  <ShareSocialMedia
                                    id={`Webinar_Registration`}
                                    link={`${WEBINAR_LINK}?ref=${data.referCode}`}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `https://us06web.zoom.us/j/6596203934?pwd=Kj5CRdYWyROaQfcbQOZNQ9nAcb1DZm.1`
                                      )
                                    }
                                  >
                                    Webinar Link
                                  </span>

                                  <ShareSocialMedia
                                    id={`Webinar_Link`}
                                    link={`https://us06web.zoom.us/j/6596203934?pwd=Kj5CRdYWyROaQfcbQOZNQ9nAcb1DZm.1`}
                                    isShareOnSocialMedia={false}
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() =>
                                      openUrlOnNewTab(
                                        `${HOME_FORM_LINK}?ref=${data.referCode}`
                                      )
                                    }
                                  >
                                    Website Link
                                  </span>

                                  <ShareSocialMedia
                                    id={`Website_Link`}
                                    link={`${HOME_FORM_LINK}?ref=${data.referCode}`}
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}

                          {data?.vsl ? (
                            <>
                              <li>
                                <div className="inotItem d-flex">
                                  <SvgIcons type={"link"} />

                                  <span
                                    className="cursorPointer"
                                    onClick={() => openUrlOnNewTab(data?.vsl)}
                                  >
                                    VSL
                                  </span>

                                  <Button
                                    color="link"
                                    onClick={() => copyToClipboard(data?.vsl)}
                                  >
                                    <SvgIcons type={"copy"} />
                                  </Button>
                                </div>
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>

                  <PlatformEngagementCard
                    platformEngagement={platformEngagement}
                  />
                </Col>
              </>
            ) : null}
          </Row>

          <Row>
            <Col lg={6} md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Messages History</CardTitle>
                </CardHeader>
                <CardBody>
                  <AffiliateMessagesHistoryTable lists={messages} />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} md={6}>
              <Card className="cardDesign">
                <CardHeader>
                  <CardTitle>Payment Summary</CardTitle>
                </CardHeader>
                <CardBody>
                  <AffiliatePaymentSummaryTable lists={paymentLogs} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {data && (
            <Row>
              <Col sm="12">
                <Card className="cardDesign">
                  <CardHeader>
                    <CardTitle>Affiliates</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <OwnerAffiliatesTable
                      ownerId={params?.id || userCredential?.user?._id}
                      isGetTemplatesList={true}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12">
                <Card className="cardDesign">
                  <CardHeader>
                    <CardTitle>Clients</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <OwnerClientsTable
                      ownerId={params?.id || userCredential?.user?._id}
                      isGetTemplatesList={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}

      <AddOwnerModal
        isOpen={addOwnerModal.isOpen}
        data={addOwnerModal.data}
        toggle={() => _toggleAddOwnerModal()}
        onSuccess={() => _getOwnerById()}
      />
    </>
  );
};

export default OwnerDetailsPage;
