import React, { useEffect, useMemo, useState } from "react";
import CustomTable from "../components/Table/CustomTable";
import { getAllActivites } from "../http/http-calls";
import {
  deepClone,
  errorHandler,
  formatDate,
  getFullName,
} from "../helper-methods";
import { useNavigate } from "react-router-dom";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { useSelector } from "react-redux";
import SvgIcons from "./SvgIcons";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Type", id: "activityType" },
  { label: "Time", id: "createdAt" },
  { label: "Message", id: "message" },
  { label: "By", id: "_createdBy" },
  // { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const ClientMessagesTable = ({ userId }) => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [data, setData] = useState([]);
  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _getAllActivites = async () => {
    try {
      _manageLoadingState("data", true);

      const payload = {
        userId,
        userType: "Lead", // enum=["Lead", "Affiliate"]
      };

      const res = await getAllActivites(payload);

      setData(res.activities?.length ? res.activities : []);
      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("data", false);
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "activityType": {
        return <>{row?.activityType || "N/A"}</>;
      }
      case "message": {
        return (
          <>
            {row.message ? (
              <>
                <div
                  id={`newMessageText_${row._id}`}
                  className="themeColor cursorPointer w-auto d-inline-block pe-3"
                >
                  <SvgIcons type="eye" />
                </div>
                <UncontrolledPopover
                  target={`newMessageText_${row._id}`}
                  trigger="legacy"
                >
                  {row.activityType === "Email" ? (
                    <PopoverBody>
                      <div>
                        Subject:
                        <p>{row.subject}</p>
                      </div>
                      <hr />
                      <div>
                        Body:
                        <div
                          dangerouslySetInnerHTML={{
                            __html: row.message,
                          }}
                        />
                      </div>
                    </PopoverBody>
                  ) : (
                    <PopoverBody>{row.message}</PopoverBody>
                  )}
                </UncontrolledPopover>
              </>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      case "createdAt": {
        return <>{formatDate(row?.createdAt) || "-"}</>;
      }
      case "_createdBy": {
        return <>{getFullName(row?._createdBy?.name) || "N/A"}</>;
      }

      default: {
        return cell;
      }
    }
  };

  useEffect(() => {
    _getAllActivites();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>Messages</h2>

        <Button
          color="primary"
          onClick={() => navigate(`/${userType}/message/${userId}`)}
        >
          Send Message
        </Button>
      </div>

      <CustomTable
        data={data}
        dataCount={data?.length}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={false}
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
      />
    </>
  );
};

export default ClientMessagesTable;
