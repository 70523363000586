import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { editSettingsWebinar } from "../../http/http-calls";
import { errorHandler } from "../../helper-methods";
import { daysOfWeek } from "../../config/helper-config";
import { useSelector } from "react-redux";
import ReactDatetime from "react-datetime";
import moment from "moment";

const EditWebinarSettingsModal = ({
  isOpen,
  data,
  toggle,
  onSuccess = () => {},
  noOfWeek,
}) => {
  const settingsData = useSelector((state) => state?.settingsData);

  const [formFields, setFormFields] = useState({
    event: "Champion Digital Real Estate Webinar",
    webinarDay: "Tuesday",
    webinarTime: "18:00",
    // Intl.DateTimeFormat().resolvedOptions().timeZone
    webinarTimeZone:
      settingsData?.setting?.webinarTimeZone || "America/Los Angeles",
    isCancel: false,
  });
  const [isDirty, setIsDirty] = useState({});

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      event: data?.event || "Champion Digital Real Estate Webinar",
      webinarDay: data?.webinarDay || "Tuesday",
      webinarTime: data?.webinarTime || "18:00",
      // Intl.DateTimeFormat().resolvedOptions().timeZone
      webinarTimeZone:
        settingsData?.setting?.webinarTimeZone || "America/Los Angeles",
      isCancel: data?.isCancel || false,
    });
    setIsDirty({});
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "event":
            case "webinarDay":
            case "webinarTime": {
              if (newFormFields[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    const newFormFields = { ...formFields };

    if (key === "webinarTime") {
      newFormFields[key] = value?._d ? moment(value).format("HH:mm") : "";
      const newIsDirty = {
        [key]: true,
      };
      _validateFormFields({ newFormFields, newIsDirty });
    } else {
      newFormFields[key] = value;
    }

    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const payload = {
        event: newFormFields?.event,
        webinarDay: newFormFields?.webinarDay,
        webinarTime: newFormFields?.webinarTime,
        webinarTimeZone: newFormFields?.webinarTimeZone,
        isCancel: newFormFields?.isCancel,
        noOfWeek,
      };

      await editSettingsWebinar(payload);

      onSuccess(data?._id ? true : false);

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      _setModalState(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => _closeModal()}>
          Update Webinar Settings
        </ModalHeader>

        <ModalBody>
          <Row>
            {/* <Col md={6}>
              <FormGroup>
                <Label>Event</Label>
                <Input
                  placeholder="Enter"
                  value={formFields.event}
                  onChange={(e) => _onChangeFormFields("event", e.target.value)}
                  onBlur={() => _onBlurFormFields("event")}
                  disabled
                  readOnly
                />
                {errors.event ? (
                  <div className="form-error">{errors.event}</div>
                ) : null}
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Label>Scheduled Day</Label>
                <Input
                  type="select"
                  value={formFields.webinarDay}
                  onChange={(e) =>
                    _onChangeFormFields("webinarDay", e.target.value)
                  }
                  onBlur={() => _onBlurFormFields("webinarDay")}
                >
                  {daysOfWeek.map((each) => (
                    <option key={each.value} value={each.value}>
                      {each.label}
                    </option>
                  ))}
                </Input>
                {errors.webinarDay ? (
                  <div className="form-error">{errors.webinarDay}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Scheduled Time</Label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select Date & Time",
                    value: formFields.webinarTime,
                    // onBlur: _onChangeFormFields("webinarTime"),
                  }}
                  value={
                    formFields.webinarTime
                      ? moment(formFields.webinarTime, "HH:mm")
                      : ""
                  }
                  onChange={(e) => _onChangeFormFields("webinarTime", e)}
                  onBlur={() => _onChangeFormFields("webinarTime")}
                  timeConstraints={{
                    minutes: {
                      step: 5,
                    },
                  }}
                  dateFormat={false}
                  timeFormat={"HH:mm"}
                />
                {errors.webinarTime ? (
                  <div className="form-error">{errors.webinarTime}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              {data?.isCancel ? (
                <>
                  <Badge className="outline" color="danger">
                    Event has been canceled.
                  </Badge>
                </>
              ) : (
                <FormGroup check>
                  <Input
                    id={`webinar_event_isCancel`}
                    type="checkbox"
                    checked={formFields.isCancel}
                    onChange={(e) =>
                      _onChangeFormFields("isCancel", e.target.checked)
                    }
                  />
                  <Label check for={`webinar_event_isCancel`}>
                    Cancel Event
                  </Label>
                </FormGroup>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={() => _onSave()}>
            {loading ? <i className="fa fa-spinner fa-spin" /> : null} Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditWebinarSettingsModal;
