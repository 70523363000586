import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  // Label,
  // Input,
  // InputGroup,
  // InputGroupText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
// import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomTable from "../../components/Table/CustomTable";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  getFullName,
  hasPermission,
} from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateLeaderboard, saveLocalFilters } from "../../redux/actions";
import SvgIcons from "../../components/SvgIcons";
import LeaderBoardSettingModal from "../../components/modals/LeaderBoardSettingModal";
import { getLeaderboardSetting } from "../../http/http-calls";
import SkeletonLoading from "../../components/SkeletonLoading";
// import { productTypeConfig } from "../../config/helper-config";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
};

const initialFiltersState = {
  startDate: null,
  endDate: null,
  dateType: "",
  search: "",
  productType: "", // domain, store
};

const LeaderboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchIntervalRef = useRef({ current: null });

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const tableData = useSelector((state) => state?.tableData);
  const localFilters = useSelector((state) => state?.localFilters);

  const [headerKeys, setHeaderKeys] = useState([
    { label: "_id", id: "_id" },
    { label: "Name", id: "name" },
    // { label: "Date", id: "createdAt" },
    // { label: "Product Sales", id: "productSales" },
    { label: "Earnings", id: "earnings" },
    { label: "Site Sales", id: "dealMade" },
    // { label: "Goals Achieved", id: "numberOfGoalAchieved" },
    // { label: "Action", id: "action" },
  ]);
  const [visibilitySettings, setVisibilitySettings] = useState({});
  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [filters, setFilters] = useState(deepClone(initialFiltersState));
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
    setting: false,
  });
  const [filterShow, setFilterShow] = useState(false);
  const [leaderBoardSettingModal, setLeaderBoardSettingModal] = useState({
    isOpen: false,
    data: null,
  });

  const [activeTab, setActiveTab] = useState("1");

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "leaderBoard", action: "view" }),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleLeaderBoardSettingModal = (isOpen = false, data = null) => {
    setLeaderBoardSettingModal({ isOpen, data });
  };

  const _getAndUpdateLeaderboard = async (payload) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "LeaderboardPagePayload",
          value: deepClone(payload),
        })
      );

      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      await getAndUpdateLeaderboard(payload)(dispatch);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateLeaderboard(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateLeaderboard(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAndUpdateLeaderboard(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "LeaderboardPage",
          value: deepClone(newFilters),
        })
      );

      const newDataPayload = deepClone(initialDataPayloadState);

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (
            eachFilterKey === "referredLeadCount" ||
            eachFilterKey === "deals" ||
            eachFilterKey === "goalAchieved"
          ) {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey] === "Active" ? true : false;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAndUpdateLeaderboard(newDataPayload);
    } catch (error) {}
  };

  // eslint-disable-next-line no-unused-vars
  const _onChangeFilters = (key, value) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  // eslint-disable-next-line no-unused-vars
  const _onDatesChange = ({ startDate, endDate, dateType }) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    newFilters.dateType = dateType;

    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _clearFilters = () => {
    _manageLoadingState("filters", true);

    const newFilters = deepClone(initialFiltersState);

    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return <>{getFullName(row?.name) || "Anonymous"}</>;
      }
      case "createdAt": {
        return <>{formatDate(row?.createdAt) || "-"}</>;
      }
      case "productSales": {
        if (filters.productType === "domain") {
          return (
            <>
              {row?.totalDomainSale || 0}{" "}
              {row?.totalDomainSale > 1 ? "Domains" : "Domain"}
            </>
          );
        }
        if (filters.productType === "store") {
          return (
            <>
              {row?.totalStoreSale || 0}{" "}
              {row?.totalStoreSale > 1 ? "Stores" : "Store"}
            </>
          );
        }

        return (
          <>
            {row?.totalDomainSale || 0}{" "}
            {row?.totalDomainSale > 1 ? "Domains" : "Domain"},{" "}
            {row?.totalStoreSale || 0}{" "}
            {row?.totalStoreSale > 1 ? "Stores" : "Store"}
          </>
        );
      }
      case "dealMade": {
        return <>{(row?.totalDomainSale || 0) + (row?.totalStoreSale || 0)}</>;
      }
      case "earnings": {
        return <>{formatCurrencyValue(row?.earnings || 0)}</>;
      }
      case "numberOfGoalAchieved": {
        return <>{row?.goalsCompleted || 0}</>;
      }
      default: {
        return cell;
      }
    }
  };

  const _getLeaderboardSetting = async () => {
    try {
      _manageLoadingState("setting", true);

      const res = await getLeaderboardSetting();

      setVisibilitySettings(res?.visibilitySettings);

      _manageLoadingState("setting", false);

      if (userType === "admin") {
        return;
      }

      const newHeaderKeys = deepClone(headerKeys);
      // [
      //   { label: "_id", id: "_id" }, // 0
      //   { label: "Name", id: "name" }, // 1
      //   { label: "Date", id: "createdAt" }, // 2
      //   { label: "Product Sales", id: "productSales" }, // 3
      //   { label: "Earnings", id: "earnings" }, // 4
      //   { label: "Deals", id: "dealMade" }, // 5
      //   { label: "Goals Achieved", id: "numberOfGoalAchieved" }, // 6
      //   // { label: "Action", id: "action" }, // 7
      // ];

      if (res?.visibilitySettings?.affiliateName === false) {
        const findIndex = newHeaderKeys.findIndex((each) => each.id === "name");
        newHeaderKeys.splice(findIndex, 1);
      }
      if (res?.visibilitySettings?.productSales === false) {
        const findIndex = newHeaderKeys.findIndex(
          (each) => each.id === "productSales"
        );
        newHeaderKeys.splice(findIndex, 1);
      }
      if (res?.visibilitySettings?.earnings === false) {
        const findIndex = newHeaderKeys.findIndex(
          (each) => each.id === "earnings"
        );
        newHeaderKeys.splice(findIndex, 1);
      }
      if (res?.visibilitySettings?.deals === false) {
        const findIndex = newHeaderKeys.findIndex(
          (each) => each.id === "dealMade"
        );
        newHeaderKeys.splice(findIndex, 1);
      }
      if (res?.visibilitySettings?.goalsAchieved === false) {
        const findIndex = newHeaderKeys.findIndex(
          (each) => each.id === "numberOfGoalAchieved"
        );
        newHeaderKeys.splice(findIndex, 1);
      }

      setHeaderKeys(newHeaderKeys);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("setting", false);
    }
  };

  const _initialize = () => {
    try {
      _getLeaderboardSetting();

      if (
        localFilters?.filters?.LeaderboardPage &&
        localFilters?.filters?.LeaderboardPagePayload
      ) {
        setFilters(localFilters.filters.LeaderboardPage);
        setDataPayload(localFilters.filters.LeaderboardPagePayload);
        _getAndUpdateLeaderboard(localFilters.filters.LeaderboardPagePayload);
      } else {
        _onPageChange();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      navigate("/");
    }

    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _toggle = (tab) => {
    if (activeTab === tab) return;

    setActiveTab(tab);

    let newLabel = "Site Sales";

    if (tab === "2") {
      newLabel = "Store Sales";
    }
    if (tab === "3") {
      newLabel = "Sales";
    }

    const newHeaderKeys = deepClone(headerKeys);

    const findSales = newHeaderKeys.find((each) => each.id === "dealMade");

    if (!findSales) return;

    findSales.label = newLabel;

    setHeaderKeys(newHeaderKeys);
  };

  return (
    <>
      <div className="innerHeader">
        <h2>
          Leaderboard{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          {userType === "admin" ? (
            <Button
              color="link"
              className="btn-reset"
              disabled={loadingState?.setting}
              onClick={() =>
                _toggleLeaderBoardSettingModal(true, visibilitySettings)
              }
            >
              <SvgIcons type={"setting"} />
            </Button>
          ) : null}
          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
          <Button
            color="link"
            className="hideShowFilter"
            onClick={() => setFilterShow(!filterShow)}
          >
            <SvgIcons type={"filter"} />
          </Button>
        </div>
      </div>

      {/* <div className={`filterWrapper ${filterShow === true ? "show" : ""}`}>
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchbar">
            <Label>Search</Label>
            <InputGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {loadingState?.search ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <div className="formGroup">
            <Label>Date</Label>
            <CustomDateRangePicker
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_LeaderboardPage"}
              endDateId={"endDateId_LeaderboardPage"}
              onDatesChange={_onDatesChange}
              dateType={filters.dateType}
            />
          </div>

          <div className="formGroup">
            <Label>Product Type</Label>
            <Input
              type="select"
              value={filters.productType}
              name="productType"
              onChange={(e) => _onChangeFilters("productType", e.target.value)}
            >
              <option value="">All</option>
              {productTypeConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </div> */}

      <Nav pills className="customTabs">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              _toggle("1");
            }}
          >
            Site
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              _toggle("2");
            }}
          >
            Store
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              _toggle("3");
            }}
          >
            Sales Amount
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          {userType === "affiliate" && loadingState?.setting ? (
            <SkeletonLoading type="box" borderRadius={10} height={500} />
          ) : (
            <CustomTable
              data={tableData?.leaderboardData}
              dataCount={tableData?.leaderboardDataCount}
              dataPayload={dataPayload}
              onPageChange={_onPageChange}
              onSizeChange={_onSizeChange}
              loading={loadingState?.data}
              isRowSelection={false}
              headerKeys={headerKeys}
              dataFormat={_dataFormat}
              toggleSortBy={_toggleSortBy}
              className="leaderBoardTable"
            />
          )}
        </TabPane>
        <TabPane tabId="2">
          {userType === "affiliate" && loadingState?.setting ? (
            <SkeletonLoading type="box" borderRadius={10} height={500} />
          ) : (
            <CustomTable
              data={tableData?.leaderboardData}
              dataCount={tableData?.leaderboardDataCount}
              dataPayload={dataPayload}
              onPageChange={_onPageChange}
              onSizeChange={_onSizeChange}
              loading={loadingState?.data}
              isRowSelection={false}
              headerKeys={headerKeys}
              dataFormat={_dataFormat}
              toggleSortBy={_toggleSortBy}
              className="leaderBoardTable"
            />
          )}
        </TabPane>
        <TabPane tabId="3">
          {userType === "affiliate" && loadingState?.setting ? (
            <SkeletonLoading type="box" borderRadius={10} height={500} />
          ) : (
            <CustomTable
              data={tableData?.leaderboardData}
              dataCount={tableData?.leaderboardDataCount}
              dataPayload={dataPayload}
              onPageChange={_onPageChange}
              onSizeChange={_onSizeChange}
              loading={loadingState?.data}
              isRowSelection={false}
              headerKeys={headerKeys}
              dataFormat={_dataFormat}
              toggleSortBy={_toggleSortBy}
              className="leaderBoardTable"
            />
          )}
        </TabPane>
      </TabContent>

      <LeaderBoardSettingModal
        isOpen={leaderBoardSettingModal.isOpen}
        data={leaderBoardSettingModal.data}
        toggle={() => _toggleLeaderBoardSettingModal()}
        onSuccess={() => _getLeaderboardSetting()}
      />
    </>
  );
};

export default LeaderboardPage;
