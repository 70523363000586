import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { updateSettings } from "../../http/http-calls";
import { deepClone, errorHandler, showToast } from "../../helper-methods";
import { settingConfig } from "../../config/helper-config";

const initialFormFields = {
  cost: "",
  commissionableValue: "",
  affiliateShare: "",
  ownerShare: "",
  ownersPartnerDistribution: "3",
  ownersSalesCommission: "40",
};

const EditSettingsModal = ({
  isOpen,
  data,
  toggle,
  onSuccess = () => {},
  heading = "Store",
}) => {
  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [isDirty, setIsDirty] = useState({});

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      cost: data?.cost || data?.cost === 0 ? String(data?.cost) : "",
      commissionableValue:
        data?.commissionableValue || data?.commissionableValue === 0
          ? String(data?.commissionableValue)
          : "",
      affiliateShare:
        data?.affiliateShare || data?.affiliateShare === 0
          ? String(data?.affiliateShare)
          : "",
      ownerShare:
        data?.ownerShare || data?.ownerShare === 0
          ? String(data?.ownerShare)
          : "",
      ownersPartnerDistribution:
        data?.ownersPartnerDistribution || data?.ownersPartnerDistribution === 0
          ? String(data?.ownersPartnerDistribution)
          : "3",
      ownersSalesCommission:
        data?.ownersSalesCommission || data?.ownersSalesCommission === 0
          ? String(data?.ownersSalesCommission)
          : "40",
    });
    setIsDirty({});

    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "cost": {
              if (newFormFields[key]?.length) {
                if (!isNaN(newFormFields[key]) && newFormFields[key] > 0) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*It must be greater than 0`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "commissionableValue": {
              if (newFormFields[key]?.length) {
                if (!isNaN(newFormFields[key]) && newFormFields[key] > 0) {
                  if (
                    +newFormFields.cost &&
                    +newFormFields.cost < +newFormFields[key]
                  ) {
                    newErrors[
                      key
                    ] = `*Commissionable Value must be less than cost`;
                    isFormValid = false;
                  } else {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  }
                } else {
                  newErrors[key] = `*It must be greater than 0`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "affiliateShare": {
            //   if (newFormFields[key]?.length) {
            //     if (
            //       !isNaN(newFormFields[key]) &&
            //       newFormFields[key] >= 0 &&
            //       newFormFields[key] <= 100
            //     ) {
            //       delete newErrors[key];
            //       newIsDirty[key] = false;
            //     } else {
            //       newErrors[
            //         key
            //       ] = `*Invalid value % must be between 0 and 100.`;
            //       isFormValid = false;
            //     }
            //   } else {
            //     newErrors[key] = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            // case "ownerShare": {
            //   if (newFormFields[key]?.length) {
            //     if (
            //       !isNaN(newFormFields[key]) &&
            //       newFormFields[key] >= 0 &&
            //       newFormFields[key] <= 100
            //     ) {
            //       delete newErrors[key];
            //       newIsDirty[key] = false;
            //     } else {
            //       newErrors[
            //         key
            //       ] = `*Invalid value % must be between 0 and 100.`;
            //       isFormValid = false;
            //     }
            //   } else {
            //     newErrors[key] = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      (key === "cost" || key === "commissionableValue") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1]?.length > 2) ||
        +value < 0 ||
        String(value)?.length > 8)
    ) {
      return;
    }

    if (
      (key === "affiliateShare" || key === "ownerShare") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1]?.length > 2) ||
        +value < 0 ||
        (value.includes(".") && String(value)?.length > 5) ||
        (!value.includes(".") && String(value)?.length > 3))
    ) {
      return;
    }

    const newFormFields = { ...formFields };

    newFormFields[key] = value;

    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      const totalPercentage =
        +newFormFields?.affiliateShare + +newFormFields?.ownerShare;

      if (totalPercentage > 100) {
        showToast("The total commission % must be less than 100%.");
        return;
      }

      setLoading(true);

      const payload = {};

      settingConfig[heading].forEach((each) => {
        payload[each.name] = +newFormFields[each.value];
      });

      await updateSettings(payload);

      onSuccess(data?._id ? true : false);

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      _setModalState(data);
    }
  }, [isOpen, data]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => _closeModal()}>{heading}</ModalHeader>

        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Cost</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    placeholder="Enter"
                    value={formFields.cost}
                    onChange={(e) =>
                      _onChangeFormFields("cost", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("cost")}
                  />
                </InputGroup>
                {errors["cost"] ? (
                  <div className="form-error">{errors["cost"]}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Commissionable Value</Label>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    placeholder="Enter"
                    value={formFields.commissionableValue}
                    onChange={(e) =>
                      _onChangeFormFields("commissionableValue", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("commissionableValue")}
                  />
                </InputGroup>
                {errors["commissionableValue"] ? (
                  <div className="form-error">
                    {errors["commissionableValue"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            {/* <Col md={6}>
              <FormGroup>
                <Label>Affiliate Commission</Label>
                <InputGroup>
                  <Input
                    placeholder="Enter"
                    value={formFields.affiliateShare}
                    onChange={(e) =>
                      _onChangeFormFields("affiliateShare", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("affiliateShare")}
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
                {errors["affiliateShare"] ? (
                  <div className="form-error">{errors["affiliateShare"]}</div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Owner's Partner Distribution</Label>
                <InputGroup>
                  <Input
                    placeholder="Enter"
                    value={formFields.ownersPartnerDistribution}
                    disabled
                    readOnly
                    // onChange={(e) =>
                    //   _onChangeFormFields("ownersPartnerDistribution", e.target.value)
                    // }
                    // onBlur={() => _onBlurFormFields("ownersPartnerDistribution")}
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
                {errors["ownersPartnerDistribution"] ? (
                  <div className="form-error">
                    {errors["ownersPartnerDistribution"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Owner's Sales Commission</Label>
                <InputGroup>
                  <Input
                    placeholder="Enter"
                    value={formFields.ownersSalesCommission}
                    disabled
                    readOnly
                    // onChange={(e) =>
                    //   _onChangeFormFields("ownersSalesCommission", e.target.value)
                    // }
                    // onBlur={() => _onBlurFormFields("ownersSalesCommission")}
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
                {errors["ownersSalesCommission"] ? (
                  <div className="form-error">
                    {errors["ownersSalesCommission"]}
                  </div>
                ) : null}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Owner Commission</Label>
                <InputGroup>
                  <Input
                    placeholder="Enter"
                    value={formFields.ownerShare}
                    onChange={(e) =>
                      _onChangeFormFields("ownerShare", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("ownerShare")}
                  />
                  <InputGroupText>%</InputGroupText>
                </InputGroup>
                {errors["ownerShare"] ? (
                  <div className="form-error">{errors["ownerShare"]}</div>
                ) : null}
              </FormGroup>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={() => _onSave()}>
            {loading ? <i className="fa fa-spinner fa-spin" /> : null} Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditSettingsModal;
