import React, { useMemo } from "react";
import SvgIcons from "./SvgIcons";
import CustomTooltip from "./custom/CustomTooltip";
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const PlatformEngagementCard = ({ platformEngagement = {} }) => {
  const totalCount = useMemo(() => {
    let count = 0;

    if (platformEngagement?.whatsapp) {
      count += +platformEngagement.whatsapp;
    }
    if (platformEngagement?.telegram) {
      count += +platformEngagement.telegram;
    }
    if (platformEngagement?.instagram) {
      count += +platformEngagement.instagram;
    }
    if (platformEngagement?.facebook) {
      count += +platformEngagement.facebook;
    }
    if (platformEngagement?.twitter) {
      count += +platformEngagement.twitter;
    }
    if (platformEngagement?.linkedin) {
      count += +platformEngagement.linkedin;
    }

    return count;
  }, [platformEngagement]);

  const _getPercentage = (key = "") => {
    return platformEngagement?.[key]
      ? Math.floor((+platformEngagement[key] / +totalCount) * 100)
      : 0;
  };

  return (
    <Card className="cardDesign">
      <CardHeader>
        <CardTitle>Platform Engagement</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="infoWrap">
          <ul>
            <li className="d-flex">
              <Button color="link" id={`whatsapp_Platform_Engagement`}>
                <SvgIcons type="whatsapp" />
                <CustomTooltip
                  text={`Whatsapp`}
                  target={`whatsapp_Platform_Engagement`}
                />
              </Button>
              <span>{_getPercentage("whatsapp")}%</span>
            </li>
            <li className="d-flex">
              <Button color="link" id={`telegram_Platform_Engagement`}>
                <SvgIcons type="telegram" />
                <CustomTooltip
                  text={`Telegram`}
                  target={`telegram_Platform_Engagement`}
                />
              </Button>
              <span>{_getPercentage("telegram")}%</span>
            </li>
            <li className="d-flex">
              <Button color="link" id={`instagram_Platform_Engagement`}>
                <SvgIcons type="instagram" />
                <CustomTooltip
                  text={`Instagram`}
                  target={`instagram_Platform_Engagement`}
                />
              </Button>
              <span>{_getPercentage("instagram")}%</span>
            </li>
            <li className="d-flex">
              <Button color="link" id={`facebook_Platform_Engagement`}>
                <SvgIcons type="facebook" />
                <CustomTooltip
                  text={`Facebook`}
                  target={`facebook_Platform_Engagement`}
                />
              </Button>
              <span>{_getPercentage("facebook")}%</span>
            </li>
            <li className="d-flex">
              <Button color="link" id={`twitter_Platform_Engagement`}>
                <SvgIcons type="twitter" />
                <CustomTooltip
                  text={`Twitter`}
                  target={`twitter_Platform_Engagement`}
                />
              </Button>
              <span>{_getPercentage("twitter")}%</span>
            </li>
            <li className="d-flex">
              <Button color="link" id={`linkedin_Platform_Engagement`}>
                <SvgIcons type="linkedin" />
                <CustomTooltip
                  text={`LinkedIn`}
                  target={`linkedin_Platform_Engagement`}
                />
              </Button>
              <span>{_getPercentage("linkedin")}%</span>
            </li>
            {/* <li className="d-flex">
            <Button
              color="link"
              id={`direct_Platform_Engagement`}
            >
              <SvgIcons type="share" />
              <CustomTooltip
                text={`Direct`}
                target={`direct_Platform_Engagement`}
              />
            </Button>
            <span>{platformEngagement?.direct || 0}%</span>
          </li> */}
          </ul>
        </div>
      </CardBody>
    </Card>
  );
};

export default PlatformEngagementCard;
