import React, { useEffect, useMemo, useState } from "react";

import SettingsCard from "../../components/SettingsPage/SettingsCard";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { errorHandler } from "../../helper-methods";
import { getAndUpdateSettingsData } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import SettingsWebinarCard from "../../components/SettingsPage/SettingsWebinarCard";

const SettingsPage = () => {
  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state?.settingsData);

  const [loading, setLoading] = useState(false);

  const storeData = useMemo(() => {
    return {
      cost: settingsData?.setting?.storePerPrice || 0,
      commissionableValue: settingsData?.setting?.storeCommissionableValue || 0,
      affiliateShare: settingsData?.setting?.storeAffiliateCommision || 0,
      ownerShare: settingsData?.setting?.storeOwnerCommision || 0,
    };
  }, [settingsData]);

  const siteData = useMemo(() => {
    return {
      cost: settingsData?.setting?.domainPerPrice || 0,
      commissionableValue:
        settingsData?.setting?.domainCommissionableValue || 0,
      affiliateShare: settingsData?.setting?.domainAffiliateCommision || 0,
      ownerShare: settingsData?.setting?.domainOwnerCommision || 0,
    };
  }, [settingsData]);

  const _getAndUpdateSettingsData = async () => {
    try {
      setLoading(true);
      await getAndUpdateSettingsData()(dispatch);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getAndUpdateSettingsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Settings {loading ? <i className="fa fa-spinner fa-spin" /> : null}
        </h2>
      </div>

      <Row>
        <Col md="6">
          <Card className="my-2">
            <CardHeader>
              <CardTitle>Commissionable Settings</CardTitle>
            </CardHeader>

            <CardBody>
              <SettingsCard
                heading={"Store"}
                data={storeData}
                getAndUpdateSettingsData={_getAndUpdateSettingsData}
                className="my-2"
              />
              <SettingsCard
                heading={"Domain"}
                data={siteData}
                getAndUpdateSettingsData={_getAndUpdateSettingsData}
                className="my-2"
              />
            </CardBody>
          </Card>
        </Col>

        <Col md="6">
          <SettingsWebinarCard
            getAndUpdateSettingsData={_getAndUpdateSettingsData}
          />
        </Col>
      </Row>
    </>
  );
};

export default SettingsPage;
