import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import SvgIcons from "../SvgIcons";
import { RegexConfig } from "../../config/RegexConfig";
import {
  capitalize,
  deepClone,
  errorHandler,
  getFullName,
  openUrlOnNewTab,
  showToast,
  splitFullName,
  uploadFileOnServer,
} from "../../helper-methods";
import { createAffiliate, updateAffiliate } from "../../http/http-calls";
import { useDispatch, useSelector } from "react-redux";
import { statesConfig } from "../../config/statesConfig";
import { countryCodesConfig } from "../../config/countryCodesConfig";
import SearchableInput from "../SearchableInput";
import { getAndUpdateVendorsList } from "../../redux/actions";

const initialFormFields = {
  name: "",
  email: "",
  phone: "",
  countryCode: "+1",
  referCode: "",
  owner: "",

  vendorId: "",

  siteCommission: "20",
  storeCommission: "22.50",

  addressStreet: "",
  addressCity: "",
  addressState: "", // state
  addressZip: "",
  addressCountry: "US",
};

const initialVslData = {
  uploadData: null,
  previewBlob: null,
  type: "video",
  url: null,
};

const AddAffiliateModal = ({ isOpen, data, toggle, onSuccess = () => { } }) => {
  const dispatch = useDispatch();

  const userCredential = useSelector((state) => state?.userCredential);
  const filtersList = useSelector((state) => state?.filtersList);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const [vslData, setVslData] = useState(deepClone(initialVslData));
  const [formFields, setFormFields] = useState(deepClone(initialFormFields));
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setFormFields({
      name: data?.name ? getFullName(data?.name) : "",
      email: data?.email || "",
      phone: data?.phone || "",
      countryCode: "+1",
      referCode: data?.referCode || "",
      owner: data?._owner?._id
        ? { label: getFullName(data?._owner?.name), value: data?._owner?._id }
        : "",

      vendorId: "",

      siteCommission:
        data?.siteCommission || data?.siteCommission === 0
          ? String(data?.siteCommission)
          : "20",
      storeCommission:
        data?.storeCommission || data?.storeCommission === 0
          ? String(data?.storeCommission)
          : "22.50",

      addressStreet: data?.address?.Line1 || "",
      addressCity: data?.address?.City || "",
      addressState: data?.address?.CountrySubDivisionCode || "", // state
      addressZip: data?.address?.PostalCode || "",
      addressCountry: data?.address?.Country || "US",
    });
    setIsDirty({});
    setVslData({
      uploadData: null,
      previewBlob: null,
      type: "video",
      url: data?.vsl || null,
    });
    setErrors({});
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _validateFormFields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "siteCommission":
            case "storeCommission": {
              if (userType === "admin") {
                if (newFormFields[key]?.trim().length) {
                  if (
                    isNaN(newFormFields[key]) ||
                    +newFormFields[key] < 0 ||
                    +newFormFields[key] > 100
                  ) {
                    newErrors[key] = `*Must be integer between 0 and 100`;
                    isFormValid = false;
                  } else {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              } else {
                delete newErrors[key];
                newIsDirty[key] = false;
              }
              break;
            }
            case "email": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;

                  if (userType === "admin" && !data?._id) {
                    const findQuickBooksAccount = filtersList.vendorsList.find(
                      (each) => each.email === newFormFields[key]?.trim()
                    );
                    if (findQuickBooksAccount) {
                      newFormFields.vendorId = {
                        ...findQuickBooksAccount,
                        label: `${capitalize(
                          findQuickBooksAccount.displayName
                        )} (${findQuickBooksAccount.email})`,
                        value: findQuickBooksAccount.vendorId,
                      };
                      setFormFields(newFormFields);
                    }
                  }
                } else {
                  newErrors[key] = `*Invalid ${key}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (newFormFields[key]?.trim().length) {
                if (
                  RegexConfig[key].test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = `*Invalid ${key}`;
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "name": {
              if (newFormFields[key]?.length) {
                if (
                  newFormFields[key]?.length >= 2 &&
                  newFormFields[key]?.length <= 50
                ) {
                  if (
                    RegexConfig.nameWithoutSpecialCharactersAndNumber.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Invalid ${key}`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] =
                    "*Please enter a value between 2 and 50 characters.";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "referCode": {
              if (userType === "admin") {
                if (newFormFields[key]?.length) {
                  if (
                    RegexConfig.username.test(
                      String(newFormFields[key]).toLowerCase()
                    )
                  ) {
                    delete newErrors[key];
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = `*Invalid code`;
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              } else {
                delete newErrors[key];
                newIsDirty[key] = false;
              }
              break;
            }
            case "addressCity":
            case "addressState": {
              if (newFormFields[key]?.trim().length) {
                if (
                  newFormFields[key]?.trim().length > 1 &&
                  newFormFields[key]?.trim().length < 50 &&
                  RegexConfig.alphanumericMultiWord.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Minimum 2 characters & Maximum 50 characters. Number & special character not allowed except -.&,'/";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "addressStreet": {
              if (newFormFields[key]?.trim().length) {
                if (
                  newFormFields[key]?.trim().length > 1 &&
                  newFormFields[key]?.trim().length < 100 &&
                  RegexConfig.alphanumericMultiWord.test(
                    String(newFormFields[key]).toLowerCase()
                  )
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] =
                    "*Minimum 2 characters & Maximum 100 characters & number. Special character not allowed except -.&,'/";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "addressZip": {
              if (newFormFields[key]?.trim().length) {
                if (
                  !isNaN(newFormFields[key]?.trim()) &&
                  newFormFields[key]?.trim().length === 5
                ) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Must be 5 number";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "addressCountry": {
              if (newFormFields[key]?.trim().length) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "owner": {
              if (data?._id || newFormFields[key]) {
                newErrors[key] = null;
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onChangeFormFields = (key, value) => {
    if (
      key === "phone" &&
      (isNaN(value) ||
        value.includes(".") ||
        +value < 0 ||
        String(value)?.length > 10)
    ) {
      return;
    }
    if (
      (key === "siteCommission" || key === "storeCommission") &&
      (isNaN(value) ||
        (value.includes(".") && value.split(".")[1]?.length > 2) ||
        +value < 0 ||
        (!value.includes(".") && String(value)?.length > 3))
    ) {
      return;
    }

    const newFormFields = { ...formFields };

    if (key === "addressCountry") {
      newFormFields.addressState = "";
    }

    if (key === "vendorId") {
      newFormFields.email = value?.email || "";
    }

    newFormFields[key] = value;

    setFormFields(newFormFields);

    const newErrors = { ...errors };
    if (newErrors[key]) {
      newErrors[key] = false;
      setErrors(newErrors);
    }
  };

  const _onBlurFormFields = (key) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;

    if (key === "vendorId") {
      newIsDirty.email = true;
    }

    _validateFormFields({ newFormFields, newIsDirty });
  };

  const _resetVslData = () => {
    setVslData(deepClone(initialVslData));
  };

  // eslint-disable-next-line no-unused-vars
  const _onChangeFile = async (event) => {
    try {
      if (!event?.target?.files?.length) {
        return;
      }

      const file = event?.target?.files?.[0];
      const fileType = file.type.split("/")[0];

      if (fileType !== "video") {
        showToast("Only Video file is allowed", "error");
        return;
      }

      const previewBlob = URL.createObjectURL(file);

      setVslData({
        uploadData: file,
        previewBlob,
        type: fileType,
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onSave = async (e) => {
    try {
      if (e) e.preventDefault();

      const newFormFields = { ...formFields };
      const newVslData = { ...vslData };
      const newIsDirty = { ...isDirty };
      Object.keys(newFormFields).forEach((key) => {
        newIsDirty[key] = true;
      });

      const isFormValid = await _validateFormFields({
        newFormFields,
        newIsDirty,
      });

      if (!isFormValid) {
        return;
      }

      setLoading(true);

      const { firstName, lastName } = splitFullName(
        capitalize(newFormFields?.name?.trim())
      );

      const payload = {
        firstName,
        lastName,
        email: newFormFields?.email?.trim(),
        phone: newFormFields?.phone?.trim(),
        countryCode: newFormFields?.countryCode?.trim(),
        referCode: newFormFields?.referCode?.trim(),

        address: {
          Line1: newFormFields?.addressStreet?.trim(),
          City: newFormFields?.addressCity?.trim(),
          CountrySubDivisionCode: newFormFields?.addressState?.trim(), // state
          PostalCode: newFormFields?.addressZip?.trim(),
          Country: newFormFields?.addressCountry?.trim() || "US",
        },

        vsl: newVslData?.url || "",
      };

      if (userType === "admin") {
        payload.siteCommission = +newFormFields?.siteCommission;
        payload.storeCommission = +newFormFields?.storeCommission;

        if (newFormFields.vendorId?.value) {
          payload.vendorId = newFormFields.vendorId?.value;
        }
      }

      if (newFormFields?.owner?.value) {
        payload.owner = newFormFields.owner.value;
      }

      if (newVslData?.uploadData) {
        const uploadFileOnServerRes = await uploadFileOnServer([
          { ...newVslData },
        ]);
        payload.vsl = uploadFileOnServerRes?.[0]?.url;
        newVslData.url = uploadFileOnServerRes?.[0]?.url;
        newVslData.uploadData = null;
        setVslData(newVslData);
      }

      if (data?._id) {
        await updateAffiliate({ id: data?._id, payload });
      } else {
        await createAffiliate(payload);
      }

      onSuccess();

      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _getAndUpdateVendorsList = () => {
    try {
      dispatch(getAndUpdateVendorsList());
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _setModalState(data);
    } else if (isOpen) {
      _getAndUpdateVendorsList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <Modal isOpen={isOpen} scrollable centered size={"lg"}>
      <ModalHeader toggle={() => _closeModal()}>
        {data?._id ? "Update" : "Add"}
      </ModalHeader>

      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                placeholder="Enter"
                value={formFields.name}
                onChange={(e) => _onChangeFormFields("name", e.target.value)}
                onBlur={() => _onBlurFormFields("name")}
              />
              {errors["name"] ? (
                <div className="form-error">{errors["name"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone Number</Label>
              <InputGroup>
                <InputGroupText>+1</InputGroupText>
                <Input
                  placeholder="Enter"
                  value={formFields.phone}
                  onChange={(e) => _onChangeFormFields("phone", e.target.value)}
                  onBlur={() => _onBlurFormFields("phone")}
                />
              </InputGroup>
              {errors["phone"] ? (
                <div className="form-error">{errors["phone"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          {userType === "admin" && !data?._id ? (
            <Col md={6}>
              <FormGroup>
                <Label>QB Vendor Account</Label>
                <SearchableInput
                  options={filtersList?.vendorsList}
                  value={formFields.vendorId}
                  onChange={(value) => _onChangeFormFields("vendorId", value)}
                  onBlur={() => _onBlurFormFields("vendorId")}
                  isClearable
                  menuIsOpen={true}
                />
              </FormGroup>
            </Col>
          ) : null}

          <Col md={6}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                placeholder="Enter"
                value={formFields.email}
                onChange={(e) => _onChangeFormFields("email", e.target.value)}
                onBlur={() => _onBlurFormFields("email")}
              />
              {errors["email"] ? (
                <div className="form-error">{errors["email"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <Label>Referral Code</Label>
            <FormGroup>
              <Input
                placeholder="Enter"
                value={formFields.referCode}
                onChange={(e) =>
                  _onChangeFormFields("referCode", e.target.value)
                }
                onBlur={() => _onBlurFormFields("referCode")}
                disabled={loading || userType !== "admin"}
              />
              {errors["referCode"] ? (
                <div className="form-error">{errors["referCode"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          {userType === "admin" ? (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Domain Commission</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      value={formFields.siteCommission}
                      onChange={(e) =>
                        _onChangeFormFields("siteCommission", e.target.value)
                      }
                      onBlur={() => _onBlurFormFields("siteCommission")}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                  {errors["siteCommission"] ? (
                    <div className="form-error">{errors["siteCommission"]}</div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Store Commission</Label>
                  <InputGroup>
                    <Input
                      placeholder="Enter"
                      value={formFields.storeCommission}
                      onChange={(e) =>
                        _onChangeFormFields("storeCommission", e.target.value)
                      }
                      onBlur={() => _onBlurFormFields("storeCommission")}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                  {errors["storeCommission"] ? (
                    <div className="form-error">
                      {errors["storeCommission"]}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </>
          ) : null}

          <Col md={6}>
            <Label>Owner</Label>
            <FormGroup>
              <SearchableInput
                options={filtersList?.ownerList}
                value={formFields.owner}
                onChange={(value) => _onChangeFormFields("owner", value)}
              />
              {errors["owner"] ? (
                <div className="form-error">{errors["owner"]}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Street Address</Label>
              <Input
                placeholder="Enter"
                value={formFields.addressStreet}
                onChange={(e) =>
                  _onChangeFormFields("addressStreet", e.target.value)
                }
                onBlur={() => _onBlurFormFields("addressStreet")}
              />
              {errors["addressStreet"] ? (
                <div className="form-error">{errors["addressStreet"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>City</Label>
              <Input
                placeholder="Enter"
                value={formFields.addressCity}
                onChange={(e) =>
                  _onChangeFormFields("addressCity", e.target.value)
                }
                onBlur={() => _onBlurFormFields("addressCity")}
              />
              {errors["addressCity"] ? (
                <div className="form-error">{errors["addressCity"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <Label>State</Label>
            <div className="d-flex">
              <>
                {formFields.addressCountry === "US" ||
                  formFields.addressCountry === "IN" ||
                  formFields.addressCountry === "MX" ? (
                  <Input
                    type="select"
                    value={formFields.addressState}
                    name="addressState"
                    onChange={(e) =>
                      _onChangeFormFields("addressState", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("addressState")}
                  >
                    <option value="">Select</option>
                    {statesConfig[formFields.addressCountry].map((each) => (
                      <option key={each.code} value={each.code}>
                        {each.name}
                      </option>
                    ))}
                  </Input>
                ) : (
                  <Input
                    placeholder="Enter"
                    value={formFields.addressState}
                    name="addressState"
                    onChange={(e) =>
                      _onChangeFormFields("addressState", e.target.value)
                    }
                    onBlur={() => _onBlurFormFields("addressState")}
                  />
                )}
              </>
            </div>
            {errors.addressState && (
              <div className="form-error">{errors.addressState}</div>
            )}
          </Col>

          <Col md={6}>
            <Label>Zip</Label>
            <FormGroup>
              <Input
                placeholder="Enter"
                value={formFields.addressZip}
                onChange={(e) =>
                  _onChangeFormFields("addressZip", e.target.value)
                }
                onBlur={() => _onBlurFormFields("addressZip")}
              />
              {errors["addressZip"] ? (
                <div className="form-error">{errors["addressZip"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup>
              <Label>Country</Label>
              <Input
                type="select"
                value={formFields.addressCountry}
                name="addressCountry"
                onChange={(e) =>
                  _onChangeFormFields("addressCountry", e.target.value)
                }
                onBlur={() => _onBlurFormFields("addressCountry")}
              >
                <option value="">Select</option>
                {countryCodesConfig.map((each) => (
                  <option key={each.code} value={each.code}>
                    {each.name}
                  </option>
                ))}
              </Input>
              {errors["addressCountry"] ? (
                <div className="form-error">{errors["addressCountry"]}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            {/* {userType === "admin" && (
              <Label className="uploadButton">
                <Input
                  type="file"
                  accept="video/*"
                  value=""
                  onChange={(e) => _onChangeFile(e)}
                  disabled={loading}
                />
                <span>Upload VSL</span>
              </Label>
            )} */}

            {vslData?.url || vslData?.previewBlob ? (
              <div className="uploadPreview">
                <span
                  className="cursorPointer"
                  onClick={() =>
                    openUrlOnNewTab(vslData?.url || vslData?.previewBlob)
                  }
                >
                  <SvgIcons type="uploadVideo" />
                </span>

                {userType === "admin" && (
                  <div
                    className="remove"
                    disabled={loading}
                    onClick={() => _resetVslData()}
                  >
                    <SvgIcons type="remove" />
                  </div>
                )}
              </div>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button color="primary" disabled={loading} onClick={() => _onSave()}>
          {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
          {data?._id ? "Update" : "Add"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAffiliateModal;
