import { createSlice } from "@reduxjs/toolkit";
import { clearAllSettingsData, getAndUpdateSettingsData } from "../actions";

const initialState = {
  setting: {},
};

const settingsDataSlice = createSlice({
  name: "settingsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAndUpdateSettingsData.pending, (state) => {
        state.loading = true;
      })

      .addCase(getAndUpdateSettingsData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.setting = data;
      })

      .addCase(getAndUpdateSettingsData.rejected, (state) => {
        state.loading = false;
      })

      .addCase(clearAllSettingsData, (state, action) => {
        state.setting = {};
      });
  },
});

export const settingsDataReducer = settingsDataSlice.reducer;
