import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  // uploadFile,
  makeDeleteRequest,
  // uploadFileMultiPart
} from "./http-service";
import { BASE_URL } from "../config/index";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/login`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/forgotpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const requestInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * @param {string} platform - google or facebook
 * @param {object} payload - {accessToken: google or facefook response token}
 * @returns
 */
export const socialLogin = (platform, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/${platform}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * used for check availability of phone or email or username
 *
 * @param {string} payload - phone or email or username
 * @returns
 */
export const checkAvailability = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/unique`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendEmailTextToUsers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/email`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAffiliates = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/affiliates`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllReferredClients = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/affiliate/referredClients/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createAffiliate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/affiliate`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAffiliate = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/affiliate/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAffiliateById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/affiliate/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllClients = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/clients`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClient = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/client/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllActivites = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/activities`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllUsersList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/affiliateList`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllClientNotes = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/notes/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createClientNotes = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/note`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateClientNotes = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/note/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteClientNotes = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/note/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllTemplates = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/templates`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTemplateById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/template/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createTemplate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/template`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateTemplate = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/template/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteTemplate = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/template/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllLeaderboard = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/leaderboard`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllGoals = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/goals`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createGoal = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/goal`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/faq-topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getTopicById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const addTopic = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/faq-topic`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const editTopic = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/faq-topic/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const deleteTopic = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const rearrangeTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/rearrange/topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createTeamMember = (memberData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/team-member`, true, memberData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const editTeamMember = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/team-member/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const getAllTeamMembers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/all/team-members`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const getTeamMemberById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/team-member/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const getAllTemplatesList = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/templateList`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLeaderboardSetting = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/get/visibility/setting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const updateLeaderboardSetting = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/edit/visibility/setting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllAffiliateGroups = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/affiliate-groups`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("call error: ", e);
        reject(e);
      });
  });
};

export const createAffiliateGroup = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/affiliate-group`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateAffiliateGroup = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/affiliate-group/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllGroupList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/groupList`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 *
 * @param {object} data
 * @param {string} data.id
 * @param {object} data.payload
 * @param {number} data.payload.watchedTill
 * @param {boolean} data.payload.isCompleted
 * @param {boolean} data.payload.isStarted
 * @returns
 */
export const maintainWatchlist = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/update/watchlist/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const sendSupportEmail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/support`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getAllOwnerAffiliates = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/owner/referredAffiliates/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllOwnerClients = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/owner/referredClients/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllOwnerList = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/owners/dropdown`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllOwnersData = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/owners/list`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createOwner = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/owner`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateOwner = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/owner/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getOwnerById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/owner/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSettingsData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/fetch/setting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateSettings = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/edit/setting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllPayrollTransactions = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/lastweek/transactions`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllPayrollSummary = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/payrolls`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendPayrollEmail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/mail/summary`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAndSkipTransaction = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/skip/transaction/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const generatePayroll = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/payroll`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const markCompletedPayroll = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/mark/completed/payroll`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getPaymentHistoryById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/payroll/history/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllVendorsList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/vendorslist`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editSettingsWebinar = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/edit/setting/webinar`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
