import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";
import {
  loaderDataReducer,
  userCredentialReducer,
  tableDataReducer,
  localFiltersReducer,
  filtersListReducer,
  settingsDataReducer,
} from "./reducers";
import { createLogger } from "redux-logger";

const rootReducer = combineReducers({
  userCredential: userCredentialReducer,
  loaderData: loaderDataReducer,
  localFilters: localFiltersReducer,
  tableData: tableDataReducer,
  filtersList: filtersListReducer,
  settingsData: settingsDataReducer,
});

const persistConfig = {
  key: "root",
  storage,
  keyPrefix: "",
  stateReconciler: hardSet,
  blacklist: ["loaderData"],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const logger = createLogger();

// const devTools = process.env.REACT_APP_BACKEND_ENV === "prod" ? false : true;
const devTools = false;

export const store = configureStore({
  reducer: pReducer,
  middleware: (getDefaultMiddleware) =>
    devTools
      ? getDefaultMiddleware({ serializableCheck: false }).concat([logger])
      : getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
