import React, { useMemo } from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import NoDataDiv from "../NoDataDiv";
import { formatCurrencyValue, getFullName } from "../../helper-methods";

const PayrollOwnersModal = ({
  isOpen = false,
  data = [],
  toggle = () => {},
  payrollInfo = "",
}) => {
  const totalEarning = useMemo(() => {
    if (!data || !data?.length) return 0;

    return data.reduce((acc, each) => acc + (each.ownerCommission || 0), 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const _closeModal = () => {
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        className="payRollModal"
        size="xl"
      >
        <ModalHeader toggle={() => _closeModal()}>
          {(payrollInfo === "affiliateSales" ||
            payrollInfo === "ownerSales") && <>Sales</>}
          {payrollInfo === "ownerSites" && <>Sites</>}
          {payrollInfo === "ownerStore" && <>Store</>}

          <div className="fs-14">
            Total Earning: {formatCurrencyValue(totalEarning)}
          </div>
        </ModalHeader>

        <ModalBody>
          {data?.length ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    {payrollInfo === "ownerSales" ? "Owner" : "Affiliate"} Name
                  </th>

                  {(payrollInfo === "affiliateSales" ||
                    payrollInfo === "ownerSales") && (
                    <>
                      <th>Sales</th>
                      <th>Sites</th>
                      <th>Stores</th>
                    </>
                  )}

                  {payrollInfo === "ownerSites" ||
                  payrollInfo === "ownerStore" ? (
                    <>
                      <th>Qty</th>
                    </>
                  ) : null}

                  <th>Sales Amount</th>

                  {payrollInfo === "ownerSites" ||
                  payrollInfo === "ownerStore" ||
                  payrollInfo === "ownerSales" ? (
                    <>
                      <th>Commissionable Amount</th>
                    </>
                  ) : null}

                  {payrollInfo === "affiliateSales" ? (
                    <th>Affiliate commission</th>
                  ) : null}

                  {payrollInfo === "ownerSites" ||
                  payrollInfo === "ownerStore" ||
                  payrollInfo === "ownerSales" ? (
                    <>
                      <th>Partner Distribution</th>
                    </>
                  ) : null}

                  <th>Owner commission</th>

                  {payrollInfo === "ownerSites" ||
                  payrollInfo === "ownerStore" ||
                  payrollInfo === "ownerSales" ? (
                    <>
                      <th>Total Commission</th>
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {data?.map((each, index) => (
                  <tr key={`data_${each._id}_${index}`}>
                    <td>
                      {(payrollInfo === "ownerSales"
                        ? getFullName(each?.ownerName)
                        : getFullName(each?.affliateName)) || "N/A"}
                    </td>

                    {(payrollInfo === "affiliateSales" ||
                      payrollInfo === "ownerSales") && (
                      <>
                        <td>{each?.sales || each?.noOfSales || 0}</td>
                        <td>{each?.noOfSites || 0}</td>
                        <td>{each?.noOfStores || 0}</td>
                      </>
                    )}

                    {payrollInfo === "ownerSites" ||
                    payrollInfo === "ownerStore" ? (
                      <>
                        <td>{each?.noOfSales || 0}</td>
                      </>
                    ) : null}

                    {payrollInfo === "ownerSites" ? (
                      <>
                        <td>{formatCurrencyValue(each.siteAmount || 0)}</td>
                        <td>
                          {formatCurrencyValue(
                            each.siteCommissionableAmount || 0
                          )}
                        </td>
                      </>
                    ) : payrollInfo === "ownerStore" ? (
                      <>
                        <td>{formatCurrencyValue(each.storeAmount || 0)}</td>
                        <td>
                          {formatCurrencyValue(
                            each.storeCommissionableAmount || 0
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{formatCurrencyValue(each.salesAmount || 0)}</td>
                        {payrollInfo === "ownerSales" ? (
                          <>
                            <td>
                              {formatCurrencyValue(
                                each.totalCommissionableAmount || 0
                              )}
                            </td>
                          </>
                        ) : null}
                      </>
                    )}

                    {payrollInfo === "affiliateSales" ? (
                      <td>
                        {formatCurrencyValue(each.affiliateCommission || 0)}
                      </td>
                    ) : null}

                    {payrollInfo === "ownerSites" ||
                    payrollInfo === "ownerStore" ||
                    payrollInfo === "ownerSales" ? (
                      <>
                        <td>
                          {formatCurrencyValue(
                            each.ownersPartnerDistribution ||
                              each.partnerDistribution ||
                              0
                          )}
                        </td>
                      </>
                    ) : null}

                    <td>{formatCurrencyValue(each.ownerCommission || 0)}</td>

                    {payrollInfo === "ownerSites" ||
                    payrollInfo === "ownerStore" ||
                    payrollInfo === "ownerSales" ? (
                      <>
                        <td>
                          {formatCurrencyValue(
                            (each.partnerDistribution || 0) +
                              (each.ownerCommission || 0)
                          )}
                        </td>
                      </>
                    ) : null}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <NoDataDiv />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default PayrollOwnersModal;
