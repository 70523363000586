import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PayrollSummary from "../../components/Payroll/PayrollSummary";
import PayrollTransactions from "../../components/Payroll/PayrollTransactions";
import { getAndUpdateSettingsData } from "../../redux/actions";
import { errorHandler, sleepTime } from "../../helper-methods";

const Payroll = () => {
  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state?.settingsData);

  const [settingsLoading, setSettingsLoading] = useState(true);

  const isPayrollSubmitted = useMemo(() => {
    // check day
    if (
      !moment(settingsData?.setting?.payrollGeneratedTill).isSame(
        moment(settingsData?.setting?.payrollDoneTill),
        "d"
      )
    ) {
      // fetch payroll
      return true;
    }

    if (
      settingsData?.setting?.payrollGeneratedTill &&
      moment(settingsData?.setting?.payrollGeneratedTill)
        .add("7", "d")
        .isAfter(moment())
    ) {
      // no payroll added
      // transaction fetch
      return false;
    }

    // payrollGeneratedTill.day === payrollDoneTill.day
    // transaction fetch
    return false;
  }, [
    settingsData?.setting?.payrollGeneratedTill,
    settingsData?.setting?.payrollDoneTill,
  ]);

  const _getAndUpdateSettingsData = async () => {
    try {
      setSettingsLoading(true);

      await getAndUpdateSettingsData()(dispatch);

      await sleepTime(500);

      setSettingsLoading(false);
    } catch (error) {
      errorHandler(error);
      setSettingsLoading(false);
    }
  };

  useEffect(() => {
    _getAndUpdateSettingsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPayrollSubmitted) {
    return (
      <>
        <PayrollSummary
          settingsLoading={settingsLoading}
          isPayrollSubmitted={isPayrollSubmitted}
        />
      </>
    );
  }

  return (
    <>
      <PayrollTransactions
        settingsLoading={settingsLoading}
        isPayrollSubmitted={isPayrollSubmitted}
      />
    </>
  );
};

export default Payroll;
