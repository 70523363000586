import React from "react";
import { leadsProjectStatusConfig } from "../config/helper-config";
import { formatDateAndTime } from "../helper-methods";
import CustomTooltip from "./custom/CustomTooltip";
import SvgIcons from "./SvgIcons";

const StatusUpdate = ({ leadsProjectStatus = "", statusTimeLine = [] }) => {
  const _activeStatus = (value) => {
    return statusTimeLine?.find((each) => each.status === value);
  };

  return (
    <>
      <ul className="statusList">
        {leadsProjectStatusConfig?.map((each, index) => (
          <li
            key={`statusList_${each.value}`}
            className={
              each.value === leadsProjectStatus
                ? "active"
                : _activeStatus(each.value)
                ? "previousActive"
                : ""
            }
          >
            <span className="status">
              {each?.label}{" "}
              {_activeStatus(each.value)?.notes?.noteText ? (
                <>
                  <SvgIcons type={"docFile"} id={`status_timeline_${index}`} />
                  <CustomTooltip
                    text={_activeStatus(each.value)?.notes?.noteText}
                    target={`status_timeline_${index}`}
                  />
                </>
              ) : null}
            </span>
            <span className="count">{index + 1}</span>
            <span className="date">
              {formatDateAndTime(_activeStatus(each.value)?.updateAt)}
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default StatusUpdate;
