import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import PaymentAccordion from "../PaymentAccordion";
import { errorHandler } from "../../helper-methods";
import { getPaymentHistoryById } from "../../http/http-calls";
import SkeletonLoading from "../SkeletonLoading";
import NoDataDiv from "../NoDataDiv";

const OwnerPaymentModal = ({ isOpen, data, toggle }) => {
  const [payrollsData, setPayrollsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState(-1);

  const payrollsFilteredData = useMemo(() => {
    if (!payrollsData?.length) return [];

    return payrollsData.sort(
      (a, b) => (new Date(a.endDate) < new Date(b.endDate) ? orderBy : -orderBy) // ascending order
    );
  }, [payrollsData, orderBy]);

  const _closeModal = () => {
    toggle();
  };

  const _getPaymentHistoryById = async () => {
    try {
      setLoading(true);

      const res = await getPaymentHistoryById(data?._id);

      const newPayrollsData = res?.payrolls?.length
        ? res.payrolls.sort((a, b) => (a.payrollFor > b.payrollFor ? -1 : 1))
        : [];

      setPayrollsData(newPayrollsData);

      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _getPaymentHistoryById();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        scrollable
        centered
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => _closeModal()}>
          <Button
            color="link"
            disabled={loading}
            onClick={() => setOrderBy((prev) => (prev === 1 ? -1 : 1))}
          >
            Payment History{" "}
            {loading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              <i className="fas fa-sort" />
            )}
          </Button>
        </ModalHeader>

        <ModalBody>
          {payrollsFilteredData?.length ? (
            <PaymentAccordion data={payrollsFilteredData} />
          ) : loading ? (
            <>
              <SkeletonLoading
                type="box"
                count={3}
                width={"100%"}
                height={70}
                borderRadius={5}
              />
            </>
          ) : (
            <NoDataDiv />
          )}
        </ModalBody>

        {/* <ModalFooter className="justify-content-center">
          <Button color="primary" outline>
            Older
          </Button>
          <Button color="primary">Newer</Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default OwnerPaymentModal;
