import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import AddAffiliateModal from "../../components/modals/AddAffiliateModal";
import CustomTable from "../../components/Table/CustomTable";
import SendEmailTextComponent from "../../components/SendEmailTextComponent";
import { updateAffiliate } from "../../http/http-calls";
import {
  capitalize,
  copyToClipboard,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  getDropdownColor,
  getFullName,
  hasPermission,
  showToast,
} from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndUpdateAffiliates,
  getAndUpdateGroupList,
  getAndUpdateOwnerList,
  getAndUpdateTemplateList,
  saveLocalFilters,
  updateAffiliatesDataByIndex,
} from "../../redux/actions";
import SvgIcons from "../../components/SvgIcons";
import {
  dealsConfig,
  goalsConfig,
  referredLeadCountConfig,
  statusConfig,
} from "../../config/helper-config";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../components/custom/CustomTooltip";
import SearchableInput from "../../components/SearchableInput";
import GoalsAchievedModal from "../../components/modals/GoalsAchievedModal";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Name", id: "name" },
  { label: "Added Date", id: "createdAt" },
  { label: "Owner", id: "owner" },
  { label: "Leads", id: "leadCount" },
  { label: "Deal Made", id: "dealMade" },
  { label: "Potential Commission", id: "potentialCommision" },
  { label: "Actual Commission", id: "esCommission" },
  // { label: "Owner Commission", id: "ownerCommission" },
  { label: "Group(s)", id: "_groups" },
  { label: "Goals Achieved", id: "goalsCompleted" },
  { label: "Status", id: "status" },
  // { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const initialFiltersState = {
  status: "",
  startDate: null,
  endDate: null,
  referredLeadCount: "",
  deals: "",
  goalAchieved: "",
  search: "",
  dateType: "",
  group: "",
  owner: "",
};

const AffiliatesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchIntervalRef = useRef({ current: null });

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const tableData = useSelector((state) => state?.tableData);
  const localFilters = useSelector((state) => state?.localFilters);
  const filtersList = useSelector((state) => state?.filtersList);
  const [filterShow, setFilterShow] = useState(false);
  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [filters, setFilters] = useState(deepClone(initialFiltersState));
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [totalPotentialCommission, setTotalPotentialCommission] = useState(0);
  const [totalActualCommission, setTotalActualCommission] = useState(0);

  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);

  const [addAffiliateModal, setAddAffiliateModal] = useState({
    isOpen: false,
    data: null,
  });
  const [goalsAchievedModal, setGoalsAchievedModal] = useState({
    isOpen: false,
    data: null,
  });

  const hasPermissionToCreate = useMemo(
    () => hasPermission({ page: "affiliates", action: "create" }),
    []
  );

  const hasPermissionToView = useMemo(
    () => hasPermission({ page: "affiliates", action: "view" }),
    []
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddAffiliateModal = (isOpen = false, data = null) => {
    setAddAffiliateModal({ isOpen, data });
  };

  const _toggleGoalsAchievedModal = (isOpen = false, data = null) => {
    setGoalsAchievedModal({ isOpen, data });
  };

  const _getAndUpdateAffiliates = async (payload) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "AffiliatesPagePayload",
          value: deepClone(payload),
        })
      );

      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      const res = await getAndUpdateAffiliates(payload)(dispatch);

      setTotalPotentialCommission(res?.payload?.totalPotentialCommission || 0);
      setTotalActualCommission(res?.payload?.totalActualCommission || 0);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateAffiliates(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateAffiliates(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAndUpdateAffiliates(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "AffiliatesPage",
          value: deepClone(newFilters),
        })
      );

      const newDataPayload = deepClone(initialDataPayloadState);

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (
            eachFilterKey === "referredLeadCount" ||
            eachFilterKey === "deals" ||
            eachFilterKey === "goalAchieved"
          ) {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "group" || eachFilterKey === "owner") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey]?.value;
            // newFilters[eachFilterKey] === "Active" ? true : false;
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
            // newFilters[eachFilterKey] === "Active" ? true : false;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAndUpdateAffiliates(newDataPayload);
    } catch (error) {
      console.log({ error });
    }
  };

  const _onChangeFilters = (key, value) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate, dateType }) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    newFilters.dateType = dateType;

    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _clearFilters = () => {
    _manageLoadingState("filters", true);

    const newFilters = deepClone(initialFiltersState);

    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onChangeSelectedData = (value = "all", checked = false) => {
    if (value === "every") {
      setIsSelectAll(checked);
    } else {
      setIsSelectAll(false);
      let newSelectedDataIds = [...selectedDataIds];
      if (value === "all") {
        if (checked) {
          const currentPageIds = tableData?.affiliatesData
            ?.filter((each) => !newSelectedDataIds?.includes(each._id))
            .map((each) => each._id);
          newSelectedDataIds = [...newSelectedDataIds, ...currentPageIds];
        } else {
          const currentPageIds = tableData?.affiliatesData?.map(
            (each) => each._id
          );
          newSelectedDataIds = newSelectedDataIds.filter(
            (sid) => !currentPageIds.includes(sid)
          );
        }
      } else {
        if (checked) {
          newSelectedDataIds.push(value);
        } else {
          const findIndex = newSelectedDataIds.findIndex(
            (each) => each === value
          );
          if (findIndex >= 0) newSelectedDataIds.splice(findIndex, 1);
        }
      }
      setSelectedDataIds(newSelectedDataIds);
    }
  };

  const _onChangeStatus = async (data, status) => {
    try {
      if (!data?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      const findIndex = tableData?.affiliatesData?.findIndex(
        (e) => e._id === data?._id
      );

      if (findIndex <= -1) {
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        isActive: status === "Active" ? true : false,
      };

      dispatch(
        updateAffiliatesDataByIndex({
          index: findIndex,
          key: "isActive",
          value: payload.isActive,
        })
      );

      await updateAffiliate({ id: data?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getAndUpdateAffiliates(dataPayload);
    }
  };

  const _dataFormat = (cell, row, header, index) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              {/* <img
                src={require("../../assets/img/default-profile.svg").default}
                alt="user"
              /> */}
              <div className="userContact">
                <h2
                  className={`${
                    row?.isActive === false ? "text-danger" : "text-primary"
                  }`}
                  onClick={() => navigate(`/${userType}/affiliate/${row?._id}`)}
                >
                  {getFullName(row?.name) || "Anonymous"}
                </h2>
                <ul className="userAction">
                  {row?.phone && (
                    <li>
                      <>
                        <div
                          id={`user_phone_${row?._id}`}
                          onClick={() =>
                            copyToClipboard(
                              `${row?.countryCode || ""}${row?.phone}`
                            )
                          }
                        >
                          <SvgIcons type={"phone"} />
                        </div>

                        <CustomTooltip
                          target={`user_phone_${row?._id}`}
                          text={`${row?.countryCode || ""} ${row?.phone}`}
                        />
                      </>
                    </li>
                  )}
                  {row?.email && (
                    <li>
                      <>
                        <div
                          id={`user_email_${row?._id}`}
                          onClick={() => copyToClipboard(row?.email)}
                        >
                          <SvgIcons type={"email"} />
                        </div>

                        <CustomTooltip
                          target={`user_email_${row?._id}`}
                          text={row?.email}
                        />
                      </>
                    </li>
                  )}
                  {row?.referCode && (
                    <li>
                      <>
                        <div
                          id={`user_website_${row?._id}`}
                          onClick={() => copyToClipboard(row?.referCode)}
                        >
                          <SvgIcons type={"refer"} />
                        </div>
                        <CustomTooltip
                          target={`user_website_${row?._id}`}
                          text={row?.referCode}
                        />
                      </>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      }
      case "owner": {
        return <>{getFullName(row?._owner?.name) || "N/A"}</>;
      }
      case "createdAt": {
        return <>{formatDate(row?.createdAt) || "-"}</>;
      }
      case "leadCount": {
        return <>{row?.leadCount || 0}</>;
      }
      case "dealMade": {
        return <>{row?.dealMade || 0}</>;
      }
      case "potentialCommision": {
        return <>{formatCurrencyValue(row?.potentialCommision || 0)}</>;
      }
      case "esCommission": {
        return <>{formatCurrencyValue(row?.estCommission || 0)}</>;
      }
      case "ownerCommission": {
        return <>{formatCurrencyValue(row?.ownerCommission || 0)}</>;
      }
      case "goalsCompleted": {
        if (!row?.goalsCompleted?.length) return 0;

        return (
          <>
            <Button
              color="link"
              className="m-0 p-0"
              onClick={() =>
                _toggleGoalsAchievedModal(true, row?.goalsCompleted)
              }
            >
              {row?.goalsCompleted?.length}
            </Button>
          </>
        );
      }
      case "_groups": {
        return (
          <>
            {cell?.length ? (
              <>
                {cell?.slice(0, 1)?.map((e, i) => (
                  <span
                    key={`_groups_${row?._id}_${e}_${i}_${index}`}
                    className="mr-1"
                  >
                    {capitalize(e?.name) || "-"}
                  </span>
                ))}

                {cell?.slice(1)?.length ? (
                  <>
                    <Button
                      id={`groups_customtooltip_affiliate_page_${row?._id}_${index}`}
                      color="link"
                      className="m-0 p-0 themeColor text-decoration-none"
                      style={{ verticalAlign: 0 }}
                    >
                      +{cell?.slice(1)?.length}
                    </Button>

                    <CustomTooltip
                      target={`groups_customtooltip_affiliate_page_${row?._id}_${index}`}
                      text={cell?.slice(1)?.map((e, i) => (
                        <span
                          key={`_groups_${row?._id}_${e._id}_${i}_${index}`}
                          className={"comma-separated"}
                        >
                          {capitalize(e?.name) || "-"}
                        </span>
                      ))}
                    />
                  </>
                ) : null}
              </>
            ) : (
              "-"
            )}
          </>
        );
      }
      case "status": {
        return (
          <>
            <Input
              type="select"
              className={`status ${getDropdownColor(
                row?.isActive ? "Active" : "Inactive"
              )}`}
              disabled={loadingState?.status ? true : false}
              value={row?.isActive ? "Active" : "Inactive"}
              onChange={(e) => _onChangeStatus(row, e.target.value)}
            >
              {statusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _getAndUpdateGroupList = async () => {
    try {
      await getAndUpdateGroupList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAndUpdateTemplateList = async () => {
    try {
      await getAndUpdateTemplateList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAndUpdateOwnerList = async () => {
    try {
      await getAndUpdateOwnerList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _initialize = () => {
    try {
      _getAndUpdateTemplateList();
      _getAndUpdateGroupList();
      _getAndUpdateOwnerList();

      if (
        localFilters?.filters?.AffiliatesPage &&
        localFilters?.filters?.AffiliatesPagePayload
      ) {
        setFilters(localFilters.filters.AffiliatesPage);
        setDataPayload(localFilters.filters.AffiliatesPagePayload);
        _getAndUpdateAffiliates(localFilters.filters.AffiliatesPagePayload);
      } else {
        _onPageChange();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (!hasPermissionToView) {
      errorHandler({ reason: "Unauthorised User" });
      navigate("/");
    }

    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Affiliates{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button
            onClick={() => _toggleAddAffiliateModal(true)}
            color="primary"
            disabled={!hasPermissionToCreate}
          >
            Add
          </Button>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
          <Button
            color="link"
            className="hideShowFilter"
            onClick={() => setFilterShow(!filterShow)}
          >
            <SvgIcons type={"filter"} />
          </Button>
        </div>
      </div>

      <div className={`filterWrapper ${filterShow === true ? "show" : ""}`}>
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchbar">
            <Label>Search</Label>
            <InputGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {loadingState?.search ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <div className="formGroup">
            <Label>Added Date</Label>
            <CustomDateRangePicker
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_AffiliatesPage"}
              endDateId={"endDateId_AffiliatesPage"}
              onDatesChange={_onDatesChange}
              dateType={filters.dateType}
            />
          </div>

          <div className="formGroup">
            <Label>Referred Leads</Label>
            <Input
              type="select"
              value={filters.referredLeadCount}
              name="referredLeadCount"
              onChange={(e) =>
                _onChangeFilters("referredLeadCount", e.target.value)
              }
            >
              <option value="">All</option>
              {referredLeadCountConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Deal Made</Label>
            <Input
              type="select"
              value={filters.deals}
              name="deals"
              onChange={(e) => _onChangeFilters("deals", e.target.value)}
            >
              <option value="">All</option>
              {dealsConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Groups</Label>
            <SearchableInput
              options={filtersList?.groupList}
              value={filters.group}
              onChange={(value) => _onChangeFilters("group", value)}
            />
          </div>

          <div className="formGroup">
            <Label>Owner</Label>
            <SearchableInput
              options={filtersList?.ownerList}
              value={filters.owner}
              onChange={(value) => _onChangeFilters("owner", value)}
            />
          </div>

          <div className="formGroup">
            <Label>Goal Achieved</Label>
            <Input
              type="select"
              value={filters.goalAchieved}
              name="goalAchieved"
              onChange={(e) => _onChangeFilters("goalAchieved", e.target.value)}
            >
              <option value="">All</option>
              {goalsConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          <div className="formGroup">
            <Label>Status</Label>
            <Input
              type="select"
              value={filters.status}
              name="status"
              onChange={(e) => _onChangeFilters("status", e.target.value)}
            >
              <option value="">All</option>
              {statusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap">
        {isSelectAll || selectedDataIds?.length ? (
          <>
            <div>
              <div>
                {isSelectAll ? (
                  <>
                    All {tableData?.affiliatesDataCount} Affiliates are selected
                  </>
                ) : (
                  <>
                    {selectedDataIds.length}{" "}
                    {selectedDataIds.length > 1
                      ? "Affiliates are"
                      : "Affiliate is"}{" "}
                    selected
                  </>
                )}
              </div>
              {selectedDataIds?.length >= tableData?.affiliatesData?.length &&
              tableData?.affiliatesDataCount > dataPayload.limit ? (
                <FormGroup check className="mb-0 ml-2">
                  <Label for="isSelectAll_Affiliates">
                    Select All {tableData?.affiliatesDataCount} Affiliates
                  </Label>
                  <Input
                    type="checkbox"
                    id="isSelectAll_Affiliates"
                    checked={isSelectAll}
                    onChange={(e) =>
                      _onChangeSelectedData("every", e.target.checked)
                    }
                  />
                </FormGroup>
              ) : null}
            </div>
          </>
        ) : null}
        <div className="affiliateAmount">
          <span>
            Total Potential Commission:{" "}
            {formatCurrencyValue(totalPotentialCommission || 0)}
          </span>
          <span>
            Total Actual Commission:{" "}
            {formatCurrencyValue(totalActualCommission || 0)}
          </span>
        </div>
      </div>

      <CustomTable
        data={tableData?.affiliatesData}
        dataCount={tableData?.affiliatesDataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={true}
        isSelectAll={isSelectAll}
        selectedDataIds={selectedDataIds}
        onChangeSelectedData={(each, checked) =>
          _onChangeSelectedData(each?._id, checked)
        }
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      {selectedDataIds?.length ? (
        <SendEmailTextComponent
          isSelectAll={isSelectAll}
          selectedDataIds={selectedDataIds}
          setSelectedDataIds={setSelectedDataIds}
          setIsSelectAll={setIsSelectAll}
          userType={`Affiliate`}
        />
      ) : null}

      <AddAffiliateModal
        isOpen={addAffiliateModal.isOpen}
        data={addAffiliateModal.data}
        toggle={() => _toggleAddAffiliateModal()}
        onSuccess={() => _onPageChange()}
      />

      <GoalsAchievedModal
        isOpen={goalsAchievedModal.isOpen}
        data={goalsAchievedModal.data}
        toggle={() => _toggleGoalsAchievedModal()}
        heading={"Goals Achieved"}
        isProgressShow={false}
      />
    </>
  );
};

export default AffiliatesPage;
