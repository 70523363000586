import React from "react";
import { Input } from "reactstrap";

const StatusChangeComponent = ({
  value,
  onChange,
  options,
  disabled,
  type,
}) => {
  return type ? (
    <Input
      type="switch"
      checked={value}
      onChange={(e) => onChange(e)}
      disabled={disabled}
    />
  ) : (
    <Input
      type="select"
      value={value}
      onChange={(e) => onChange(e)}
      disabled={disabled}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
};

export default StatusChangeComponent;
