import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MediaQueueViewer from "../MediaQueueViewer";
import DashboardFooter from "./DashboardFooter";
import DashboardHeader from "./DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import { getAndUpdateSettingsData } from "../../redux/actions";
import { errorHandler } from "../../helper-methods";
import AffiliateRoutes from "../Routes/AffiliateRoutes";
import OwnerRoutes from "../Routes/OwnerRoutes";
import DefaultRoutes from "../Routes/DefaultRoutes";

const DashboardLayout = () => {
  const routes = {
    affiliate: <AffiliateRoutes />,
    owner: <OwnerRoutes />,
    default: <DefaultRoutes />,
  };

  const dispatch = useDispatch();
  const location = useLocation();

  const [isShow, setIsShow] = useState(false);

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(
    () => userCredential?.user?.userType?.toLowerCase(),
    [userCredential]
  );

  const _getAndUpdateSettingsData = async () => {
    try {
      if (
        location.pathname.includes("/payroll-summary") ||
        location.pathname.includes("/payroll") ||
        location.pathname.includes("/settings")
      ) {
        return;
      }

      await getAndUpdateSettingsData()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _getAndUpdateSettingsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="mainWrapper">
        <DashboardSidebar isShow={isShow} setIsShow={setIsShow} />
        <DashboardHeader isShow={isShow} setIsShow={setIsShow} />
        <div className="innerWrapper">
          {userType === "affiliate" || userType === "owner" ? (
            <>{routes[userType]}</>
          ) : (
            <>{routes.default}</>
          )}

          <MediaQueueViewer />
        </div>

        <DashboardFooter />
      </div>
    </>
  );
};

export default DashboardLayout;
