import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllAffiliateGroups,
  getAllAffiliates,
  getAllClients,
  getAllGoals,
  getAllLeaderboard,
  getAllOwnersData,
  getAllPayrollTransactions,
  getAllPayrollSummary,
  getAllTeamMembers,
} from "../../http/http-calls";

const getAndUpdateAffiliates = createAsyncThunk(
  "tableData/getAndUpdateAffiliates",
  async (payload, thunkAPI) => {
    const res = await getAllAffiliates(payload);

    return {
      data: res.users,
      count: res.totalCount,
      totalPotentialCommission: res.totalPotentialCommission,
      totalActualCommission: res.totalActualCommission,
    };
  }
);

const getAndUpdateClients = createAsyncThunk(
  "tableData/getAndUpdateClients",
  async (payload, thunkAPI) => {
    const res = await getAllClients(payload);

    return {
      data: res.leads,
      count: res.leadCount,
    };
  }
);

const getAndUpdateOwners = createAsyncThunk(
  "tableData/getAndUpdateOwners",
  async (payload, thunkAPI) => {
    const res = await getAllOwnersData(payload);

    return {
      data: res.owners,
      count: res.ownersCount,
    };
  }
);

const getAndUpdatePayrollTransactions = createAsyncThunk(
  "tableData/getAndUpdatePayrollTransactions",
  async (payload, thunkAPI) => {
    const res = await getAllPayrollTransactions();

    const data = [];

    if (res.affiliates?.length) {
      res.affiliates.forEach((each) => {
        data.push({ ...each, userType: "affiliate" });
      });
    }

    if (res.owners?.length) {
      res.owners.forEach((each) => {
        data.push({ ...each, userType: "owner" });
      });
    }

    return {
      data,
      isEmptyData: res.isEmptyData || false,
    };
  }
);

const getAndUpdatePayrollSummary = createAsyncThunk(
  "tableData/getAndUpdatePayrollSummary",
  async (payload, thunkAPI) => {
    const res = await getAllPayrollSummary();

    const data = res?.payrolls?.length
      ? res.payrolls.sort((a, b) => (a.payrollFor > b.payrollFor ? -1 : 1))
      : [];

    return {
      data,
      isEmptyData: res.isEmptyData || false,
    };
  }
);

const getAndUpdateLeaderboard = createAsyncThunk(
  "tableData/getAndUpdateLeaderboard",
  async (payload, thunkAPI) => {
    const res = await getAllLeaderboard(payload);

    return {
      data: res.affiliates,
      count: res.affiliateCount,
    };
  }
);

const getAndUpdateGoals = createAsyncThunk(
  "tableData/getAndUpdateGoals",
  async (payload, thunkAPI) => {
    const res = await getAllGoals(payload);

    return {
      data: res.goals,
      count: res.goalCount,
    };
  }
);

const getAndUpdateTeams = createAsyncThunk(
  "tableData/getAndUpdateTeams",
  async (payload, thunkAPI) => {
    const res = await getAllTeamMembers(payload);

    return {
      data: res.teamMembers,
      count: res.teamMembersCount,
    };
  }
);

const getAndUpdateAffiliateGroups = createAsyncThunk(
  "tableData/getAndUpdateAffiliateGroups",
  async (payload, thunkAPI) => {
    const res = await getAllAffiliateGroups(payload);

    return {
      data: res.groups,
      count: res.groupsCount,
    };
  }
);

const updateAffiliatesDataByIndex = createAction("updateAffiliatesDataByIndex");
const removePayrollTransactionsDataByIndex = createAction(
  "removePayrollTransactionsDataByIndex"
);
const updateClientsDataByIndex = createAction("updateClientsDataByIndex");
const updateTeamsDataByIndex = createAction("updateTeamsDataByIndex");
const updateAffiliateGroupsDataByIndex = createAction(
  "updateAffiliateGroupsDataByIndex"
);
const clearAllTableData = createAction("clearAllTableData");

export {
  getAndUpdateAffiliates,
  updateAffiliatesDataByIndex,
  getAndUpdateClients,
  updateClientsDataByIndex,
  getAndUpdateLeaderboard,
  getAndUpdateGoals,
  getAndUpdateTeams,
  updateTeamsDataByIndex,
  getAndUpdateAffiliateGroups,
  updateAffiliateGroupsDataByIndex,
  getAndUpdateOwners,
  getAndUpdatePayrollTransactions,
  removePayrollTransactionsDataByIndex,
  getAndUpdatePayrollSummary,
  clearAllTableData,
};
