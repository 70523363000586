import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CustomTable from "../../components/Table/CustomTable";
import {
  deepClone,
  errorHandler,
  formatDate,
  getDropdownColor,
  getFullName,
  showToast,
} from "../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndUpdateAffiliateGroups,
  saveLocalFilters,
  updateAffiliateGroupsDataByIndex,
} from "../../redux/actions";
import SvgIcons from "../../components/SvgIcons";
import AddAffiliateGroupModal from "../../components/modals/AddAffiliateGroupModal";
import CompactText from "../../components/custom/CompactText";
import { membersCountConfig, statusConfig } from "../../config/helper-config";
import { updateAffiliateGroup } from "../../http/http-calls";
import CustomTooltip from "../../components/custom/CustomTooltip";
import GoalsAchievedModal from "../../components/modals/GoalsAchievedModal";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Group Name", id: "name" },
  { label: "Created on", id: "createdAt" },
  { label: "Members", id: "membersCount" },
  { label: "Goals Assigned", id: "goalsAssigned" },
  { label: "Status", id: "status" },
  { label: "Action", id: "action" },
];

const initialDataPayloadState = {
  filters: {},
  skip: 0,
  pageNumber: 0,
  page: 1,
  limit: 10,
  // sortBy: "createdAt",
  // orderBy: -1,
};

const initialFiltersState = {
  status: "",
  startDate: null,
  endDate: null,
  membersCount: "",
  dateType: "",
  search: "",
};

const AffiliateGroupsPage = () => {
  const dispatch = useDispatch();

  const searchIntervalRef = useRef({ current: null });

  const tableData = useSelector((state) => state?.tableData);
  const localFilters = useSelector((state) => state?.localFilters);

  const [dataPayload, setDataPayload] = useState(
    deepClone(initialDataPayloadState)
  );
  const [filters, setFilters] = useState(deepClone(initialFiltersState));
  const [loadingState, setLoadingState] = useState({
    data: false,
    status: false,
    filters: false,
    search: false,
  });

  const [addAffiliateGroupModal, AddsetAffiliateGrageModal] = useState({
    isOpen: false,
    data: null,
  });
  const [goalsAchievedModal, setGoalsAchievedModal] = useState({
    isOpen: false,
    data: null,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _toggleAddAffiliateGroupModal = (isOpen = false, data = null) => {
    AddsetAffiliateGrageModal({ isOpen, data });
  };

  const _toggleGoalsAchievedModal = (isOpen = false, data = null) => {
    setGoalsAchievedModal({ isOpen, data });
  };

  const _getAndUpdateAffiliateGroups = async (payload) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "AffiliateGroupsPagePayload",
          value: deepClone(payload),
        })
      );

      if (typeof payload?.filters === "object") {
        payload = {
          ...payload,
          ...payload.filters,
        };

        delete payload.filters;
      }

      _manageLoadingState("data", true);

      await getAndUpdateAffiliateGroups(payload)(dispatch);

      setLoadingState({});
    } catch (error) {
      errorHandler(error);
      setLoadingState({});
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAndUpdateAffiliateGroups(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAndUpdateAffiliateGroups(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAndUpdateAffiliateGroups(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    try {
      dispatch(
        saveLocalFilters({
          key: "AffiliateGroupsPage",
          value: deepClone(newFilters),
        })
      );

      const newDataPayload = deepClone(initialDataPayloadState);

      Object.keys(newFilters).forEach((eachFilterKey) => {
        if (newFilters[eachFilterKey]) {
          if (eachFilterKey === "membersCount") {
            try {
              newDataPayload.filters[eachFilterKey] = JSON.parse(
                newFilters[eachFilterKey]
              );
            } catch (e) {}
          } else if (eachFilterKey === "status") {
            newDataPayload.filters[eachFilterKey] =
              newFilters[eachFilterKey] === "Active" ? true : false;
          } else {
            newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
          }
        }
      });

      setDataPayload(newDataPayload);

      _getAndUpdateAffiliateGroups(newDataPayload);
    } catch (error) {}
  };

  const _onChangeFilters = (key, value) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate, dateType }) => {
    _manageLoadingState("filters", true);

    const newFilters = { ...filters };

    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    newFilters.dateType = dateType;

    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (value = "") => {
    if (searchIntervalRef?.current) clearInterval(searchIntervalRef.current);

    _manageLoadingState("search", true);

    const newFilters = { ...filters };
    newFilters.search = value;
    setFilters(newFilters);

    searchIntervalRef.current = setTimeout(() => {
      _onFiltersUpdated(newFilters);
    }, 1000);
  };

  const _clearFilters = () => {
    _manageLoadingState("filters", true);

    const newFilters = deepClone(initialFiltersState);

    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onChangeStatus = async (data, status) => {
    try {
      if (!data?._id) {
        errorHandler({ reason: "Data not found" });
        return;
      }

      const findIndex = tableData?.affiliateGroupsData?.findIndex(
        (e) => e._id === data?._id
      );

      if (findIndex <= -1) {
        return;
      }

      _manageLoadingState("status", true);

      const payload = {
        isActive: status === "Active" ? true : false,
      };

      dispatch(
        updateAffiliateGroupsDataByIndex({
          index: findIndex,
          key: "isActive",
          value: payload.isActive,
        })
      );

      await updateAffiliateGroup({ id: data?._id, payload });

      _manageLoadingState("status", false);

      showToast("Status has been updated", "success");
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("status", false);
      _getAndUpdateAffiliateGroups(dataPayload);
    }
  };

  const _dataFormat = (cell, row, header, index) => {
    switch (header) {
      case "name": {
        return (
          <>
            <CompactText length={60} text={row?.name || "N/A"} />
          </>
        );
      }
      case "createdAt": {
        return <>{formatDate(row?.createdAt) || "-"}</>;
      }
      case "membersCount": {
        if (!row?._members?.length) return 0;

        return (
          <>
            <Button
              id={`members_customtooltip_group_page_${row?._id}_${index}`}
              color="link"
              className="p-0 m-0 themeColor text-decoration-none"
              style={{ verticalAlign: 0 }}
            >
              {row?._members?.length}
            </Button>

            <CustomTooltip
              target={`members_customtooltip_group_page_${row?._id}_${index}`}
              text={row?._members?.map((e, i) => (
                <span
                  key={`members_${row?._id}_${e._id}_${i}_${index}`}
                  className={"comma-separated"}
                >
                  {getFullName(e?.name) || "-"}
                </span>
              ))}
            />
          </>
        );
      }
      case "goalsAssigned": {
        if (!row?.goalsAssigned?.length) return 0;

        return (
          <>
            <Button
              color="link"
              className="m-0 p-0"
              onClick={() =>
                _toggleGoalsAchievedModal(true, row?.goalsAssigned)
              }
            >
              {row?.goalsAssigned?.length}
            </Button>
          </>
        );
      }
      case "status": {
        return (
          <>
            <Input
              type="select"
              className={`status ${getDropdownColor(
                row?.isActive ? "Active" : "Inactive"
              )}`}
              disabled={loadingState?.status ? true : false}
              value={row?.isActive ? "Active" : "Inactive"}
              onChange={(e) => _onChangeStatus(row, e.target.value)}
            >
              {statusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </>
        );
      }
      case "action": {
        return (
          <>
            <Button
              color="link"
              onClick={() => _toggleAddAffiliateGroupModal(true, row)}
            >
              <SvgIcons type="edit" />
            </Button>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _initialize = () => {
    try {
      if (
        localFilters?.filters?.AffiliateGroupsPage &&
        localFilters?.filters?.AffiliateGroupsPagePayload
      ) {
        setFilters(localFilters.filters.AffiliateGroupsPage);
        setDataPayload(localFilters.filters.AffiliateGroupsPagePayload);
        _getAndUpdateAffiliateGroups(
          localFilters.filters.AffiliateGroupsPagePayload
        );
      } else {
        _onPageChange();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    _initialize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Affiliate Groups{" "}
          {loadingState?.data || loadingState?.filters ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          <Button
            onClick={() => _toggleAddAffiliateGroupModal(true)}
            color="primary"
          >
            Add
          </Button>

          <Button
            color="link"
            className="btn-reset"
            onClick={() => _clearFilters()}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <div className="filterWrapper">
        <div className="filterIcon">
          <i className="fas fa-filter" />
        </div>

        <div className="filterForm">
          <div className="formGroup searchbar">
            <Label>Search</Label>
            <InputGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={filters.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {loadingState?.search ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : filters.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>

          <FormGroup className="formGroup">
            <Label>Created on</Label>
            <CustomDateRangePicker
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_AffiliateGroupsPage"}
              endDateId={"endDateId_AffiliateGroupsPage"}
              onDatesChange={_onDatesChange}
              dateType={filters.dateType}
            />
          </FormGroup>

          <FormGroup className="formGroup">
            <Label>Members</Label>
            <Input
              type="select"
              value={filters.membersCount}
              name="membersCount"
              onChange={(e) => _onChangeFilters("membersCount", e.target.value)}
            >
              <option value="">All</option>
              {membersCountConfig?.map((each) => (
                <option
                  key={JSON.stringify(each.value)}
                  value={JSON.stringify(each.value)}
                >
                  {each.label}
                </option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup className="formGroup">
            <Label>Status</Label>
            <Input
              type="select"
              value={filters.status}
              name="status"
              onChange={(e) => _onChangeFilters("status", e.target.value)}
            >
              <option value="">All</option>
              {statusConfig?.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        </div>
      </div>

      <CustomTable
        data={tableData?.affiliateGroupsData}
        dataCount={tableData?.affiliateGroupsDataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loadingState?.data}
        isRowSelection={false}
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      <AddAffiliateGroupModal
        isOpen={addAffiliateGroupModal.isOpen}
        data={addAffiliateGroupModal.data}
        toggle={() => _toggleAddAffiliateGroupModal()}
        onSuccess={() => _onPageChange()}
      />

      <GoalsAchievedModal
        isOpen={goalsAchievedModal.isOpen}
        data={goalsAchievedModal.data}
        toggle={() => _toggleGoalsAchievedModal()}
        isProgressShow={false}
        heading={"Goals Assigned"}
      />
    </>
  );
};

export default AffiliateGroupsPage;
