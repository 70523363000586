import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { errorHandler, formatDate } from "../../helper-methods";
import SvgIcons from "../SvgIcons";
import SkeletonLoading from "../SkeletonLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  getAndUpdateOwnerList,
  getAndUpdatePayrollTransactions,
  getAndUpdateSettingsData,
} from "../../redux/actions";
import Swal from "sweetalert2";
import moment from "moment";
import NoDataDiv from "../NoDataDiv";
import { generatePayroll } from "../../http/http-calls";
import OwnerCard from "./OwnerCard";
import AffiliateCard from "./AffiliateCard";

const PayrollTransactions = ({
  settingsLoading = false,
  isPayrollSubmitted = false,
}) => {
  const dispatch = useDispatch();

  const tableData = useSelector((state) => state?.tableData);
  const settingsData = useSelector((state) => state?.settingsData);

  const [loadingState, setLoadingState] = useState({
    data: false,
    update: false,
    skip: false,
    submit: false,
  });

  const affiliatesData = useMemo(() => {
    return tableData?.payrollTransactionsData?.filter(
      (each) => each.userType === "affiliate"
    );
  }, [tableData?.payrollTransactionsData]);

  const ownersData = useMemo(() => {
    return tableData?.payrollTransactionsData?.filter(
      (each) => each.userType === "owner"
    );
  }, [tableData?.payrollTransactionsData]);

  // const _toggleOwnerPaymentModal = (isOpen = false) => {
  //   setOwnerPaymentModal({ isOpen });
  // };

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({ ...prev, [key]: value }));
  };

  const _getAndUpdatePayrollTransactions = async () => {
    try {
      _manageLoadingState("data", true);

      const res = await getAndUpdatePayrollTransactions()(dispatch);

      if (res?.payload?.data?.length) {
        _getAndUpdateOwnerList();
      }

      _manageLoadingState("data", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("data", false);
    }
  };

  const _getAndUpdateOwnerList = async () => {
    try {
      await getAndUpdateOwnerList()(dispatch);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onSubmit = async () => {
    try {
      _manageLoadingState("submit", true);

      await generatePayroll();
      await getAndUpdateSettingsData()(dispatch);

      _manageLoadingState("submit", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("submit", false);
    }
  };

  const _onSubmitAlert = async () => {
    _manageLoadingState("submit", true);

    const { isConfirmed } = await Swal.fire({
      title: "Are you sure?",
      text: `You wish to proceed with creating a billing? Please note that once initiated, it cannot be reverted.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      cancelButtonText: "Cancel",
      confirmButtonText: "Proceed with Billing",
    });

    if (!isConfirmed) {
      _manageLoadingState("submit", false);
      return;
    }

    _onSubmit();
  };

  useEffect(() => {
    if (!isPayrollSubmitted && !settingsLoading) {
      _getAndUpdatePayrollTransactions();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPayrollSubmitted, settingsLoading]);

  return (
    <>
      <div className="innerHeader">
        <h2>
          Payroll{" "}
          {loadingState?.data || settingsLoading ? (
            <i className="fa fa-spinner fa-spin" />
          ) : null}
        </h2>

        <div className="right">
          {!tableData?.payrollTransactionsDataIsEmptyData &&
          settingsData?.setting?.payrollGeneratedTill ? (
            <span>
              {formatDate(
                moment(settingsData?.setting?.payrollGeneratedTill).add(1, "d")
              )}{" "}
              -{" "}
              {formatDate(
                moment(settingsData?.setting?.payrollGeneratedTill).add(7, "d")
              )}
            </span>
          ) : loadingState?.data || settingsLoading ? (
            <SkeletonLoading type="box" width={220} height={25} />
          ) : null}

          <Button
            color="link"
            className="btn-reset"
            onClick={() => {
              dispatch(getAndUpdateSettingsData());
              _getAndUpdatePayrollTransactions();
            }}
          >
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <Row>
        {tableData?.payrollTransactionsData?.length ? (
          <>
            {affiliatesData?.length ? (
              <Col xl={6} lg={6}>
                <h2 className="fs-18">Affiliates</h2>

                {affiliatesData?.map((each, index) => (
                  <Fragment key={`affiliatesData_${each._id}_${index}`}>
                    <AffiliateCard
                      data={each}
                      dataIndex={index}
                      getAndUpdatePayrollTransactions={
                        _getAndUpdatePayrollTransactions
                      }
                    />
                  </Fragment>
                ))}
              </Col>
            ) : null}

            {ownersData?.length ? (
              <Col xl={6} lg={6}>
                <h2 className="fs-18">Owners</h2>

                {ownersData?.map((each, index) => (
                  <Fragment key={`ownersData_${each._id}_${index}`}>
                    <OwnerCard
                      data={each}
                      dataIndex={index}
                      getAndUpdatePayrollTransactions={
                        _getAndUpdatePayrollTransactions
                      }
                    />
                  </Fragment>
                ))}
              </Col>
            ) : null}
          </>
        ) : loadingState?.data || settingsLoading ? (
          <>
            <SkeletonLoading type="card" height={500} count={2} md={6} />
          </>
        ) : (
          <Col md="12">
            <NoDataDiv message={"There is no transaction to display."} />
          </Col>
        )}
      </Row>

      {!tableData?.payrollTransactionsDataIsEmptyData ? (
        <div className="floatingBtn">
          <Button
            color="primary"
            disabled={loadingState?.submit}
            onClick={() => _onSubmitAlert()}
          >
            {loadingState?.submit ? (
              <i className="fa fa-spinner fa-spin" />
            ) : null}{" "}
            Generate Payroll
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default PayrollTransactions;
